import React from "react";
import Layout from "../Layout";
import Navbar from "../components/Reusable/Navbar";
import QuestionScreen from "../components/Reusable/QuestionScreen";
import { getPath } from "../utils/getPath";
import { FaUserAlt, FaUserFriends } from "react-icons/fa";
import { HiUserGroup } from "react-icons/hi";

const question = "Audience size?";
const options = [
  {
    label: "Small",
    value: "small",
    icon: <FaUserAlt />,
  },
  {
    label: "Medium",
    value: "medium",
    icon: <FaUserFriends />,
  },
  {
    label: "Large",
    value: "large",
    icon: <HiUserGroup />,
  },
];

export const AudienceSizeQuestion = () => {
  const state = "audience-size";
  const getLink = async () => await getPath(state);

  return (
    <div className="flex flex-col">
      <Navbar />
      <div>
        <Layout>
          <QuestionScreen
            navbar
            pageName={state}
            question={question}
            options={options}
            link={getLink}
          />
        </Layout>
      </div>
    </div>
  );
};
