import React, { useEffect } from "react";
import Layout from "../../Layout";
import { useHistory } from "react-router";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useCart } from "../../store";
import SearchDomainCard from "../SearchDomain/SearchDomainCard";
import RoundedDropdown from "./RoundedDropdown";
import cn from "classnames";
import { FaCheck } from "react-icons/fa";
import Tooltip from "./Tooltip";
import { AiOutlineInfoCircle } from "react-icons/ai";

const QuestionScreen = ({
  navbar,
  pageName,
  question,
  options,
  link,
  type: componentType,
}) => {
  const [active, setActive] = React.useState(0);
  const history = useHistory();
  const {
    addToCart,
    setType,
    getIsDomainSelected,
    type,
    setBusinessRegistration,
    setTrademarkRegistration,
    setTemplateSelection,
    removeFromCart,
    setTemplateType,
    setWebsiteSpeciality,
    setAudienceSize,
  } = useCart();
  const [domainViewActive, setDomainViewActive] = React.useState(false);
  const [selected, setSelected] = React.useState(
    componentType === "select" && componentType === "radio" && options[0].value
  );

  // this is a handler function that is called when the user enter the page to set the default value to the first option value
  // this function is also called when the user click on the option and set the value to the selected option value

  const handleChange = async (index) => {
    setActive(index);
    setDomainViewActive(false);

    switch (pageName) {
      case "purpose":
        setType(options[index].value);
        break;
      case "trademark":
        if (options[index].value) {
          addToCart({
            name: "Trademark Creation",
            price: 24.99,
            fee: 1.99,
            type,
          });
          setTrademarkRegistration(true);
        } else {
          removeFromCart("Trademark Creation");
          setTrademarkRegistration(false);
        }
        break;
      case "registration":
        if (options[index].value) {
          addToCart({
            name: "Business Registration",
            price: 124.99,
            fee: 4.99,
            type,
          });
          setBusinessRegistration(true);
        } else {
          removeFromCart("Business Registration");
          setBusinessRegistration(false);
        }
        break;

      case "template-selection":
        if (options[index].value) {
          setTemplateSelection(true);
        } else {
          setTemplateSelection(false);
        }
        break;

      case "audience-size":
        if (options[index].value) {
          setAudienceSize(options[index].value);
        } else {
          setAudienceSize(null);
        }
        break;

      case "website-speciality":
        if (options[index].value) {
          setWebsiteSpeciality(options[index].value);
        } else {
          setWebsiteSpeciality(null);
        }

        break;

      case "template-type":
        if (options[index].value) {
          setTemplateType(options[index].value);
        } else {
          setTemplateType(null);
        }
        break;

      default:
        return null;
    }
  };

  console.log({ selected });

  useEffect(() => {
    handleChange(0);
  }, []);

  return (
    <Layout bgcolor="light">
      <div className={navbar ? wrappers[1] : wrappers[0]}>
        <h1 className="font-black tracking-[-1.02px] leading-tight text-center text-3xl md:text-[40px] mb-12">
          {question}
        </h1>
        {!componentType &&
          options
            .filter(
              (option) => !getIsDomainSelected() || option.value !== "Domain"
            )
            .map((option, index) => {
              const Component = option.path ? Link : "p";
              return (
                <button
                  key={index}
                  type="button"
                  onClick={() => handleChange(index)}
                  className={active === index ? classes[1] : classes[0]}
                >
                  <div className="flex items-center space-x-5">
                    {option.icon && (
                      <div
                        className={cn(
                          "bg-white rounded-full w-12 h-12 text-2xl flex items-center justify-center text-black -ml-5",
                          {
                            "bg-transparent": active !== index,
                          }
                        )}
                      >
                        {option.icon}
                      </div>
                    )}

                    <Component href={option.path}> {option.label}</Component>
                  </div>
                  {active === index ? (
                    <img
                      src="green-check.svg"
                      className="mb-px transform scale-[0.8]"
                      alt="check-icon"
                    />
                  ) : null}
                </button>
              );
            })}

        {componentType === "select" && (
          <div className="w-[90%] sm:w-3/4 max-w-2xl">
            <RoundedDropdown
              options={options}
              selected={selected || options[0].value}
              onChange={(value) => {
                handleChange(
                  options.findIndex((option) => option.value === value)
                );
                setSelected(value);
              }}
            />
          </div>
        )}

        {componentType === "radio" && (
          <div className="w-[90%] sm:w-3/4 max-w-2xl space-y-6">
            {options.map((option, i) => (
              <div
                key={i}
                className={cn(
                  "p-5 flex items-center space-x-4 rounded-md border border-[#EAEAEA] bg-white w-full gap-5 justify-between",
                  {
                    "border-button bg-button/10": selected === option.value,
                  }
                )}
              >
                <div className="flex items-center space-x-5">
                  <div
                    className={cn(
                      "p-1.5 rounded-full border border-button bg-white w-12 h-12 flex items-center justify-center text-3xl"
                    )}
                  >
                    {option.icon}
                  </div>

                  <div>
                    <div className="flex items-center space-x-3">
                      <h5 className="text-lg font-bold text-dark">
                        {option.label}
                      </h5>

                      {option.info && (
                        <Tooltip text={option.info}>
                          <AiOutlineInfoCircle size={20} />
                        </Tooltip>
                      )}
                    </div>

                    <p className="text-sm font-bold text-button">
                      {option.subLabel}
                    </p>
                  </div>
                </div>

                {selected !== option.value ? (
                  <button
                    className="rounded-md border border-button text-button font-extrabold w-[124px] h-10 hover:bg-button hover:text-white transition-all"
                    onClick={() => {
                      handleChange(i);
                      setSelected(option.value);
                    }}
                  >
                    Choose
                  </button>
                ) : (
                  <button className="rounded-md border border-button bg-button font-extrabold w-10 h-10 flex items-center justify-center text-white">
                    <FaCheck />
                  </button>
                )}
              </div>
            ))}
          </div>
        )}

        {/* <RoundedInput placeholder="Enter website name" /> */}

        {/* <div className="w-[90%] sm:w-3/4 max-w-2xl">
          <div className="p-4 rounded-2xl border border-[#D9D9D9] bg-white shadow-primary">
            <h5 className="mb-[10px] text-sm text-[#363636]">
              Connect a domain you own
            </h5>

            <div className="relative flex items-center">
              <input
                type="text"
                className="border border-[#D9D9D9] w-full h-10 text-sm text-[#363636] outline-none px-2"
              />
              <button className="w-[103px] h-10 bg-button text-white font-extrabold">
                Connect
              </button>
            </div>
          </div>
        </div> */}

        {pageName === "registration" && !options[active].value && (
          <p className="text-left text-gray-700">
            Learn more about business registration{" "}
            <Link to="/knowledgebase" className="text-blue-500 hover:underline">
              here
            </Link>
          </p>
        )}

        {pageName === "trademark" &&
          type === "Personal" &&
          !options[active].value && (
            <p className="text-left text-gray-700">
              Learn more about trademark registration{" "}
              <Link
                to="/knowledgebase"
                className="text-blue-500 hover:underline"
              >
                here
              </Link>
            </p>
          )}

        {pageName === "purpose" &&
          !getIsDomainSelected() &&
          !domainViewActive && (
            <p className="text-left text-gray-700">
              do you need a domain?{" "}
              <span
                className="text-blue-500 hover:underline cursor-pointer"
                onClick={() => setDomainViewActive(true)}
              >
                click here
              </span>
            </p>
          )}

        {pageName === "purpose" &&
          !getIsDomainSelected() &&
          domainViewActive && (
            <div className="mt-5">
              <SearchDomainCard />
            </div>
          )}

        <button
          Baseton
          onClick={async () => {
            console.log("link", await link());
            history.push(await link());
            setDomainViewActive(false);
          }}
          className="mt-16 text-lg text-button hover:underline focus:outline-none"
        >
          Next &#8594;
        </button>
      </div>
    </Layout>
  );
};

export default QuestionScreen;

const classes = [
  "w-[90%] sm:w-3/4 max-w-2xl h-[60px] my-2 pt-px pl-7 pr-1 flex flex-row items-center justify-between tracking-[-1px] md:text-xl font-bold rounded-full text-button bg-white shadow-lg focus:outline-none",
  "w-[90%] sm:w-3/4 max-w-2xl h-[60px] my-2 pt-px pl-7 pr-1 flex flex-row items-center justify-between tracking-[-1px] md:text-xl font-bold rounded-full text-white bg-button focus:outline-none",
];
const wrappers = [
  "relative m-auto -mt-12 flex max-w-[90%] w-[710px] flex-col justify-center items-center h-screen",
  "relative m-auto -mt-12 flex max-w-[90%] w-[710px] flex-col justify-center items-center h-[calc(100vh-20px)] md:h-[calc(100vh-40px)]",
];
