/*
entry : Notion block of type numbered_list_item with subblocks of type bulleted_list_item
onToggle: (index) => void;
*/
const FaqElement = ({ entry, isExpanded, onToggle }) => {
  if (!entry.subBlocks?.length) return <></>;
  return (
    <div className="p-4 border-t border-gray">
      <div className="text-base cursor-pointer flex flex-row justify-between gap-3 items-center" onClick={onToggle}>
        {entry.content.numbered_list_item?.rich_text?.at(0)?.text.content}
        <i className={`fa ${isExpanded ? "fa-chevron-up" : "fa-chevron-down"} text-xs`} />
      </div>
      {isExpanded && entry.subBlocks.map(answer => 
        <div key={answer.content.id} className="text-sm pt-2 text-[#42526D]">
          {answer.content.bulleted_list_item?.rich_text?.at(0)?.text.content}
        </div>
        )}
    </div>
  );
};

export default FaqElement;
