import axios from "axios";
import AuthLayout from "./AuthLayout";
import { useState } from "react";

const VerificationForm = ({ setStepnumber, sendFormObject, formObject }) => {
  const [errorMsg, setErrorMsg] = useState("");
  const verificationDigits = 6;

  const concatCodeInputs = () => {
    let fullCode = "";
    let allInputs = document.querySelectorAll("input[codenumber-attribute]");
    allInputs.forEach((input) => {
      fullCode += input.value;
    });
    return fullCode;
  };

  const submitForm = async () => {
    try {
      console.log(formObject);

      const phoneVerificationConfig = {
        method: "post",
        url: "/verifyCode",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          phone: formObject.phone,
          code: concatCodeInputs(),
        },
      };

      const phoneVerificationResponse = await axios(phoneVerificationConfig);
      console.log(
        "Response from phoneVerificationConfig:",
        phoneVerificationResponse.data
      );

      if (phoneVerificationResponse.data === "approved") {
        const sendFormResponse = await sendFormObject();
        if (sendFormResponse) {
          console.log(
            "Response from sendFormObject was successful (200 status)"
          );
          setStepnumber(3);
        } else {
          console.log("Response from sendFormObject was not successful");
        }
      } else {
        console.error("Phone verification was not approved");
        setErrorMsg("Phone verification was not approved. Please try again.");
      }
    } catch (error) {
      console.error("Error occurred during form submission:", error);
    }
  };

  const handleKeyUp = (event, codeInputNumber) => {
    let element = event.target;
    let nextNumber = (codeInputNumber + 1) % verificationDigits;
    setErrorMsg("");

    if (event.keyCode != 8 && nextNumber != 0) {
      let next = document.querySelector(
        `input[codenumber-attribute='${nextNumber}']`
      );
      next.focus();
    }
  };

  return (
    <AuthLayout>
      <form className="relative h-full w-full max-w-[90%] md:w-1/2 md:max-w-xl mt-8 md:-mt-16 flex flex-col justify-start md:justify-center">
        <h2 className="text-3xl md:text-4xl mb-12 md:mb-10">
          Please enter the 6-digit code
        </h2>
        {errorMsg && <p className="text-red-500">{errorMsg}</p>}
        <div className="flex flex-row justify-center gap-4 md:gap-8">
          <input
            type="number"
            defaultValue=""
            maxLength={1}
            codenumber-attribute="0"
            onKeyUp={(e) => {
              handleKeyUp(e, 0);
            }}
            className="w-16 md:w-20 h-14 md:h-[72px] text-xl md:text-2xl pl-[26px] md:pl-8 pt-1 text-button rounded focus:outline-none"
          />
          <input
            type="number"
            defaultValue=""
            maxLength={1}
            codenumber-attribute="1"
            onKeyUp={(e) => {
              handleKeyUp(e, 1);
            }}
            className="w-16 md:w-20 h-14 md:h-[72px] text-xl md:text-2xl pl-[26px] md:pl-8 pt-1 text-button rounded focus:outline-none"
          />
          <input
            type="number"
            defaultValue=""
            maxLength={1}
            codenumber-attribute="2"
            onKeyUp={(e) => {
              handleKeyUp(e, 2);
            }}
            className="w-16 md:w-20 h-14 md:h-[72px] text-xl md:text-2xl pl-[26px] md:pl-8 pt-1 text-button rounded focus:outline-none"
          />
          <input
            type="number"
            defaultValue=""
            maxLength={1}
            codenumber-attribute="3"
            onKeyUp={(e) => {
              handleKeyUp(e, 3);
            }}
            className="w-16 md:w-20 h-14 md:h-[72px] text-xl md:text-2xl pl-[26px] md:pl-8 pt-1 text-button rounded focus:outline-none"
          />
          <input
            type="number"
            defaultValue=""
            maxLength={1}
            codenumber-attribute="4"
            onKeyUp={(e) => {
              handleKeyUp(e, 4);
            }}
            className="w-16 md:w-20 h-14 md:h-[72px] text-xl md:text-2xl pl-[26px] md:pl-8 pt-1 text-button rounded focus:outline-none"
          />
          <input
            type="number"
            defaultValue=""
            maxLength={1}
            codenumber-attribute="5"
            onKeyUp={(e) => {
              handleKeyUp(e, 5);
            }}
            className="w-16 md:w-20 h-14 md:h-[72px] text-xl md:text-2xl pl-[26px] md:pl-8 pt-1 text-button rounded focus:outline-none"
          />
        </div>
        <button
          type="button"
          onClick={() => submitForm()}
          className="bg-button w-full h-11 mt-16 md:mt-20 text-white font-bold rounded hover:bg-[#177fc0] transition duration-300 focus:outline-none"
        >
          Confirm
        </button>
      </form>
    </AuthLayout>
  );
};

export default VerificationForm;
