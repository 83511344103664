import React from "react";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getPath } from "../utils/getPath";

const StaticSiteCustomization = () => {
  const history = useHistory();
  return (
    <div className="md:grid md:grid-cols-2 h-screen">
      <div className="py-6 px-3 lg:p-10 bg-[#F9F9F9]">
        <div>
          <h2 className="text-[#000] text-2xl lg:text-[40px] font-black">
            Let's customize your site design
          </h2>
        </div>
        <div className="">
          <div className="mt-6 flex flex-col">
            <label
              className="text-[#373737] text-base lg:text-lg font-bold"
              htmlFor=""
            >
              What do you want this text to say in box 1
            </label>
            <input
              className="max-w-[678px] lg:max-w-full w-full py-2 lg:py-3.5 px-6 rounded-full border border-[#373737] mt-2 outline-[#0197F5] outline-[2px]"
              placeholder="Heading"
              type="text"
            />
          </div>
          <div className="mt-6 flex flex-col">
            <label
              className="text-[#373737] text-base lg:text-lg font-bold"
              htmlFor=""
            >
              What do you want this text to say in box 2
            </label>
            <input
              className="max-w-[678px] lg:max-w-full w-full py-2 lg:py-3.5 px-6 rounded-full border border-[#373737] mt-2 outline-[#0197F5] outline-[2px]"
              placeholder="Heading"
              type="text"
            />
          </div>
          <div className="mt-6 flex flex-col">
            <label
              className="text-[#373737] text-base lg:text-lg font-bold"
              htmlFor=""
            >
              What do you want this text to say in box 3
            </label>
            <input
              className="max-w-[678px] lg:max-w-full w-full py-2 lg:py-3.5 px-6 rounded-full border border-[#373737] mt-2 outline-[#0197F5] outline-[2px]"
              placeholder="Heading"
              type="text"
            />
          </div>
          <div className="mt-6 flex flex-col">
            <label
              className="text-[#373737] text-base lg:text-lg font-bold"
              htmlFor=""
            >
              What do you want this text to say in box 4
            </label>
            <input
              className="max-w-[678px] lg:max-w-full w-full py-2 lg:py-3.5 px-6 rounded-full border border-[#373737] mt-2 outline-[#0197F5] outline-[2px]"
              placeholder="Heading"
              type="text"
            />
          </div>
          <div className="mt-6 flex flex-col">
            <label
              className="text-[#373737] text-base lg:text-lg font-bold"
              htmlFor=""
            >
              What do you want this text to say in box 5
            </label>
            <input
              className="max-w-[678px] lg:max-w-full w-full py-2 lg:py-3.5 px-6 rounded-full border border-[#373737] mt-2 outline-[#0197F5] outline-[2px]"
              placeholder="Heading"
              type="text"
            />
          </div>
          <div className="mt-6 flex flex-col">
            <label
              className="text-[#373737] text-base lg:text-lg font-bold"
              htmlFor=""
            >
              What do you want this text to say in box 6
            </label>
            <input
              className="max-w-[678px] lg:max-w-full w-full py-2 lg:py-3.5 px-6 rounded-full border border-[#373737] mt-2 outline-[#0197F5] outline-[2px]"
              placeholder="Heading"
              type="text"
            />
          </div>
        </div>
        <div className="mt-9 flex justify-center items-center">
          <Link to={() => getPath("static-site-customization")}>
            <button className="rounded bg-[#0197F5] px-5 py-3 font-extrabold text-[#FFF]">
              Generate
            </button>
          </Link>
        </div>
        <div
          className="hidden lg:block cursor-pointer"
          onClick={() => history.goBack()}
        >
          ← Back
        </div>
      </div>
      <div className="pt-5 px-3 lg:px-5 bg-[#0197F5] relative">
        <p className="mb-5 text-[#FFF] text-lg font-bold">Preview Design</p>
        <img
          src="/images/state-site-customization.png"
          alt=""
          className="absolute bottom-0"
        />
      </div>
    </div>
  );
};

export default StaticSiteCustomization;
