import { Dialog } from "@headlessui/react";
import React from "react";
import { LiaTimesSolid } from "react-icons/lia";
import SiteSettingForm from "./SiteSettingForm";

const SiteSettingModal = ({ isOpen, close, activeSite }) => {
  return (
    <Dialog open={isOpen} onClose={close}>
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      <Dialog.Panel className="fixed inset-0 flex items-center justify-center p-4">
        <div className="p-5 bg-[#FFFFFF] rounded-[4px]">
          <div className="flex justify-between items-center mb-12">
            <h4 className="font-extrabold text-xl text-[#164360] uppercase">
              Site Settings
            </h4>

            <LiaTimesSolid size={25} onClick={close} cursor="pointer" />
          </div>
          <div className="bg-[#FEE5B5] p-2 rounded-[4px]">
            <p className="text-base text-[#164360]">
              Warning Site config changes will affect your website URL
              accessibility
            </p>
          </div>
          <SiteSettingForm close={close} defaultValues={{ ...activeSite }} />
        </div>
      </Dialog.Panel>
    </Dialog>
  );
};

export default SiteSettingModal;
