
export const findInTree = (tree, predicate, childrenGetter) => {
    if (predicate(tree)) return tree;

    let found = undefined;
    for (const element of childrenGetter(tree)) {
        found = findInTree(element, predicate, childrenGetter);
        if (found) break;
    }
    return found;
}

export const pathToItem = (tree, predicate, childrenGetter, path) => { 
    if (predicate(tree)) return path.concat([tree]);

    let found = undefined;
    for (const element of childrenGetter(tree)) {
        found = pathToItem(element, predicate, childrenGetter, path.concat([tree]));
        if (found) break;
    }
    return found;
}