// export const templateData = {
//   All: [
//     {
//       imagesrc: "/",
//       name: "template 2",
//       category: "business",
//     },
//     {
//       imagesrc: "/",
//       name: "template 3",
//       category: "music",
//     },
//     {
//       imagesrc: "/",
//       name: "template 4",
//       category: "fashion",
//     },
//     {
//       imagesrc: "/",
//       name: "template 5",
//       category: "business",
//     },
//     {
//       imagesrc: "/",
//       name: "template 6",
//       category: "sports",
//     },
//     {
//       imagesrc: "/",
//       name: "template 7",
//       category: "business",
//     },
//     {
//       imagesrc: "/",
//       name: "template 8",
//       category: "sports",
//     },
//     {
//       imagesrc: "/",
//       name: "template 9",
//       category: "fashion",
//     },
//   ],
//   "Static Site": [
//     {
//       imagesrc: "/",
//       name: "template 1",
//       category: "fashion",
//     },
//     {
//       imagesrc: "/",
//       name: "template 2",
//       category: "business",
//     },
//     {
//       imagesrc: "/",
//       name: "template 3",
//       category: "music",
//     },
//     {
//       imagesrc: "/",
//       name: "template 4",
//       category: "fashion",
//     },
//     {
//       imagesrc: "/",
//       name: "template 5",
//       category: "business",
//     },
//     {
//       imagesrc: "/",
//       name: "template 6",
//       category: "sports",
//     },
//     {
//       imagesrc: "/",
//       name: "template 7",
//       category: "business",
//     },
//     {
//       imagesrc: "/",
//       name: "template 8",
//       category: "sports",
//     },
//     {
//       imagesrc: "/",
//       name: "template 9",
//       category: "fashion",
//     },
//   ],
//   "Ghost Site": [
//     {
//       imagesrc: "/",
//       name: "template 1",
//       category: "fashion",
//     },
//     {
//       imagesrc: "/",
//       name: "template 2",
//       category: "business",
//     },
//     {
//       imagesrc: "/",
//       name: "template 3",
//       category: "music",
//     },
//     {
//       imagesrc: "/",
//       name: "template 4",
//       category: "fashion",
//     },
//     {
//       imagesrc: "/",
//       name: "template 5",
//       category: "business",
//     },
//     {
//       imagesrc: "/",
//       name: "template 6",
//       category: "sports",
//     },
//     {
//       imagesrc: "/",
//       name: "template 7",
//       category: "business",
//     },
//     {
//       imagesrc: "/",
//       name: "template 8",
//       category: "sports",
//     },
//     {
//       imagesrc: "/",
//       name: "template 9",
//       category: "fashion",
//     },
//   ],
// };

export const templateData = [
  {
    imagesrc: "/",
    name: "template 1",
    category: "fashion",
    provider: "Static Site",
  },
  {
    imagesrc: "/",
    name: "template 2",
    category: "business",
    provider: "Static Site",
  },
  {
    imagesrc: "/",
    name: "template 3",
    category: "music",
    provider: "Static Site",
  },
  {
    imagesrc: "/",
    name: "template 4",
    category: "fashion",
    provider: "Static Site",
  },
  {
    imagesrc: "/",
    name: "template 5",
    category: "business",
    provider: "Static Site",
  },
  {
    imagesrc: "/",
    name: "template 6",
    category: "sports",
    provider: "Ghost Site",
  },
  {
    imagesrc: "/",
    name: "template 7",
    category: "business",
    provider: "Ghost Site",
  },
  {
    imagesrc: "/",
    name: "template 8",
    category: "sports",
    provider: "Ghost Site",
  },
  {
    imagesrc: "/",
    name: "template 9",
    category: "fashion",
    provider: "Ghost Site",
  },
  {
    imagesrc: "/",
    name: "template 10",
    category: "music",
    provider: "Ghost Site",
  },
  {
    imagesrc: "/",
    name: "template 10",
    category: "sports",
    provider: "Static Site",
  },
];
