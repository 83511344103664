import { useState } from "react";
import MobileFaq from "./MobileFaq";

const MobileFaqDrawer = ({faq}) => {
  const [faqOpened, setFaqOpened] = useState(false);

  const heigh = faqOpened 
    ? "max-height-[80%] h-[80%] overflow-auto bg-white" 
    : "h-[54px] h-min-[54px] overflow-hidden bg-gray text-white"

  return (
    <div className={`xl:hidden sticky bottom-0 z-[1000] 
        transform ease-out duration-500
        sm:width-640-fixed lg:width-700-fixed mx-auto
        flex flex-col rounded-xl-top border-thick-top border-gray align-center ${heigh}`}>
      <div
        className="font-bold p-4 flex flex-row justify-center w-full gap-2"
        onClick={() => setFaqOpened(!faqOpened)}
      >
        FAQ
      </div>
      <MobileFaq faqOpened={faqOpened} blocks={faq} />
    </div>
  );
};

export default MobileFaqDrawer;
