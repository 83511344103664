import { SOCIAL } from "../../utils/data/social";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="flex flex-col items-center pt-14 md:pt-24 pb-16 md:pb-20 text-[#00204F] bg-[#EAF0FF]">
      <ul className="flex flex-row flex-wrap justify-center gap-4 md:gap-10 uppercase mb-12 md:mb-20">
        {buttons.map((button, i) => (
          <Link to={button.path} onClick={() => window.scrollTo(0, 0)}>
            <li
              key={i}
              className="font-extrabold cursor-pointer transition duration-300 hover:text-button"
            >
              {button.label}
            </li>
          </Link>
        ))}
      </ul>
      <p className="font-extrabold uppercase mb-4">Blank Cut</p>
      <p className="font-extralight text-center w-full md:w-1/2 max-w-4xl opacity-40">
        At Blank Cut, we’re dedicated to fostering entrepreneurship and
        creativity through our comprehensive platform, providing the tools,
        knowledge, and support essential for business success. We value
        transparency, integrity, and commitment to our users. Privacy Policy: We
        respect your privacy and are committed to protecting your personal
        information. Learn more about how we handle your data and your rights in
        our Privacy Policy. Terms of Service: By using our services, you agree
        to our Terms of Service. We encourage you to read them carefully to
        understand your rights and obligations when using Blank Cut. Contact Us:
        For any inquiries, suggestions, or support, feel free to reach out to us
        at support@blankcut.com. Blank Cut – Empowering Your Business Journey
        from the First Click."
      </p>
      <div className="bg-[#9EB4C5] h-px w-48 mt-8 md:mt-12 mb-10 md:mb-14" />
      <div className="flex flex-row gap-6">
        <a href={SOCIAL.FACEBOOK} target="_blank" rel="noreferrer">
          <i className="fa-facebook fa fa-border fa-lg fa-fw w-[34px] h-[34px] m-auto border border-[#9EB4C5] rounded-full pt-2 pl-[6px] cursor-pointer transform duration-300 hover:bg-[#00204F] hover:border-[#00204F] hover:text-white" />
        </a>
        <a href={SOCIAL.INSTAGRAM} target="_blank" rel="noreferrer">
          <i className="fa-instagram fa fa-border fa-lg fa-fw w-[34px] h-[34px] m-auto border border-[#9EB4C5] rounded-full pt-2 pl-[6px] cursor-pointer transform duration-300 hover:bg-[#00204F] hover:border-[#00204F] hover:text-white" />
        </a>
        <a href={SOCIAL.TWITTER} target="_blank" rel="noreferrer">
          <i className="fa-twitter fa fa-border fa-lg fa-fw w-[34px] h-[34px] m-auto border border-[#9EB4C5] rounded-full pt-2 pl-[6px] cursor-pointer transform duration-300 hover:bg-[#00204F] hover:border-[#00204F] hover:text-white" />
        </a>
      </div>
    </div>
  );
};

export default Footer;

const buttons = [
  {
    label: "Privacy Policy",
    path: "/privacy-policy",
  },
  {
    label: "Faqs",
    path: "/faq",
  },
  {
    label: "About Us",
    path: "/about-us",
  },
  {
    label: "Contact Us",
    path: "/contact-us",
  },
];
