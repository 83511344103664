import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import "tailwindcss/tailwind.css";
import "axios";
import axios from "axios";
import { useCart } from "../../store";
import { regexQuery, tldParser } from "../../utils/domainHelpers";

const SearchBody = () => {
  const properFormatParser = /[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]/g;
  const location = useLocation();
  const [query, setQuery] = React.useState("");
  const [error, setError] = React.useState(false);
  const [improperFormat, setImproperFormat] = React.useState(false);
  const [unsuppportedTLD, setUnsupportedTLD] = React.useState(false);
  const [unsupportedTLDExtensions, setUnsupportedTLDExtensions] =
    React.useState(new Set());
  const [unsupportedTLDExtArray, setUnsupportedTLDExtArray] = React.useState(
    []
  );
  const [showLoader, setShowLoader] = React.useState(false);
  const [callDisplayLoader, setCallDisplayLoader] = React.useState(false);
  const { domain, setDomain } = useCart();

  /* 
    Upon navigating to the searchDomain page, use useEffect to check if domain name search came from another search component 
    outside of the searchDomain page. If so, handle the search. If not, do nothing to avaoid unecessary calls to 
    checkDomainAvailability Lamda function
  */
  React.useEffect(() => {
    if (domain.outsideSearch) {
      //set domainOusideSearched context to false before handling the search
      setDomain({
        outsideSearch: false,
      });
      handleClick();
    }
  }, []);

  /* 
    Display a spinning loader when making a call to checkDomainAvailability Lambda function.
    Display a checkmark upon response from checkDomainAvailability. 
  */
  const displayLoaderType = () => {
    if (showLoader) {
      return (
        <svg
          className="checkmark"
          xmlns="http://www.w3.org/2000/svg"
          height="39px"
          width="39px"
          viewBox="0 0 24 24"
        >
          <circle className="checkmark__circle" cx="12" cy="12" fill="none" />
          <path
            className="checkmark__check"
            fill="none"
            d="M6.50769229 12.5538461l3.27692307 3.32307691 7.70769228 -7.75384613"
          />
        </svg>
      );
    } else {
      return (
        <svg
          className="animate-spin mr-3 h-10 w-10 text-blue-800"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      );
    }
  };

  /*
    Handle search button onClick by calling checkDomainAvailability Lambda function and processing erroneous inputs
   */
  const handleClick = () => {
    //initialize functionQuery to an empty string
    let functionQuery = new String("");

    //set domainAvailability context to an empty string
    setDomain({
      isAvailable: null,
    });

    //if no domain name is entered to search, return
    if (domain.searchQuery === "" && query === "") {
      return;
    }
    //else if query state contains a string (from handleChange function) set searchQuery context and functionQuery to query state
    else if (query !== "") {
      functionQuery = query;
      setDomain({
        searchQuery: query,
      });
    }
    //else set functionQuery and query state to searchQuery context (handled from outside search component)
    else {
      functionQuery = domain.searchQuery;
      setQuery(domain.searchQuery);
    }

    //initialize dataInput for user input query to an empty object
    let dataInput = {};

    //if user input (functionQuery) contains improper domain name formatting, then notify the user
    if (functionQuery.match(regexQuery) != functionQuery) {
      setCallDisplayLoader(false);
      setError(true);
      setImproperFormat(true);
      //if erroneous user input contains a TLD, find it and remove it prior to proper domain name formatting
      if (functionQuery.match(tldParser)) {
        functionQuery = functionQuery.split(tldParser)[0];
      }
      //convert improper user domain name format into a proper format
      let properFormatQuery = functionQuery.match(properFormatParser).join("");
      properFormatQuery += ".com";
      let searchInput = document.getElementById("searchInput");
      //set search input to proper domain name format
      searchInput.value = properFormatQuery;
      setDomain({
        searchQuery: properFormatQuery,
      });
      setQuery(properFormatQuery);
    }
    //else, update setCallDisplay to display loaders and create config for checkDomainAvailability Lambda function call
    else {
      setCallDisplayLoader(true);
      dataInput.domain_name = functionQuery;
      const domainAvailabilityAPIConfig = {
        method: "post",
        url: "/GetDomainAvailability",
        headers: {
          "Content-Type": "application/json",
        },
        data: dataInput, //send user input query to checkDomainAvailability Lambda function
      };

      //show spinning loader
      setShowLoader(false);

      //make call to checkDomainAvailability Lambda function and await response
      axios(domainAvailabilityAPIConfig).then((response) => {
        //if user entered an unsupported Top Level Domain (TLD) or extension, notify the user and display TLD suggestions
        if (response.data["unsupportedTLD"]) {
          setError(true);
          setUnsupportedTLD(true);
          //set callDisplayLoader to false in case user decides to not choose a TLD suggestion
          setCallDisplayLoader(false);
          //show checkmark to display completed work if user decides to choose a TLD suggestion
          setShowLoader(true);

          //assign unsupportedTLDExtensions state with the response of listed suggestions from checkDomainAvailability Lambda function call
          const suggestionsList = response.data["suggestionsList"];
          suggestionsList.map((suggestion) => {
            var priceWithShare = parseFloat(
              parseInt(suggestion["Price"]) +
                parseFloat(suggestion["Price"] * 0.75)
            ).toFixed(2);
            unsupportedTLDExtensions.add({
              name: suggestion["DomainName"],
              price: priceWithShare,
            }); //.match(context.regexExtension)[0] (in case we want to only output the extensions)
          });
          //set unsupportedTLDExtArray state with the converted contents of unsupportedTLDExtensions state
          setUnsupportedTLDExtArray(Array(...unsupportedTLDExtensions));
        }
        //else if the user entered a domain name that is already registered, then display checkmark and show domain name suggestions
        else if (
          response.data["suggestionsList"] &&
          response.data["domainUnavailable"]
        ) {
          setShowLoader(true);
          setDomain({
            isAvailable: false,
            price: 0.0,
          });

          setDomain({
            suggestionList: Array(response.data["suggestionsList"]),
          });
        }
        //else if the user entered an available domain name, then display checkmark and notify the user that the domain name is available
        else {
          setShowLoader(true);
          setDomain({
            isAvailable: true,
            price: parseFloat(
              response.data["Price"] + response.data["Price"] * 0.75
            ).toFixed(2),
          });
        }
      });
    }
  };

  /*
    Handle unsupported TLD suggested domain name clicks and populate search bar with the selected domain name
   */
  const clickUnspupported = (event, unsupportedTLDSuggestionPrice) => {
    //set callDisplayLoader to true to display checkmark
    setCallDisplayLoader(true);
    //replace search bar with selected domain name
    let newSearchQuery = event.target.innerText;
    //input price of the domain name suggestion

    let searchInput = document.getElementById("searchInput");
    searchInput.value = newSearchQuery;
    setDomain({
      searchQuery: newSearchQuery,
      price: unsupportedTLDSuggestionPrice,
    });
    setQuery(newSearchQuery);
    //notify the user that the domain name is available
    setDomain({
      isAvailable: true,
    });
    setError(false);
    //reset state for subsequent actions
    if (unsuppportedTLD) {
      setUnsupportedTLD(false);
      setUnsupportedTLDExtensions(new Set());
      setUnsupportedTLDExtArray([]);
    }
  };

  //set callDisplayLoader to false to display hide loaders and display search button
  const handleFocus = () => {
    setCallDisplayLoader(false);
  };

  const handleChange = (event) => {
    event.preventDefault();
    let changeEventQuery = event.target.value;
    changeEventQuery = changeEventQuery.replace("www.", "");
    setQuery(changeEventQuery);
  };

  return (
    <div className="flex flex-col items-center">
      <div
        className={
          domain.isAvailable | (domain.isAvailable === false)
            ? classes[0]
            : classes[1]
        }
      >
        <div>
          <h1 className="text-[34px] max-w-[100%] md:max-w-[100%] mx-auto md:text-[40px] text-center tracking-[-1px]">
            Start Your Domain Search
          </h1>
          <p className="text-button font-light text-center mb-3 text-lg md:text-xl">
            Find the ideal name for your website.
          </p>
        </div>
        <form
          onSubmit={(event) => event.preventDefault()}
          className="w-[110%] relative flex flex-row"
        >
          <input
            placeholder="Search Domain"
            type="text"
            defaultValue={domain.searchQuery}
            onChange={(event) => handleChange(event)}
            onKeyPress={(event) => event.key === "Enter" && handleClick()} //
            onFocus={() => handleFocus()}
            className="h-[60px] md:h-[68px] w-full max-w-[80%] md:max-w-none mx-auto px-6 md:px-10 rounded-full shadow-md box-border font-light focus:outline-none"
            id="searchInput"
          />
          <button
            type="button"
            onClick={() => handleClick()}
            onKeyPress={(event) => event.key === "Enter" && handleClick()} //
            className="bg-button absolute right-10 md:right-0 w-[90px] md:w-[140px] h-full flex items-center justify-center rounded-full border-4 border-white transform duration-300 hover:bg-[#177fc0] focus:outline-none"
          >
            {callDisplayLoader ? (
              displayLoaderType()
            ) : (
              <img src="/search-icon.svg" alt="search-icon" width="auto" />
            )}
          </button>
        </form>
        <div className="flex flex-col md:flex-row items-center gap-4 md:gap-8 -mt-2">
          <Link className="font-bold w-max">Already have a domain?</Link>
        </div>
      </div>
      {error ? (
        <div
          className="fixed z-10 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
          id="modal-window"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-400 sm:mx-0 sm:h-10 sm:w-10">
                    <svg
                      className="h-6 w-6 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                      />
                    </svg>
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left ">
                    {improperFormat ? (
                      <h3
                        className="text-lg leading-6 font-medium text-gray-900"
                        id="modal-title"
                      >
                        There was an issue your domain name format. Please type
                        your domain name in the following format...
                      </h3>
                    ) : null}
                    {unsuppportedTLD ? (
                      <h3
                        className="text-lg leading-6 font-medium text-gray-900"
                        id="modal-title"
                      >
                        Sorry, your domain name extension is currently
                        unsupported. Maybe try one of these...
                      </h3>
                    ) : null}
                  </div>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="h-12 w-12 sm:h-10 sm:w-10 flex-shrink-0 sm:ml-4"></div>
                  <div className="mt-6 overflow-auto unsupportedTLDSuggestions">
                    {improperFormat ? (
                      <p className="text-sm content-center text-gray-500">
                        example.com
                      </p>
                    ) : null}
                    {unsuppportedTLD ? (
                      <div className="sm:flex text-xs font-light text-white content-center space-x-2 mb-2">
                        {unsupportedTLDExtArray.map((index, value) => {
                          return (
                            <div
                              className="rounded-full bg-black px-3 py-1 unsupportedSuggestionDomains"
                              key={value}
                              onClick={(event) =>
                                clickUnspupported(event, index["price"])
                              }
                            >
                              {index["name"]}
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  onClick={() => {
                    //reset state onClick for subsequent actions
                    setCallDisplayLoader(false);
                    setError(false);
                    if (unsuppportedTLD) {
                      setUnsupportedTLD(false);
                      setUnsupportedTLDExtensions(new Set());
                      setUnsupportedTLDExtArray([]);
                    }
                    if (improperFormat) {
                      setImproperFormat(false);
                    }
                  }}
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent px-4 py-1 bg-gotIt-button text-base font-medium hover:bg-black hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-blue-400 sm:ml-3 sm:w-auto sm:text-sm"
                  id="confirm"
                >
                  Got it!
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SearchBody;

//conditional margin sizes for domain availability
const classes = [
  "my-24 w-screen m-auto max-w-3xl flex flex-col gap-10 items-center justify-center",
  "my-52 w-screen m-auto max-w-3xl flex flex-col gap-10 items-center justify-center",
];
