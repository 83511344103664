import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { BiChevronDown, BiHelpCircle } from "react-icons/bi";
import { Listbox, Popover } from "@headlessui/react";

const schema = Yup.object().shape({
  projectName: Yup.string().required("Project name is required"),
  siteUrl: Yup.string().required("Site URL is required"),
});

const people = [
  { id: 1, name: "farizsidik.com", unavailable: false },
  { id: 2, name: "farizsidik.com", unavailable: false },
  { id: 3, name: "farizsidik.com", unavailable: false },
  { id: 4, name: "farizsidik.com", unavailable: true },
  { id: 5, name: "farizsidik.com", unavailable: false },
];

const SiteSettingForm = ({ defaultValues, close }) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      projectName: defaultValues.name,
      siteUrl: defaultValues.name,
    },
    resolver: yupResolver(schema),
  });
  const [selectedDomain, setSelectedDomain] = React.useState(people[0]);
  const onSubmit = handleSubmit(async (values) => {});

  return (
    <form onSubmit={onSubmit}>
      <div className=" flex flex-col items-baseline justify-end w-full mt-5">
        <label
          className="mb-1.5 text-[#00204F] text-base font-extrabold"
          htmlFor="projectName"
        >
          Project Name
        </label>
        <input
          className="border border-[#D0D5DD] p-4 rounded-md w-full "
          type="text"
          placeholder="Name your Projects"
          id="projectName"
          {...register("projectName")}
        />
      </div>
      <div className="flex flex-col items-baseline mt-5">
        <div>
          <h5 className="mb-1.5 text-[#00204F] text-base font-extrabold">
            Site URL Setings
          </h5>

          <div className="flex items-center space-x-1 p-2">
            <input
              className="w-[17px] h-[17px] mt-[-2px] checked:accent-[#000000]"
              type="radio"
              id="customDomain"
              {...register("siteUrl")}
            />
            <label
              className="text-[#00204F] text-base font-normal"
              htmlFor="customDomain"
            >
              Custom Domain
            </label>
            <Popover className="relative">
              <Popover.Button>
                <BiHelpCircle cursor="pointer" size={25} />
              </Popover.Button>

              <Popover.Panel className="absolute -top-5 left-0 min-w-[80px] bg-gray-900 px-1 rounded">
                <p className="text-center text-xs text-white font-medium">
                  Help
                </p>
              </Popover.Panel>
            </Popover>
          </div>
        </div>
      </div>

      <Listbox
        value={selectedDomain}
        onChange={setSelectedDomain}
        className="text-[#00204F] p-4 pl-2 pt-1 relative"
        as="div"
      >
        <Listbox.Button className="border border-[#F4F6F9] bg-[#F4F6F9] p-4 flex justify-between items-center w-full text-[#00204F]">
          <p className="text-[#0197F5] font-bold text-base">farizsidik.com</p>
          <BiChevronDown cursor="pointer" size={20} className="bg-white" />
        </Listbox.Button>
        <Listbox.Options className="absolute inset-0 top-[62px] p-4 pl-2 pt-0 z-10">
          {people.map((person) => (
            <Listbox.Option
              key={person.id}
              value={person}
              disabled={person.unavailable}
              className="border-y border-gray-300 first-of-type:border-t-1 bg-[#F4F6F9] p-4 flex justify-between items-center w-full text-[#00204F]"
            >
              {person.name}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
      <div className="my-1">
        <div className="flex items-center space-x-1 p-3 pl-2">
          <input
            className="w-[17px] h-[17px] mt-[-2px] checked:accent-[#000000]"
            type="radio"
            id="blankcutSubdomain"
            {...register("siteUrl")}
          />
          <label
            className="text-[#00204F] text-base font-normal"
            htmlFor="blankcutSubdomain"
          >
            Blankcut Subdomain
          </label>
          <Popover className="relative">
            <Popover.Button>
              <BiHelpCircle cursor="pointer" size={25} />
            </Popover.Button>

            <Popover.Panel className="absolute -top-5 left-0 min-w-[80px] bg-gray-900 px-1 rounded">
              <p className="text-center text-xs text-white font-medium">Help</p>
            </Popover.Panel>
          </Popover>
        </div>
      </div>
      <div className="p-2 mt-1">
        <p className="text-[#164360] font base">
          Unpublish Site , save on draft
        </p>
      </div>
      <div className="mt-1 mb-5">
        <div className="flex items-center space-x-1 p-3 pl-2">
          <input
            className="w-[17px] h-[17px] mt-[-2px] checked:accent-[#000000]"
            type="radio"
            id="unpublishWebsite"
            {...register("siteUrl")}
          />
          <label
            className="text-[#00204F] text-base font-normal"
            htmlFor="unpublishWebsite"
          >
            Unpublish Website
          </label>
          <Popover className="relative">
            <Popover.Button>
              <BiHelpCircle cursor="pointer" size={25} />
            </Popover.Button>

            <Popover.Panel className="absolute -top-5 left-0 min-w-[80px] bg-gray-900 px-1 rounded">
              <p className="text-center text-xs text-white font-medium">Help</p>
            </Popover.Panel>
          </Popover>
        </div>
      </div>
      <div className="space-x-3 flex sm:justify-end mt-5">
        <button
          className="text-[#164360] font-extrabold border border-[#EAEAEA] bg-[#FAFAFA] p-[15px] h-[41px] rounded-[4px] flex justify-center items-center"
          onClick={close}
        >
          Cancel
        </button>
        <button className="font-extrabold text-[#FFF] bg-[#0197F5] p-[15px] rounded-[4px] h-[41px] flex justify-center items-center">
          Save Changes
        </button>
      </div>
    </form>
  );
};

export default SiteSettingForm;
