import AuthLayout from "./AuthLayout";
import axios from "axios";
import { useState } from "react";

const SignupForm = ({ setStepnumber, appendInputToFormObject }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [showLoginLink, setShowLoginLink] = useState(false);

  const submitInput = async () => {
    const email = document.querySelector("input[name='email']").value;

    try {
      const response = await axios.post("/checkUsersExistence", { email });

      if (response.data.exists) {
        setErrorMessage("This email is already associated with an account.");
        setShowLoginLink(true);
      } else {
        setStepnumber(1);
        appendInputToFormObject("email", email);
      }
    } catch (error) {
      console.error("Error checking email existence:", error);
      setErrorMessage("Something went wrong. Please try again.");
      setShowLoginLink(false);
    }
  };

  return (
    <AuthLayout>
      <form className="relative h-full w-full max-w-[90%] md:w-1/2 md:max-w-xl mt-8 md:-mt-16 flex flex-col justify-start md:justify-center">
        <h2 className="text-3xl md:text-4xl mb-4 md:mb-10">
          Welcome to Blank Cut!
        </h2>
        <a href="/google-signup">
          <button
            type="button"
            class="w-full text-white bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#4285F4]/55 mr-2 mb-2"
          >
            <svg
              class="mr-2 -ml-1 w-4 h-4"
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="google"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 488 512"
            >
              <path
                fill="currentColor"
                d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
              ></path>
            </svg>
            Join Us with Google
          </button>
        </a>

        <a href="/facebook-signup">
          <button
            type="button"
            class="w-full text-white bg-[#3b5998] hover:bg-[#3b5998]/90 focus:ring-4 focus:ring-[#3b5998]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 mr-2 mb-2"
          >
            <svg
              class="mr-2 -ml-1 w-4 h-4"
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="facebook-f"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path
                fill="currentColor"
                d="M279.1 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.4 0 225.4 0c-73.22 0-121.1 44.38-121.1 124.7v70.62H22.89V288h81.39v224h100.2V288z"
              ></path>
            </svg>
            Join Us with Facebook
          </button>
        </a>

        <div className="flex flex-row items-center justify-center mt-0 md:mt-2.5 mb-0 md:mb-2 gap-2.5">
          <div className="w-full h-px bg-[#0197F5]" />
          <p className="text-gray-400 text-center">Or</p>
          <div className="w-full h-px bg-[#0197F5]" />
        </div>

        <input
          name="email"
          type="text"
          placeholder="Email address"
          defaultValue=""
          className="w-full h-11 my-4 px-6 pt-1 rounded focus:outline-none"
        />

        <button
          type="button"
          onClick={() => submitInput()}
          className="bg-[#0197F5] w-full h-11 mt-0 md:mt-2 text-white font-bold rounded hover:bg-[#177fc0] transition duration-300 focus:outline-none"
        >
          Continue
        </button>

        {errorMessage && <p className="mt-4 text-red-500">{errorMessage}</p>}
        {showLoginLink && (
          <p className="mt-2 text-blue-500 cursor-pointer">
            <a href="/login">Login instead</a>
          </p>
        )}
      </form>
    </AuthLayout>
  );
};

export default SignupForm;
