import { Menu } from "@headlessui/react";
import React, { Fragment } from "react";
import { HiEye, HiOutlineDuplicate } from "react-icons/hi";
import { HiCog8Tooth } from "react-icons/hi2";
import { FiMoreVertical } from "react-icons/fi";
import { RxTrash } from "react-icons/rx";

const SiteCard = ({ openSiteSettingModal, name, status }) => {
  return (
    <div className="p-5 bg-white rounded-[4px] max-w-[385px] w-full md:mx-0 border border-[#EAEAEA]">
      <div className="space-y-6 mb-6">
        <h5 className="text-dark text-xl font-extrabold">{name}</h5>
        <p className="font-semibold text-button">{status}</p>
      </div>
      <div className="flex items-center justify-between pt-6 border-t border-t-[#EEE] flex-wrap">
        <div className="">
          <button className="flex items-center gap-1 text-button font-semibold border border-button p-2.5 rounded-[4px] w-full lg:w-auto">
            <img src="/icons/pen.svg" alt="" />
            Edit Site
          </button>
        </div>
        <div className="flex gap-x-8 items-center text-dark">
          <HiEye size={25} color="#164360" cursor="pointer" onClick={{}} />
          <HiCog8Tooth
            size={25}
            color="#164360"
            cursor="pointer"
            onClick={openSiteSettingModal}
          />
          <Menu as="div" className="relative flex">
            <Menu.Button>
              <FiMoreVertical size={25} color="#164360" />
            </Menu.Button>
            <Menu.Items className="absolute left-0 top-6 mt-2 w-52 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <Menu.Item as={Fragment}>
                <button className="flex space-x-3 items-center p-[15px] w-full bg-white">
                  <HiOutlineDuplicate size={25} color="#164360" />
                  <p className="text-dark">Duplicate Site</p>
                </button>
              </Menu.Item>
              <Menu.Item as={Fragment}>
                <button className="flex space-x-3 items-center p-[15px] w-full bg-white border-t border-t-[#EEE]">
                  <RxTrash size={25} color="#F50101" />
                  <p className="text-[#F50101]">Delete Site</p>
                </button>
              </Menu.Item>
            </Menu.Items>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default SiteCard;
