import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Layout from "../Layout";
import Navbar from "../components/Reusable/Navbar";
import BreadCrumb from "../components/Reusable/BreadCrumb";
import Content from "../components/Article/Content";
import axios from "axios";

const ArticleEinCreationProcess = () => {
  const [dataState, setDataState] = useState({
    article: undefined,
    isLoading: false,
    error: undefined,
  });
  const history = useHistory();

  useEffect(
    () => {
      const articleCall = {
        method: "GET",
        url: "/articles/business/EIN%20Creation%20Process",
        headers: {
          "Content-Type": "application/json",
        },
      };

      setDataState({ article: undefined, isLoading: true, error: undefined });
      axios(articleCall)
        .then((result) => {
          setDataState({
            article: result.data,
            isLoading: false,
            error: undefined,
          });
        })
        .catch((e) => {
          console.error("Error gettings Article", e);
          setDataState({
            article: undefined,
            isLoading: false,
            error: "Error gettings Article",
          });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { article, isLoading, error } = dataState;
  const isReady = !!article;
  const noData = isReady && article.toc.length < 2;

  let page =
    isReady && !noData ? article.pagesIndex[article.toc[1].id] : undefined;

  const title = "EIN Creation Process";
  if (page) page.name = undefined;

  return (
    <div className="h-screen overflow-auto justify-center">
      <Navbar />
      <div className="flex flex-col h-min-full overflow-hidden">
        <Layout bgcolor="white">
          <div className="flex flex-col z-10 m-auto w-full max-w-4xl my-4 md:mt-10 md:mb-8">
            <i
              className="fa fa-arrow-left fa-lg cursor-pointer"
              onClick={() => {
                history.goBack();
              }}
            ></i>
            <h1 className="font-bold md:font-extrabold text-3xl md:text-5xl text-center text-dark">
              {title}
            </h1>
            <BreadCrumb steps={["Help", "Design Project", "Get Started"]} />
          </div>
        </Layout>
        {noData && (
          <Layout bgcolor="light" fill>
            <div className="text-2xl m-4 md:m-8">Under construction</div>
          </Layout>
        )}
        {isLoading && (
          <Layout bgcolor="light" fill>
            <div className="text-2xl m-4 md:m-8 text-error">
              Data Loading ...
            </div>
          </Layout>
        )}
        {error && (
          <Layout bgcolor="light" fill>
            <div className="text-2xl m-4 md:m-8 text-error">
              Error occured, please try later
            </div>
          </Layout>
        )}
        {isReady && !noData && (
          <Layout bgcolor="light" fill>
            <div className="flex flex-col items-center flex-fill bg-white md:bg-light">
              <div className="w-screen md:max-w-8xl flex flex-row gap-6 justify-center xl:my-8">
                <div className="w-full sm:flex-640-fixed lg:flex-700-fixed xl:flex-700-fixed xxl:flex-800-fixed">
                  <Content page={page} onNavigate={() => {}} />
                </div>
              </div>
            </div>
          </Layout>
        )}
      </div>
    </div>
  );
};

export default ArticleEinCreationProcess;
