import { useEffect, useState, useCallback } from "react";
import KnowledgeBaseSearch from "./KnowledgeBaseSearch";
import Layout from "../../Layout";

const KnowledgeBaseBanner = () => {
  const [shouldShrink, setShouldShrink] = useState(false);

  const onScroll = useCallback(() => {
    setShouldShrink(window.scrollY >= 112);
    //112; // 100 (h1 + h2) + 12 (my-5 - my-2)
  }, [setShouldShrink]);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [onScroll]);

  return (
    <Layout bgcolor="white" fixed={shouldShrink}>
      <div className="flex flex-col m-auto w-full max-w-4xl items-center">
        <h1
          className={`font-extrabold text-3xl md:text-4xl text-center mt-6 md:mt-20 text-[#164360] ${
            shouldShrink ? "hidden md:not-hidden" : ""
          }`}
        >
          Here to Support You!
        </h1>
        <h3
          className={`"text-xl md:text-xl text-center mt-2 md:mt-2.5 mb-2 md:mb-2.5 text-[#42526D] ${
            shouldShrink ? "hidden md:not-hidden" : ""
          }`}
        >
          Explore a wealth of articles in the BlankCut Help Center.
        </h3>
        <KnowledgeBaseSearch topFixed={shouldShrink} />
      </div>
    </Layout>
  );
};

export default KnowledgeBaseBanner;
