const BreadCrumb = ({steps}) => {
  return (
    <div className="flex flew-row gap-2 justify-center">
      {steps.map((step, index) => {
        return ( 
          <h3 key={step} className={`text-xl md:text-xl justify-center mt-1 md:mt-2.5 mb-1 md:mb-2.5 flex flex-row gap-2 
            ${(index + 1 === steps.length) ? "font-medium text-[#42526D]" : "text-gray-400"}`}
            >
            <span>{step}</span>
            {(index + 1 < steps.length) && <i className="fa fa-chevron-right text-sm mt-1" />}
          </h3>
        );
      })}
    </div>);
}

export default BreadCrumb;