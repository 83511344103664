import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useCart } from "../../store";

const SearchDomainCard = () => {
  const [domainName, setDomainName] = useState("");
  const history = useHistory();
  const { setDomain } = useCart();
  const handleSearch = () => {
    setDomain({
      searchQuery: domainName,
      outsideSearch: true,
    });
    history.push("/search");
  };
  const canSearch = !!domainName;

  return (
    <div className="flex flex-col">
      <div className="px-3 py-2 flex flex-row justify-between">
        <h2 className="text-base font-bold text-dark">
          Search For a Domain Name
        </h2>
      </div>
      <div className="mx-3 mb-1 bg-[#EEEEEE] h-[1px]"></div>
      <div className="m-3 p-1 flex flex-row items-center gap-2 cursor-pointer bg-[#FEE5B5]">
        <span className="text-[#344054] text-sm">
          Finding a domain name for your business is very important.
        </span>
        <img
          alt="question-icon"
          src="/question_mark.svg"
          className="w-[16px] h-[16px]"
        />
      </div>
      <div className="px-3 flex flex-col gap-1">
        <span className="text-sm font-bold text-[#00204F]">
          Domain Name Search
        </span>
        <input
          type="text"
          placeholder="Let’s find a name for your website."
          defaultValue=""
          onChange={(e) => setDomainName(e.target.value)}
          className="w-full h-8 p-3 rounded focus:outline-none border-light border-[#0F2D66] text-sm"
        />
      </div>
      <div className="flex flex-col items-center mt-1">
        <Link className="text-sm font-bold w-max" to="empty">
          Already have a domain?
        </Link>
      </div>
      <div className="flex flex-row px-3 py-2 justify-end">
        <div>
          <button
            onClick={handleSearch}
            disabled={!canSearch}
            className={`rounded h-[32px] transition duration-300 focus:outline-none text-sm m-auto px-4 pt-1
              ${
                canSearch
                  ? "text-white bg-button hover:bg-[#177fc0]"
                  : "bg-[#9EB4C5]"
              }`}
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchDomainCard;
