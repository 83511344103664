import "./tailwindcss/index.css";
import React from "react";
import { StateProvider } from "./context/GlobalContext";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Homepage from "./pages/Homepage";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import SearchDomain from "./pages/SearchDomain";
import DomainOptions from "./pages/DomainOptions";
import { SitePurpose } from "./pages/SitePurpose";
import { Registration } from "./pages/CompanyRegistration";
import TemplateSelection from "./pages/TemplateSelection";
import PricingDetails from "./pages/PricingDetails";
import PricingPlan from "./pages/PricingPlan";
import VerifyEmail from "./components/Authentication/VerifyEmail";
import StateSelection from "./pages/StateSelection";
import AdditionalInfo from "./pages/AdditionalInfo";
import BusinessDeployment from "./pages/BusinessDeployment";
import PaymentSuccess from "./pages/PaymentSuccess";
import PaymentCancellation from "./pages/PaymentCancellation";
import BasicDeployment from "./pages/BasicDeployment";
import axios from "axios";
import KnowledgeBase from "./pages/KnowledgeBase";
import StateArticle from "./pages/StateArticle";
import ArticleEinCreationProcess from "./pages/ArticleEinCreationProcess";
import Trademark from "./pages/Trademark";
import { TemplateQuestion } from "./pages/TemplateQuestion";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import FAQ from "./pages/FAQ";
import About from "./pages/About";
import Contact from "./pages/Contact";
import UserSites from "./pages/Dashboard/Sites";
import UserDocumentation from "./pages/Dashboard/Documentation";
import { UserLoggedInPrivateRoute } from "./components/private-routes";
import { AudienceSizeQuestion } from "./pages/AudienceSizeQuestion";
import { WebsiteSpecialityQuestion } from "./pages/WebsiteSpecialityQuestion";
import { CustomizeWebsiteQuestion } from "./pages/CustomizeWebsiteQuestion";
import CustomizeDesignQuestionaire from "./pages/StaticSiteCustomization";
import { useCart } from "./store";

function App() {
  const cart = useCart();
  console.log(cart);
  React.useEffect(() => {
    const getCsrfToken = async () => {
      const response = await axios.get("/csrfToken");
      axios.defaults.headers.post["X-CSRF-Token"] = response.data.csrfToken;
    };
    getCsrfToken();
  }, []);
  return (
    <div className="App font-overpass">
      <StateProvider>
        <Router>
          <Switch>
            <Route exact path="/" component={Homepage} />
            <Route path="/login" component={Login} />
            <Route path="/signup" component={Signup} />
            <Route path="/search" component={SearchDomain} />
            <Route path="/domain-options" component={DomainOptions} />
            <Route path="/purpose" component={SitePurpose} />
            <Route path="/formation" component={Registration} />
            <Route path="/templates" component={TemplateSelection} />
            <Route path="/template-selection" component={TemplateQuestion} />
            <Route path="/pricing-details" component={PricingDetails} />
            <Route path="/pricing-plan" component={PricingPlan} />
            <Route path="/verify-email" component={VerifyEmail} />
            <Route path="/state-selection" component={StateSelection} />
            <Route path="/trademark" component={Trademark} />
            <Route path="/audience-size" component={AudienceSizeQuestion} />
            <Route
              path="/website-speciality"
              component={WebsiteSpecialityQuestion}
            />
            <Route path="/template-type" component={CustomizeWebsiteQuestion} />
            <Route
              path="/static-site-customization"
              component={CustomizeDesignQuestionaire}
            />
            <Route path="/additional-info" component={AdditionalInfo} />
            <Route
              exact
              path="/deployment-business"
              component={BusinessDeployment}
            />
            <Route
              exact
              path="/payment-success"
              component={PaymentSuccess}
            />
            <Route
              exact
              path="/payment-cancellation"
              component={PaymentCancellation}
            />
            <Route path="/deployment-basic" component={BasicDeployment} />
            <Route exact path="/knowledgebase" component={KnowledgeBase} />
            <Route
              exact
              path="/knowledgebase/business-entity/EINCreationProcess"
              component={ArticleEinCreationProcess}
            />
            <Route
              path="/knowledgebase/business-entity/:state/:pageId?"
              component={StateArticle}
            />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/faq" component={FAQ} />
            <Route path="/about-us" component={About} />
            <Route path="/contact-us" component={Contact} />

            <UserLoggedInPrivateRoute>
              <Route path="/dashboard">
                <Route path="/dashboard/sites" exact component={UserSites} />
                <Redirect from="/dashboard" to="/dashboard/sites" />

                <Route
                  path="/dashboard/documentation"
                  component={UserDocumentation}
                  exact
                />
              </Route>
            </UserLoggedInPrivateRoute>
          </Switch>
        </Router>
      </StateProvider>
    </div>
  );
}

export default App;
