import HeaderOne from "./HeaderOne";
import HeaderTwo from "./HeaderTwo";
import StandardBlock from "./StandardBlock";
import Paragraph from "./Paragraph";
import BulletList from "./BulletList";
import NumberList from "./NumberList";
import PageLink from "./PageLink";
import Step from "./Step";

const NotionBlockRenderer = ({
  block,
  stepsProgression,
  index,
  level,
  onComplete,
  pathBuilder
}) => {
  if (block.content.type === "heading_1")
    return <HeaderOne block={block.content.heading_1} />;
  if (block.content.type === "heading_2")
    return <HeaderTwo block={block.content.heading_2} />;
  if (
    block.content.type === "numbered_list_item" &&
    !!stepsProgression[block.content.id]
  ) {
    return (
      <Step
        block={block.content.numbered_list_item}
        subBlocks={block.subBlocks}
        stepProgression={stepsProgression[block.content.id]}
        onComplete={() => onComplete(block.content.id, !stepsProgression[block.content.id].completed)}
        pathBuilder={pathBuilder}
      />
    );
  }

  return (
    <StandardBlock subBlocks={block.subBlocks} level={0} pathBuilder={pathBuilder}>
      {block.content.type === "paragraph" && (
        <Paragraph
          block={block.content.paragraph}
          subBlocks={block.subBlocks}
          level={level}
        />
      )}
      {block.content.type === "bulleted_list_item" && (
        <BulletList block={block.content.bulleted_list_item} />
      )}
      {block.content.type === "numbered_list_item" && (
        <NumberList block={block.content.numbered_list_item} index={index} />
      )}
      {block.content.type === "child_page" && (
        <PageLink
          block={block.content}
          pathBuilder={pathBuilder}
        />
      )}
    </StandardBlock>
  );
};

export default NotionBlockRenderer;
