const FeatureCard = ({ index, illustration, heading, content }) => {
  return (
    <div className={classes[index]}>
      <img
        src={illustration}
        alt="card-illustration"
        className="w-[50%] mx-auto"
      />
      <div className="flex flex-col text-center md:text-left">
        <p className="text-2xl mb-2 tracking-[-1px]">{heading}</p>
        <p className="font-extralight">{content}</p>
      </div>
    </div>
  );
};

const Features = () => {
  return (
    <div className="flex flex-col items-center gap-10 md:gap-0 pt-16 md:pt-48 pb-16 md:pb-56 transform translate-x-0 md:-translate-x-28">
      {cardHeading.map((heading, index) => (
        <FeatureCard
          key={heading}
          index={index}
          illustration={illustrations[index]}
          heading={heading}
          content={cardContent[index]}
        />
      ))}
    </div>
  );
};

export default Features;

const cardHeading = ["Save time and money", "Get help fast", "Build on the go"];
const cardContent = [
  "With Blank Cut, you save valuable time and resources. We streamline your online journey, making every step intuitive, inclusive, and efficient.",
  "Need assistance? Our receptive and passionate support team is here to guide you every step of the way, ensuring your business thrives online.",
  "Stay flexible with Blank Cut. Our pioneering platform lets you manage and enhance your business anytime, anywhere, keeping you ahead in the digital landscape.",
];
const illustrations = [
  "feature-card-illustration-1.svg",
  "feature-card-illustration-2.svg",
  "feature-card-illustration-3.svg",
];
const classes = [
  "flex flex-col md:flex-row gap-10 bg-white w-full md:w-1/2 max-w-6xl p-8 md:pt-14 md:pb-14 md:pl-10 md:pr-16 border border-[#DEE4F2] transform translate-x-0 md:translate-x-0 translate-y-0 md:-translate-y-0 text-left shadow-2xl rounded-xl z-10",
  "flex flex-col md:flex-row gap-10 bg-white w-full md:w-1/2 max-w-6xl p-8 md:pt-14 md:pb-14 md:pl-10 md:pr-16 border border-[#DEE4F2] transform translate-x-0 md:translate-x-[40%] translate-y-0 md:translate-y-[-30px] text-left shadow-2xl rounded-xl z-20",
  "flex flex-col md:flex-row gap-10 bg-white w-full md:w-1/2 max-w-6xl p-8 md:pt-14 md:pb-14 md:pl-10 md:pr-16 border border-[#DEE4F2] transform translate-x-0 md:translate-x-0 translate-y-0 md:translate-y-[-60px] text-left shadow-2xl rounded-xl z-30",
];
