import NotionBlockRenderer from "./NotionBlockRenderer";
import SiteSettings from "./SiteSettings";

const chooseANameStepTitle = "Choose a Name";

const Step = ({
  block,
  subBlocks,
  stepProgression,
  onComplete,
  pathBuilder,
}) => {
  const text = block.rich_text.at(0).text.content.trim();
  const isChooseANameStep = text.trim() === chooseANameStepTitle;
  
  return (
    <>
      <div className="flex flex-row justify-between p-4 border-b border-gray bg-light mt-2">
        <div className="flex flex-row gap-3 text-[20px] leading-6 width-fit-content">
          <span>{stepProgression.index}.</span>
          <span>{text}</span>
        </div>
        <div onClick={onComplete} className="w-4">
          <i
            className={`fa ${
              stepProgression.completed ? "fa-check-square-o" : "fa-square-o"
            } text-base`}
          ></i>
        </div>
      </div>
      {!stepProgression.completed && subBlocks?.length > 0 && (
        <>
          {isChooseANameStep && 
            <div className="mt-2 mb-8 ml-4 flex flex-row gap-2">
              <div className="flex-[100%]">
              {subBlocks.map((sb) => (
                <NotionBlockRenderer
                  key={sb.content.id}
                  stepsProgression={{}}
                  block={sb}
                  level={1}
                  pathBuilder={pathBuilder}
                />
              ))}
              </div>
              <div className="flex-[100%]">
                <SiteSettings></SiteSettings>
              </div>
            </div>}
          {!isChooseANameStep && (
            <div className="mt-2 mb-8 ml-4">
              {subBlocks.map((sb) => (
                <NotionBlockRenderer
                  key={sb.content.id}
                  stepsProgression={{}}
                  block={sb}
                  level={1}
                  pathBuilder={pathBuilder}
                />
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Step;
