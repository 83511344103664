import React, { useState } from "react";
import SitesBlock from "../../../components/Dashboard/Sites/SitesBlock/SitesBlock";
import TabsBlock from "../../../components/Dashboard/Sites/TabsBlock/TabsBlock";
import DashboardLayout from "../../../components/Dashboard/DashboardLayout";
import SiteSettingModal from "../../../components/Dashboard/Sites/SiteSettingModal/SiteSettingModal";

const sites = [
  {
    id: 1,
    name: "www.edersdevdomain.com",
    status: "Published",
  },
  {
    id: 2,
    name: "www.autoocala.com",
    status: "Published",
  },
  {
    id: 3,
    name: "www.thewhitecloth.com",
    status: "Unpublished",
  },
];

const UserSites = () => {
  const [siteSettingModalActive, setSiteSettingModalActive] = useState(false);
  const [activeSite, setActiveSite] = useState(null);

  console.log({ activeSite });

  return (
    <>
      <DashboardLayout>
        <SitesBlock sites={sites} />
        <TabsBlock
          openSiteSettingModal={() => setSiteSettingModalActive(true)}
          setActiveSite={setActiveSite}
          sites={sites}
        />

        <SiteSettingModal
          isOpen={siteSettingModalActive}
          close={() => setSiteSettingModalActive(false)}
          activeSite={activeSite}
        />
      </DashboardLayout>
    </>
  );
};

export default UserSites;
