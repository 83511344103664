import React from "react";

export const Context = React.createContext();
export const StateProvider = ({ children }) => {
  const [domainOnly, setDomainOnly] = React.useState(false);
  const [loginStatus, setLoginStatus] = React.useState("Log In");
  const [articleSteps, setArticleSteps] = React.useState({});
  const [userInformationShown, setUserInformationShown] = React.useState(false);
  const [entityFormationStates, setEntityFormationStates] = React.useState([]);
  return (
    <Context.Provider
      value={{
        domainOnly,
        setDomainOnly,
        loginStatus,
        setLoginStatus,
        articleSteps,
        setArticleSteps,
        userInformationShown,
        setUserInformationShown,
        entityFormationStates,
        setEntityFormationStates,
      }}
    >
      {children}
    </Context.Provider>
  );
};
