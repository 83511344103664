const KnowledgeBaseSearch = ({topFixed}) => {
  const searchQuery = "";

  const handleChange = (event) => {
    console.log("handleChange", event);
  };
  const handleClick = () => {
    console.log("handleClick");
  };
  const handleFocus = () => {
    console.log("handleFocus");
  };

  const callDisplayLoader = false;
  const displayLoaderType = () => <div>Loading</div>;

  return (
    <form
      onSubmit={(event) => event.preventDefault()}
      className={`w-[100%] relative flex flex-row ${topFixed ? "my-2" : "my-5"} md:my-10 `}
    >
      <input
        placeholder="Search"
        type="text"
        defaultValue={searchQuery}
        onChange={(event) => handleChange(event)}
        onKeyPress={(event) => event.key === "Enter" && handleClick()} //
        onFocus={() => handleFocus()}
        className="h-[60px] md:h-[68px] w-full max-w-[80%] md:max-w-none mx-auto px-6 md:px-10 rounded-full shadow-md box-border font-light focus:outline-none"
        id="searchInput"
        disabled
      />
      <button
        type="button"
        onClick={() => handleClick()}
        onKeyPress={(event) => event.key === "Enter" && handleClick()} //
        className="bg-button absolute right-10 md:right-0 w-[90px] md:w-[140px] h-full flex items-center justify-center rounded-full border-4 border-white transform duration-300 hover:bg-[#177fc0] focus:outline-none"
        disabled
      >
        {callDisplayLoader ? (
          displayLoaderType()
        ) : (
          <img src="/search-icon.svg" alt="search-icon" width="auto" />
        )}
      </button>
    </form>);
}

export default KnowledgeBaseSearch;