import NotionBlockRenderer from "./NotionBlockRenderer";

const NotionBlocks = ({ blocks, stepsProgression, onComplete, pathBuilder }) => {
  let numberedListIndex = 0;
  return (
    <>
      {blocks.map((block) => {
        const numberListEntered =
          !numberedListIndex && block.content.type === "numbered_list_item";
        const numberListExited =
          numberedListIndex && block.content.type !== "numbered_list_item";
        if (numberedListIndex > 0) numberedListIndex = numberedListIndex + 1;
        if (numberListEntered) numberedListIndex = 1;
        if (numberListExited) numberedListIndex = 0;
        return (
          <NotionBlockRenderer
            key={block.content.id}
            block={block}
            stepsProgression={stepsProgression}
            index={numberedListIndex}
            onComplete={onComplete}
            pathBuilder={pathBuilder}
          />
        );
      })}
    </>
  );
};

export default NotionBlocks;
