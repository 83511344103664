import React from "react";
import Footer from "../components/Reusable/Footer";
import Navbar from "../components/Reusable/Navbar";

const FAQData = [
  {
    title: "What is Blank Cut?",
    excerpt: "Try these tips to help your guests feel comfortable and secure.",
    readTime: "3 min read",
    image: "/images/faq/faq-1.png",
  },
  {
    title: "What access rights do users need to connect a GA source?",
    excerpt: "Try these tips to help your guests feel comfortable and secure.",
    readTime: "3 min read",
    image: "/images/faq/faq-2.png",
  },
  {
    title: "Can I invite other team members to collaborate?",
    excerpt: "Try these tips to help your guests feel comfortable and secure.",
    readTime: "3 min read",
    image: "/images/faq/faq-3.png",
  },
  {
    title: "What access rights do users need to connect a GA source?",
    excerpt: "Try these tips to help your guests feel comfortable and secure.",
    readTime: "3 min read",
    image: "/images/faq/faq-4.png",
  },
  {
    title: "Do you sell my data?",
    excerpt: "Try these tips to help your guests feel comfortable and secure.",
    readTime: "3 min read",
    image: "/images/faq/faq-5.png",
  },
  {
    title: "Do you support GA4?",
    excerpt: "Try these tips to help your guests feel comfortable and secure.",
    readTime: "3 min read",
    image: "/images/faq/faq-6.png",
  },
];

const FAQ = () => {
  return (
    <>
      <Navbar />

      <main className="bg-light">
        <div className="max-w-[1440px] mx-auto">
          <div className="px-6 pt-10 lg:pt-20 pb-10">
            <div className="text-center mb-7 lg:mb-10">
              <h1 className="font-black text-2xl text-[#121212] lg:text-[40px]">
                Frequently Asked Questions
              </h1>
              <p className="text-sm mt-4 lg:text-xl">View all you need here</p>
            </div>
            <div className="flex flex-wrap sm:grid grid-cols-1 justify-center sm:grid-cols-2 lg:grid-cols-3 gap-6 place-items-center">
              {FAQData.map((item, i) => (
                <div className="cursor-pointer bg-white shadow-sm p-6 rounded-xl w-full max-w-[330px] 2xl:max-w-[414px] group">
                  <img src={item.image} alt={item.title} />
                  <div className="mt-4 space-y-2">
                    <h6 className="text-lg text-[#222] lg:text-xl font-bold group-hover:text-[#0197F5] transition-colors">
                      What is Blank Cut?
                    </h6>
                    <p className="text-[#717171] text-sm lg:text-lg">
                      Try these tips to help your guests feel comfortable and
                      secure.
                    </p>
                    <span className="inline-flex text-[#717171] text-sm lg:text-lg pt-4">
                      3 min read
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default FAQ;
