import { useContext } from "react";
import { Context } from "../../context/GlobalContext";

const KnowledgeTopic = () => {
  const context = useContext(Context);
  return (
    <div
      className="bg-white flex flex-col border-light rounded px-6 md:px-6 py-5 md:px-5 gap-6 items-center cursor-pointer"
      onClick={() => context.setUserInformationShown(true)}
    >
      <img
        src="/topic_BusinessEntity.svg"
        alt="business entity"
        width={147}
        height={147}
      />
      <div className="flex flex-col gap-2 items-center text-center ">
        <h4 className="text-xl font-light text-dark">Business Entity</h4>
        <span className="font-extralight text-base text-[#42526D]">
          How It Works, Getting Started, Fees & Protection
        </span>
      </div>
    </div>
  );
};

export default KnowledgeTopic;
