import React from "react";
import AuthLayout from "./AuthLayout";
import { useHistory } from "react-router";


const VerifyEmail = () => {
  const history = useHistory();

  return (
    <AuthLayout centerText>
      <div className="relative h-full w-full max-w-[90%] md:max-w-xl mt-8 md:-mt-16 flex flex-col text-center items-center justify-start md:justify-center">
        <h2 className="text-3xl md:text-4xl mb-2">
          Verification has been completed!
        </h2>
        <p className="text-[#164360]">
          Thank you for confirming your email.
        </p>
        <img
          src="/success-check.svg"
          alt="success-check"
          width="114px"
          className="mb-8 md:mb-14 mt-10 md:mt-16"
        />
        <button
          type="button"
          onClick={() => history.push("/login")}
          className="bg-[#0197F5] w-48 h-11 text-white font-bold rounded hover:bg-[#177fc0] transition duration-300 focus:outline-none"
        >
          Sign in
        </button>
      </div>
    </AuthLayout>
  );
};

export default VerifyEmail;
