import Layout from "../Layout";
import { useHistory } from "react-router";

const PaymentCancellation = () => {
  const history = useHistory();

  return (
    <Layout bgcolor="light">
      <div className="min-h-screen pb-10 flex flex-col m-auto max-w-7xl items-center justify-center">
        <button
          onClick={() => history.push("/")}
          className="rounded-full bg-red-500 px-3 py-1 text-white hover:bg-red-600 focus:outline-none absolute top-4 left-4"
        >
          x
        </button>
        <img
          src="/payment-cancellation.svg"
          alt="payment-cancellation"
          width="280px"
        />
        <h1 className="font-bold text-3xl text-center mt-12 mb-2.5 tracking-tight">
        You're payment has been cancelled. We're sorry we could not process your payment at this time.
        </h1>
        <h2 className="text-[#0197F5] text-lg text-center tracking-tight">
          If you have any further questions, please contact us. 
        </h2>
        <button
          onClick={() => history.push("/contact-us")}
          className="rounded-full bg-light px-9 mt-6 h-[50px] text-sm duration-300 border border-[#0197F5] text-[#0197F5] hover:bg-[#0197F5] hover:text-white hover:border-white focus:outline-none"
        >
          Contact Us
        </button>
      </div>
    </Layout>
  );
};

export default PaymentCancellation;