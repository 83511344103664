import Layout from "../Layout";
import { useHistory } from "react-router";

const BusinessDeployment = () => {
  const history = useHistory();

  return (
    <Layout bgcolor="light">
      <div className="min-h-screen pb-10 flex flex-col m-auto max-w-7xl items-center justify-center">
        <img
          src="/deployment-illustration.svg"
          alt="deployment-illustration"
          width="280px"
        />
        <h1 className="font-bold text-3xl text-center mt-12 mb-2.5 tracking-tight">
          Setting up your business will take 30 business days.
        </h1>
        <h2 className="text-button text-lg text-center tracking-tight">
          We will notify you once everything is set up
        </h2>
        <button
          onClick={() => history.push("/")}
          className="rounded-full bg-light px-9 mt-6 h-[50px] text-sm duration-300 border border-button text-button hover:bg-button hover:text-white hover:border-white focus:outline-none"
        >
          Go to Dashboard
        </button>
      </div>
    </Layout>
  );
};

export default BusinessDeployment;
