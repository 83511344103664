import React from "react";
import { useForm, Controller } from "react-hook-form";
import Input from "../ui/Input/Input";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { sendContactForm } from "../../services/contact";
import PhoneInput from "../Reusable/PhoneInput";

const schema = Yup.object().shape({
  fName: Yup.string().required("First name is required"),
  lName: Yup.string().required("Last name is required"),
  email: Yup.string().email().required("Email is required"),
  phone: Yup.string().required("Phone is required"),
  message: Yup.string().required("Message is required"),
});

const ContactForm = ({ isDreamTeam }) => {
  const {
    handleSubmit,
    formState: { isLoading, errors },
    control,
    reset,
  } = useForm({
    defaultValues: {
      fName: "",
      lName: "",
      email: "",
      phone: "",
      message: "",
    },
    mode: "all",
    resolver: yupResolver(schema),
  });
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(null);

  const onSubmit = handleSubmit(async (values) => {
    setError(null);
    setSuccess(false);
    const emailSubject = isDreamTeam ? "Dream Team Inquiry" : "General Inquiry";

    try {
      await sendContactForm({
        subject: emailSubject,
        name: `${values.fName} ${values.lName}`,
        phone: `${values.phone}`,
        email: values.email,
        message: values.message,
      });
      reset();
      setSuccess(true);
    } catch (err) {
      console.log(err);
      setError("Something went wrong. Please try again.");
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="flex flex-col mt-12 gap-6 md:gap-5 sm:justify-items-center w-full lg:px-0 max-w-[512px]">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 sm:space-y-0">
          {/* Use the Controller component for each input */}
          <Controller
            name="fName"
            control={control}
            render={({ field }) => (
              <Input
                label="First Name"
                error={errors?.fName?.message}
                placeholder="First name"
                {...field}
              />
            )}
          />

          <Controller
            name="lName"
            control={control}
            render={({ field }) => (
              <Input
                label="Last Name"
                error={errors?.lName?.message}
                placeholder="Last name"
                {...field}
              />
            )}
          />
        </div>

        <div>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                label="Email Address"
                error={errors?.email?.message}
                placeholder="you@company.com"
                type="email"
                {...field}
              />
            )}
          />
        </div>

        <Controller
          name="phone"
          control={control}
          render={({ field }) => (
            <PhoneInput
              error={errors?.phone?.message}
              label="Phone Number"
              {...field}
            />
          )}
        />

        <div>
          <label
            className="pb-1.5 text-md font-medium text-[#344054]"
            htmlFor=""
          >
            {isDreamTeam
              ? "Tell Us About Your Website and A Member of Our Team Will Be In Touch"
              : "Message"}
          </label>
          <Controller
            name="message"
            control={control}
            render={({ field }) => (
              <textarea
                rows="4"
                cols="50"
                className="w-full xs:w-[512px] border border-[#D0D5DD] py-[12px] px-3.5 rounded-[8px] text-[#667085] shadow-[0px,1px,2px,0px_rgba(16,24,40,0.05)] focus:outline-0"
                placeholder="Leave us a message..."
                {...field}
              />
            )}
          />
          <p className="text-md text-red-500 pt-1">
            {errors?.message?.message}
          </p>
        </div>

        <div className="w-full mt-2">
          <button
            className="border-button bg-button py-3 px-5 rounded-lg w-full xs:w-[512px] text-white font-medium disabled:opacity-50 disabled:cursor-not-allowed"
            type="submit"
            disabled={isLoading}
          >
            Contact
          </button>
        </div>

        {success && (
          <p className="text-md text-green-500 pt-1">
            Thank you for contacting us. We will be in touch shortly.
          </p>
        )}

        {error && <p className="text-md text-red-500 pt-1">{error}</p>}
      </div>
    </form>
  );
};

export default ContactForm;
