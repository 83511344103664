import React, { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import { RiGlobalFill } from "react-icons/ri";
import cn from "classnames";
import { Menu } from "@headlessui/react";
import { BiChevronDown } from "react-icons/bi";

const navigation = [
  {
    label: "Sites",
    href: "/dashboard/sites",
    icon: <RiGlobalFill size={20} />,
  },
  {
    label: "Documentation",
    href: "/dashboard/documentation",
  },
];

const Topbar = () => {
  const location = useLocation();

  return (
    <nav className="bg-white px-3 lg:px-10 pt-3">
      <div className="flex justify-center flex-col gap-5 pb-3">
        <div className="flex gap-6 items-center justify-between w-full flex-wrap">
          <div className="flex items-center gap-5 lg:gap-10 flex-wrap sm:flex-nowrap">
            <Link to="/">
              <img src="/logo.svg" alt="blank-cut" />
            </Link>
            <div className="flex items-center gap-5 lg:gap-10">
              {navigation.map((item, i) => (
                <Link to={item.href} key={i}>
                  <div
                    className={cn(
                      "space-x-1 cursor-pointer text-base min-w-[120px] w-full text-center flex items-center justify-center p-2 text-[#00204F]",
                      {
                        "border-b-[2px] border-b-button !text-button":
                          location.pathname === item.href,
                      }
                    )}
                  >
                    {item.icon}
                    <p className="font-extrabold text-sm lg:text-base">
                      {item.label}
                    </p>
                  </div>
                </Link>
              ))}
            </div>
          </div>

          <Menu as="div" className="relative flex">
            <Menu.Button>
              <div className="flex gap-2 items-center cursor-pointer">
                <img className="h-10" src="/icons/profile.svg" alt="" />
                <BiChevronDown size={20} />
              </div>
            </Menu.Button>
            <Menu.Items className="absolute right-0 top-9 mt-2 w-52 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <Menu.Item as={Fragment}>
                <button className="flex space-x-3 items-center p-[15px] w-full bg-white">
                  <p className="text-dark">Setting</p>
                </button>
              </Menu.Item>
              <Menu.Item as={Fragment}>
                <button className="flex space-x-3 items-center p-[15px] w-full bg-white border-t border-t-[#EEE]">
                  <p className="text-[#F50101]">Logout</p>
                </button>
              </Menu.Item>
            </Menu.Items>
          </Menu>
        </div>
      </div>
    </nav>
  );
};

export default Topbar;
