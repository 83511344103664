import React from "react";
import { useLocation, Link } from "react-router-dom";
import Layout from "../Layout";
import Navbar from "../components/Reusable/Navbar";
import TemplateCard from "../components/TemplateSelection/TemplateCard";
import { templateData } from "../components/TemplateSelection/TemplateData";

const TemplateSelection = () => {
  const location = useLocation();

  const templates = templateData;

  const categories = [
    "all",
    ...new Set(templates?.map((template) => template.category)),
  ];
  const providers = [
    ...new Set(templates?.map((template) => template.provider)),
  ];

  const [filters, setFilters] = React.useState({
    category: categories[0],
    provider: providers[0],
  });

  const searchParam = location?.search
    ?.split("=")[1]
    ?.replace(/%20/g, " ")
    .replace(/%20/g, " ");

  const variant = searchParam || filters.provider;

  return (
    <div className="flex flex-col">
      <Navbar />
      <Layout bgcolor="light">
        <div className="flex flex-col m-auto w-full max-w-7xl mb-24 min-h-[81vh]">
          <div className="text-center my-12 md:my-20">
            <h1 className="text-3xl md:text-4xl">
              Discover Your Perfect Template
            </h1>
            <Link to="/contact-us?variant=dream-team" className="text-gray-500">
              Want a custom solution?
            </Link>
          </div>
          <div className="flex flex-row flex-wrap gap-2 md:gap-3 mb-5">
            {categories.map((tag, index) => (
              <button
                key={tag}
                type="button"
                onClick={() => setFilters({ ...filters, category: tag })}
                className={filters.category === tag ? classes[1] : classes[0]}
              >
                {tag}
              </button>
            ))}
          </div>
          <div className="flex flex-row flex-wrap gap-2 md:gap-3 mb-10">
            {providers
              ?.filter((tag) => (searchParam ? tag === variant : true))
              .map((tag, index) => (
                <button
                  key={tag}
                  type="button"
                  onClick={() => setFilters({ ...filters, provider: tag })}
                  className={
                    variant === tag
                      ? `${classes[1]} !bg-dark border-dark hover:!bg-dark/80`
                      : `${classes[0]} bg-white border-dark text-dark hover:bg-dark hover:text-white`
                  }
                >
                  {tag}
                </button>
              ))}
          </div>

          <div className="flex flex-row flex-wrap gap-10">
            {templates
              .filter(
                (template) =>
                  (template.category === filters.category ||
                    filters.category === "all") &&
                  template.provider === variant
              )
              .map((template, index) => (
                <TemplateCard
                  index={index}
                  key={template.name}
                  imagesrc={template.imagesrc}
                  name={template.name}
                  provider={template.provider}
                  category={template.category}
                />
              ))}
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default TemplateSelection;

const classes = [
  "h-[28px] md:h-[36px] px-4 md:px-6 pt-px border border-button text-sm rounded-full font-bold capitalize transform duration-300 bg-transparent text-button focus:outline-none hover:bg-button hover:text-white",
  "h-[28px] md:h-[36px] px-4 md:px-6 pt-px border border-button text-sm rounded-full font-bold capitalize transform duration-300 bg-button text-white focus:outline-none hover:bg-[#177fc0] hover:text-white",
];
