const NumberList = ({block, index}) => {
  return (
    <div className="text-base flex w-100 mt-2">
      <div className="flex px-2 flex-col items-end" style={{width: "28px"}}><span>{index}.</span></div>
      <div className="">
        {block.rich_text[0].text.content}
      </div>
    </div>
  );
}

export default NumberList;