import React, { useEffect } from "react";
import Tooltip from "./Tooltip";
import RadioButton from "../Reusable/RadioButton";
import { useCart } from "../../store";
import { pricingData } from "../../utils/data/pricing";

const PricingTable = ({ setSelectedPlan }) => {
  const [planType, setPlanType] = React.useState("annually");
  const [highlighted, setHighlighted] = React.useState(2);
  const mostPopular = 2;
  const { type, templateType } = useCart();
  const pricingPlan =
    pricingData[(type !== "Domain" && type) || "Personal"][
      templateType ? templateType : "DEFAULT"
    ];

  useEffect(() => {
    setSelectedPlan({
      price: pricingPlan?.headerRow[highlighted - 1].price,
      features: pricingPlan?.headerRow[highlighted - 1].features,
      type: planType,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <table className="table-auto min-w-[800px] w-full mt-20 mb-2">
      <thead>
        <tr>
          <th className="text-left transform -translate-y-6">
            <p
              className={`flex flex-row items-center justify-start gap-2 mb-2 cursor-pointer leading-[28px] ${
                planType === 0 ? "text-black" : "text-black text-opacity-[0.44]"
              }`}
              onClick={() => {
                setPlanType("annually");
                setSelectedPlan((selectedPlan) => ({
                  ...selectedPlan,
                  type: "annually",
                }));
              }}
            >
              <RadioButton active={planType === "annually"} />
              Pay Annually
            </p>
            <p
              className={`flex flex-row items-center justify-start gap-2 mt-2 cursor-pointer leading-[28px] ${
                planType === 1 ? "text-black" : "text-black text-opacity-[0.44]"
              }`}
              onClick={() => {
                setPlanType("monthly");
                setSelectedPlan((selectedPlan) => ({
                  ...selectedPlan,
                  type: "monthly",
                }));
              }}
            >
              <RadioButton active={planType === "monthly"} />
              Pay Monthly
            </p>
          </th>
          {pricingPlan?.headerRow.map((cell, index) => (
            <th
              onClick={() => {
                setHighlighted(index + 1);
                setSelectedPlan({
                  type: planType,
                  features: cell?.features,
                  price: cell?.price,
                  name: `${cell?.category} Plan`,
                });
              }}
              className={`pt-10 pb-16 font-normal cursor-pointer z-0 ${
                index === highlighted - 1
                  ? "bg-[#FCFCFC] shadow-lg"
                  : "bg-transparent"
              }`}
            >
              <div className="relative">
                {highlighted === mostPopular && highlighted - 1 === index ? (
                  <div className="absolute w-full h-10 transform -translate-y-20 left-0">
                    <button
                      type="button"
                      className="absolute bg-button w-full h-full flex flex-row items-center justify-center font-light text-sm text-white uppercase focus:outline-none"
                    >
                      Most Popular
                    </button>
                  </div>
                ) : null}
              </div>
              <p className="capitalize text-[22px] mb-1">{cell.category}</p>
              <div className="relative w-max mx-auto h-16 mt-3">
                <p className="absolute top-6 -left-4 text-base">$</p>
                <p className="text-[54px]">
                  {planType === "annually"
                    ? (Number(cell.price) * 0.8).toFixed()
                    : cell.price}
                </p>
              </div>
              <p className="text-black text-opacity-[0.53] text-base capitalize">
                {cell.plan}
              </p>
              <p
                className={
                  planType === "annually"
                    ? "text-button text-base"
                    : "text-[#F9F9F9] text-base"
                }
              >
                {cell.saving}
              </p>
            </th>
          ))}
        </tr>

        {pricingPlan?.rowData.map((row, rowindex) => (
          <tr key={`${row}`}>
            {row.map((cell, index) =>
              cell !== "true" && cell !== "false" ? (
                <td
                  key={`${rowindex}${index}${cell}`}
                  className="relative flex flex-row items-center justify-items-start gap-3.5 border-b border-[#707070]/[0.23]"
                >
                  {rowindex === 0 ? (
                    <p className="absolute -top-5 font-light uppercase tracking-[3.5px] text-black text-opacity-[0.35]">
                      Core
                    </p>
                  ) : null}
                  <p className="text-black text-opacity-[0.64] py-7">{cell}</p>
                  <Tooltip />
                </td>
              ) : (
                <td
                  onClick={() => {
                    setHighlighted(index);

                    setSelectedPlan({
                      type: planType,
                      features: pricingPlan?.headerRow[index]?.features,
                      price: pricingPlan?.headerRow[index]?.price,
                      name: `${pricingPlan?.headerRow[index]?.category} Plan`,
                    });
                  }}
                  key={`${rowindex}${index}${cell}`}
                  className={`border-b cursor-pointer ${
                    rowindex < row.length
                      ? "border-[#707070]/[0.23]"
                      : "border-transparent"
                  } ${
                    index === highlighted
                      ? "bg-[#FCFCFC] shadow-lg"
                      : "bg-transparent"
                  }`}
                >
                  <div className="flex">
                    <img
                      src={cell === "true" ? "/check.svg" : "/cross.svg"}
                      alt="availability-status"
                      className="m-auto inline-table"
                    />
                  </div>
                </td>
              )
            )}
          </tr>
        ))}
      </thead>
    </table>
  );
};

export default PricingTable;
