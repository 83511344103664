import React from "react";
import Navbar from "../components/Reusable/Navbar";
import Footer from "../components/Reusable/Footer";
import { Disclosure } from "@headlessui/react";
import { FaMinus, FaPlus } from "react-icons/fa";

const FAQData = [
  {
    text: "What Is Personal Data at BlankCut?",
    description:
      "Personal Data at BlankCut refers to any information that can be used to identify an individual. This includes, but is not limited to, names, addresses, email addresses, and payment details.",
  },
  {
    text: "Your Privacy Rights at BlankCut",
    description:
      "At BlankCut, we respect your privacy rights. You have the right to access, correct, and delete your personal data. Additionally, you can object to or limit the use of your data by us.",
  },
  {
    text: "Personal Data BlankCut Collects from You",
    description:
      "We collect data that you provide directly to us, such as when you create an account, make a purchase, or sign up for our newsletter. This can include your name, email, address, and other relevant details.",
  },
  {
    text: "Personal Data BlankCut Receives from Other Sources",
    description:
      "We may also receive data about you from third parties, like partners or affiliates, and combine it with the data we have about you.",
  },
  {
    text: "BlankCut’s Use of Personal Data",
    description:
      "We use your data to provide, improve, and personalize our services. This includes processing transactions, sending marketing communications, and enhancing user experience.",
  },
  {
    text: "BlankCut’s Sharing of Personal Data",
    description:
      "We do not sell your personal data. We only share it with trusted third parties necessary for our business operations, and always with strict data protection agreements in place.",
  },
  {
    text: "Protection of Personal Data at BlankCut",
    description:
      "Protecting your data is our top priority. We employ advanced security measures, including encryption and regular audits, to ensure your data remains safe and confidential.",
  },
  {
    text: "Children and Personal Data",
    description:
      "BlankCut does not knowingly collect or solicit personal data from children under 13. If you believe we might have information from or about a child under 13, please contact us immediately.",
  },
  {
    text: "Cookies and Other Technologies",
    description:
      "We use cookies and similar technologies to enhance your browsing experience, gather analytics, and understand user behavior. You can manage or opt-out of cookies in your browser settings.",
  },
  {
    text: "Transfer of Personal Data Between Countries",
    description:
      "While BlankCut operates globally, we ensure that data transfers across borders are compliant with international data protection regulations, ensuring your data's safety.",
  },
  {
    text: "Our Companywide Commitment to Your Privacy",
    description:
      "Every member of the BlankCut team is committed to upholding the highest standards of data privacy. We continuously train our staff and update our policies to reflect best practices.",
  },
  {
    text: "Privacy Questions",
    description:
      "If you have any questions or concerns about our privacy practices, please reach out to our dedicated customer support team. We're here to help and clarify any doubts you might have.",
  },
];

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />

      <div className="px-5 lg:px-3 text-[#373737] py-10 lg:py-20 bg-light">
        <div className="max-w-[950px] mx-auto">
          <div className="text-sm lg:text-[15px]">
            <div className="text-center mb-8">
              <h1 className="font-black text-2xl lg:text-[40px] text-[#121212]">
                Privacy Policy
              </h1>
            </div>
            <div className="space-y-[31px]">
              <p className="text-xs md:text-[15px]">
                Effective date: 09/30/2022
              </p>
              <h4 className="heading">Introduction</h4>
              <div>
                <p className="mb-2">Welcome to</p>
                <p>Blankcut.com.</p>
                <p>
                  Blankcut.com (“us”, “we”, or “our”) operates
                  https://blankcut.com (hereinafter referred to as “Service”).
                </p>
              </div>
              <p>
                Our Privacy Policy governs your visit to https://blankcut.com,
                and explains how we collect, safeguard and disclose information
                that results from your use of our Service.
              </p>
              <p>
                We use your data to provide and improve Service. By using
                Service, you agree to the collection and use of information in
                accordance with this policy. Unless otherwise defined in this
                Privacy Policy, the terms used in this Privacy Policy have the
                same meanings as in our Terms and Conditions.
              </p>
              <p>
                Our Terms and Conditions (“Terms”) govern all use of our Service
                and together with the Privacy Policy constitutes your agreement
                with us (“agreement”).
              </p>
            </div>
            <div className="space-y-[31px] mt-6">
              <h4 className="text-[#111827] font-bold text-2xl lg:text-[27px]">
                Definitions
              </h4>
              <div>
                <p>
                  SERVICE means the https://blankcut.com website operated by
                </p>
                <p>Blankcut.com.</p>
              </div>
              <p>
                PERSONAL DATA means data about a living individual who can be
                identified from those data (or from those and other information
                either in our possession or likely to come into our possession).
              </p>
              <p>
                USAGE DATA is data collected automatiblankcutly either generated
                by the use of Service or from Service infrastructure itself (for
                example, the duration of a page visit).
              </p>
              <p>
                COOKIES are small files stored on your device (computer or
                mobile device).
              </p>
              <p>
                DATA CONTROLLER means a natural or legal person who (either
                alone or jointly or in common with other persons) determines the
                purposes for which and the manner in which any personal data
                are, or are to be, processed. For the purpose of this Privacy
                Policy, we are a Data Controller of your data.
              </p>
              <p>
                DATA PROCESSORS (OR SERVICE PROVIDERS) means any natural or
                legal person who processes the data on behalf of the Data
                Controller. We may use the services of various Service Providers
                in order to process your data more effectively.
              </p>
              <p>
                DATA SUBJECT is any living individual who is the subject of
                Personal Data.
              </p>
              <p>
                THE USER is the individual using our Service. The User
                corresponds to the Data Subject, who is the subject of Personal
                Data.
              </p>
            </div>
            <div className="mt-8">
              {FAQData.map((item, i) => (
                <Disclosure as="div" className="text-[#373737]">
                  <Disclosure.Button
                    as="div"
                    className="flex items-center justify-between border-t py-5 lg:py-5 border-[#D2D2D7] cursor-pointer"
                  >
                    {({ open }) => (
                      <>
                        <p className="text-base font-bold lg:text-xl xl:text-xl">
                          {item.text}
                        </p>
                        {open ? (
                          <FaMinus color="#86868B" size={18} />
                        ) : (
                          <FaPlus color="#86868B" size={18} />
                        )}
                      </>
                    )}
                  </Disclosure.Button>
                  <Disclosure.Panel as="div" className="mb-5">
                    <p>{item.description}</p>
                  </Disclosure.Panel>
                </Disclosure>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
