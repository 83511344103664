/*
show: boolean
*/
const Modal = ({ show, children }) => {
  return (
    <div
      className={`fixed h-full w-full bg-opacity-75 bg-black flex-row justify-center z-[1001] ${
        show ? "flex" : "hidden"
      }`}
    >
      {show && (
        <div className="flex flex-row justify-center items-center">
          <div className="bg-white  w-[400px] rounded">{children}</div>
        </div>
      )}
    </div>
  );
};

export default Modal;
