import React, { useState } from "react";
import { TableOfContentElement, getPageIdsToCurrent } from "./TableOfContent";

const MobileTableOfContent = ({ tocMenuOpened, toc, currentPageId, pathBuilder, onNavigate }) => {
  const [expandedItems, setExpandedItems] = useState(getPageIdsToCurrent(toc, currentPageId));
  const onToggle = (tocId) => {
    const found = expandedItems.findIndex(i => i === tocId);
    if (found >= 0) {
      const newState = [...expandedItems];
      newState.splice(found, 1);
      setExpandedItems(newState);
    } else {
      setExpandedItems(expandedItems.concat([tocId]));
    }
  };
  
  const height = tocMenuOpened ? { maxHeight: "700px"} : {
    maxHeight: "0"
  };
  // ${tocMenuOpened ? "": "hidden"}
  return (
    <div
      className={`xl:hidden w-screen sm:width-640-fixed lg:width-700-fixed bg-white mb-2
        transform ease-in duration-500 overflow-hidden
        `} 
        style={{ ...height }}
    >
      <div className="flex flex-col px-4">
        {toc.map((entry) => (
        <TableOfContentElement
          key={entry.id}
          toc={entry}
          level={0}
          currentPageId={currentPageId}
          expandedItems={expandedItems}
          onToggle={onToggle}
          pathBuilder={pathBuilder}
          onNavigate={onNavigate}
        />))}
      </div>
    </div>
  );
};

export default MobileTableOfContent;
