import React from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { useCart } from "../../store";

const SearchDomain = () => {
  const history = useHistory();
  const [query, setQuery] = React.useState("");
  const handleChange = (event) => {
    event.preventDefault();
    let changeEventQuery = event.target.value;
    changeEventQuery = changeEventQuery.replace("www.", "");
    setQuery(changeEventQuery);
  };
  const { setDomain } = useCart();
  const handleSearch = () => {
    setDomain({
      searchQuery: query,
      outsideSearch: true,
    });
    history.push("/search");
  };

  return (
    <div className="relative overflow-hidden m-auto max-w-5xl flex flex-col py-14 md:py-28 md:px-28 gap-7 items-center">
      <h1 className="font-semibold text-white text-4xl md:text-5xl">
        Start your domain name search.
      </h1>
      <form className="relative flex flex-row w-full mb-4 md:mb-0 mt-10 md:mt-2">
        <input
          placeholder="Search"
          type="text"
          defaultValue={query}
          onChange={(event) => handleChange(event)}
          className="h-[60px] rounded-full w-full md:w-5/6 mx-auto box-border px-7 md:px-9 focus:outline-none"
        />
        <button
          onClick={() => handleSearch()}
          onKeyPress={(event) => event.key === "Enter" && handleSearch()}
          className="absolute w-20 md:w-24 h-[60px] flex flex-row items-center justify-center right-0 md:right-16 bg-button border-4 border-white rounded-full transfrom duration-300 hover:bg-[#177fc0] focus:outline-none"
        >
          <img src="/search-icon.svg" alt="search-icon" width="auto" />
        </button>
      </form>
      <div className="flex flex-col md:flex-row gap-2 md:gap-10">
        <Link className="text-white mx-auto w-max font-bold">
        Already have a domain?
        </Link>
      </div>
      <img
        className="absolute -right-6 md:right-12 top-auto mt-5"
        src="/right-drawn-arrow.svg"
        alt="arrow-illustration"
        width="115px"
      />
    </div>
  );
};

export default SearchDomain;
