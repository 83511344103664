import { useCart } from "../store";

export const getPath = (currentPath) => {
  const {
    type,
    businessRegistration,
    trademarkRegistration,
    templateSelection,
    domain,
    templateType,
    websiteSpeciality,
    audienceSize,
    plan,
    hasSelectedTemplate,
  } = useCart.getState();
  const isBusiness = type === "Business";
  const isPersonal = type === "Personal";
  const isDomain = type === "Domain";
  const selectedPurpose = isBusiness || isPersonal;

  // const templatesPath = "/templates";
  const templatesPath = "/template-type";
  const pricingPlanPath = "/pricing-plan";
  const pricingDetailsPath = "/pricing-details";
  const askPurposePath = "/purpose";
  const askTrademarkPath = "/trademark";
  const askBusinessRegistrationPath = "/formation";
  const registrationSelectionPath = "/state-selection";
  const isDomainSelected = Object.keys(domain).length > 0;
  const isPlanSelected = Object.keys(plan).length > 0;

  console.log({ isPlanSelected });

  // The function determines the next path based on the current path and the state managed by the useCart hook.
  // The function first retrieves the necessary state values from the useCart store ( user selection states ), It also initializes various path strings for different steps in the process, then uses a switch statement to handle different cases based on the current path. For each case, it checks the state values and returns the appropriate path for the next step in the process.

  switch (currentPath) {
    case "template-type":
      if (templateType === "Blank Cut Team") {
        return "/contact-us?variant=dream-team";
      }
      return "website-speciality";

    case "website-speciality":
      return "audience-size";

    case "audience-size":
      if (!hasSelectedTemplate) {
        return `/templates?variant=${templateType}`;
      }

      if (templateType === "Static Site") {
        return "/static-site-customization";
      }

      if (!selectedPurpose) {
        return askPurposePath;
      }

      if (isPlanSelected) {
        return askTrademarkPath;
      }

      return pricingPlanPath;

    case "template-selection":
      if (templateSelection) {
        return "template-type";
      } else if (isDomainSelected) {
        return pricingDetailsPath;
      }

      return askPurposePath;

    case "static-site-customization":
      if (!selectedPurpose) {
        return askPurposePath;
      }

      if (isPlanSelected) {
        return askTrademarkPath;
      }
      return pricingPlanPath;

    case "purpose":
      if (audienceSize) {
        return pricingPlanPath;
      }
      if (isPersonal) {
        return "/website-speciality";
      }

      if (isBusiness) {
        return "/website-speciality";
      }

      if (isDomain) {
        return pricingDetailsPath;
      }

      break;

    case "pricing-plan":
      if (templateSelection === null) {
        return "/templates";
      }
      return askTrademarkPath;

    case "trademark":
      if (isPersonal) {
        if (trademarkRegistration) {
          return registrationSelectionPath; // TODO: change to trademark registration state selection path
        } else {
          return pricingDetailsPath; // TODO: change to trademark registration state selection path
        }
      }
      if (isBusiness) {
        if (!trademarkRegistration) {
          return askBusinessRegistrationPath;
        } else {
          return registrationSelectionPath;
        }
      }
      break;

    case "afterBusinessRegistration":
      // TODO: add and change to trademark registration state selection path
      if (isPersonal) {
        return pricingDetailsPath;
      } else if (!businessRegistration) {
        return askBusinessRegistrationPath;
      }

      return pricingDetailsPath;

    case "company-registration":
      if (businessRegistration) {
        return registrationSelectionPath;
      } else {
        return pricingDetailsPath;
      }

    case "templatePage":
      if (audienceSize && templateType === "Static Site") {
        return "static-site-customization";
      }

      if (isPlanSelected) {
        return "website-speciality";
      }

      return askPurposePath;

    case "add-domain":
      if (!templateSelection) {
        return "/template-selection";
      }
      return askPurposePath;

    default:
      return templatesPath;
  }
};
