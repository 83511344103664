import React from "react";
import "../../styles/button.css";
import { Link } from "react-router-dom";

const MobileMenu = ({ menuOpen, options, links }) => {
  return (
    <div
      className="absolute md:hidden w-screen z-30 bg-white text-black transform duration-500 ease-out border-t border-black"
      style={
        menuOpen
          ? { transform: "translateY(0)" }
          : { transform: "translateY(calc(-100% - 100px))" }
      }
    >
      <div className="w-full h-full flex flex-col shadow-xl px-5 py-6 gap-20">
        <div className="flex flex-col gap-4">
          {options.map((option, index) => (
            <Link key={option} to={links[index]} className="font-bold text-lg">
              {option}
            </Link>
          ))}
        </div>
        <div className="flex flex-col gap-4">
          <Link to="/login" className="font-bold text-lg">
            Log in
          </Link>
          <div className="outer__button__mobile button__left-right__mobile">
            <div className="text">Sign up</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
