import React from "react";
import Topbar from "./Topbar/Topbar";

const DashboardLayout = ({ children }) => {
  return (
    <>
      <Topbar />
      {children}
    </>
  );
};

export default DashboardLayout;
