import React from "react";
import { useHistory } from "react-router";

const StepBox = ({ countImage, index, content }) => {
  return (
    <div className="text-left relative pt-20">
      <img className="absolute -top-6" src={countImage} alt={`${index + 1}`} />
      <p
        className={
          index < steps.length - 1
            ? "pl-10 pr-6 md:pr-10 pb-8 md:pb-0 mr-0 md:mr-6 text-[16px] font-light border-r-0 border-b-4 md:border-b-0 md:border-r-4 border-dark border-opacity-5"
            : "pl-10 pr-6 md:pr-10 pb-8 md:pb-0 mr-0 md:mr-6 text-[16px] font-light"
        }
      >
        {content}
      </p>
    </div>
  );
};

const Steps = () => {
  const history = useHistory();

  return (
    <div className="relative m-auto flex flex-col items-center pt-10 md:pt-24 pb-8 md:pb-12 max-w-[1380px]">
      <p className="text-3xl md:text-[32px] mb-3 tracking-[-1px]">
        Build Your Unique Online Presence
      </p>
      <p className="font-extralight text-center">
        To create your own website, follow these steps:
      </p>
      <div className="flex flex-col md:flex-row gap-4 md:gap-0 py-4 md:py-0">
        {steps.map((stepContent, index) => (
          <StepBox
            key={`step${index}`}
            countImage={images[index]}
            index={index}
            content={stepContent}
          />
        ))}
      </div>
      <button
        onClick={() => history.push("/search")}
        className="rounded-full bg-light pl-10 pr-10 mt-4 md:mt-16 mb-8 md:mb-10 h-[52px] duration-300 border border-blue-500 text-blue-500 font-extrabold hover:bg-blue-500 hover:text-white hover:border-white focus:outline-none"
      >
        Get Started
      </button>
    </div>
  );
};

export default Steps;

const steps = [
  "Start by picking a unique domain name. Search for a domain using the Blank Cut platform. It’s your online address that everyone will recognize.",
  "Browse through our diverse templates and discover the one that’s the perfect fit for you.",
  "Fill out a simple questionnaire. It helps us know what you need on your site.",
  "Just sit back and relax. Watch as we put it all together and launch your site just the way you want it.",
];
const images = [
  "/count-one.svg",
  "/count-two.svg",
  "/count-three.svg",
  "/count-four.svg",
];
