import NotionBlockRenderer from "./NotionBlockRenderer";

const StandardBlock = ({ subBlocks, level, children, pathBuilder }) => {
  const subTopGap = level === 0 ? "mt-2" : "mt-1";
  const topGap = subBlocks?.length > 0 ? "mt-2 mb-1" : subTopGap;
  return (
    <div className={`${topGap} ${level === 0 ? "text-base" : "text-sm"}`}>
      {children}
      {subBlocks?.length > 0 && 
        <div className={ `${subTopGap} ml-4`}> 
          {subBlocks.map((sb) => (
            <NotionBlockRenderer key={sb.content.id} stepsProgression={{}} block={sb} level={level + 1} pathBuilder={pathBuilder}/>)
          )}
        </div>
      }
    </div>
  );
}

export default StandardBlock;