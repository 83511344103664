import { useCart } from "../../store";

const ActivePlanCard = ({ features, price }) => {
  const { type } = useCart();

  return (
    <div className="relative max-w-[100%] bg-white flex flex-col justify-between gap-10 md:gap-0 px-16 py-24 rounded-lg shadow-lg">
      <img
        src="/your-plan.svg"
        alt="plan-tag"
        className="absolute top-0 right-0"
      />
      <div>
        <h2 className="text-3xl text-dark text-center font-extrabold">
          {type}
        </h2>
        <p className="font-light text-center text-black text-opacity-75">
          Price Details Below 
        </p>
      </div>
      <div className="relative w-max mx-auto flex flex-col">
        <h1 className="text-button text-[4rem]">{price}</h1>
        <p className="font-light transform -translate-y-6 mx-auto">Per Month</p>
        <p className="text-xl absolute top-7 -left-4">$</p>
      </div>
      <ul className=" mx-auto font-light text-center text-black text-opacity-80">
        {features.map((item) => (
          <li>{item}</li>
        ))}
      </ul>
    </div>
  );
};

export default ActivePlanCard;
