import React from "react";
import { Listbox } from "@headlessui/react";
import { FaChevronDown } from "react-icons/fa";
import cn from "classnames";

const RoundedDropdown = ({ selected, onChange, options, variant }) => {
  return (
    <Listbox value={selected} onChange={onChange}>
      <div className="relative mt-1">
        <Listbox.Button
          className={cn(
            "relative w-full cursor-default rounded-full bg-white py-[12px] px-6 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-lg",
            {
              "border border-black": variant === "bordered",
            }
          )}
        >
          {({ value }) => (
            <div>
              <span className="block truncate capitalize">{value}</span>
              <span className="pointer-events-none absolute inset-y-0 right-6 flex items-center pr-2">
                <FaChevronDown
                  className={cn("h-4 w-4 text-black")}
                  aria-hidden="true"
                />
              </span>
            </div>
          )}
        </Listbox.Button>

        <Listbox.Options className="absolute mt-[12px] max-h-60 w-full overflow-auto rounded-2xl bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-lg font-bold">
          {options.map((option, i) => (
            <Listbox.Option
              key={i}
              className={({ active }) =>
                `relative cursor-default select-none py-[12px] px-6 ${
                  active ? "bg-primary text-dark" : "text-gray-900"
                }`
              }
              value={option.value}
            >
              {({ selected }) => (
                <>
                  <span className={cn("block truncate font-bold capitalize")}>
                    {option.label || option.value}
                  </span>
                  {/* {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null} */}
                </>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </div>
    </Listbox>
  );
};

export default RoundedDropdown;
