import Layout from "../Layout";
import Navbar from "../components/Reusable/Navbar";
import Hero from "../components/Homepage/Hero";
import Templates from "../components/Homepage/Templates";
import Steps from "../components/Homepage/Steps";
import Features from "../components/Homepage/Features";
import Search from "../components/Homepage/Search";
import Footer from "../components/Reusable/Footer";

const Homepage = () => {
  return (
    <div className="flex flex-col">
      <Navbar />
      <Layout bgcolor="secondary">
        <Hero />
      </Layout>
      <Layout bgcolor="white">
        <Templates />
      </Layout>
      <div className="relative">
        <img
          src="steps-illustration.svg"
          alt="steps-illustration"
          className="absolute top-0 left-0"
        />
        <Layout bgcolor="light">
          <Steps />
        </Layout>
      </div>
      <div className="relative">
        <img
          src="feature-arrow.svg"
          alt="feature-illustration"
          className="hidden md:flex absolute top-0 right-0"
        />
        <Layout bgcolor="secondary">
          <Features />
        </Layout>
        <img
          src="features-illustration.svg"
          alt="feature-illustration"
          width="360px"
          className="hidden md:flex absolute -bottom-20 -left-16 z-0"
        />
      </div>
      <Layout bgcolor="dark">
        <Search />
      </Layout>
      <Layout bgcolor="secondary">
        <Footer />
      </Layout>
    </div>
  );
};

export default Homepage;
