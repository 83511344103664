import React, { useEffect } from "react";

const options = [
  {
    value: "+1",
    label: "US",
  },
  {
    value: "+44",
    label: "UK",
  },
];

const PhoneInput = ({
  label,
  type,
  placeholder,
  error,
  onChange,
  value,
  ...props
}) => {
  const loadScript = (src) =>
    new Promise((resolve, reject) => {
      if (document.querySelector(`script[src="${src}"]`)) return resolve();
      const link = document.createElement("link");
      const script = document.createElement("script");
      script.src = src;
      link.rel = "stylesheet";
      link.href =
        "https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/css/intlTelInput.css";
      script.onload = () => resolve();
      script.onerror = (err) => reject(err);
      document.head.appendChild(link);
      document.body.appendChild(script);
    });

  useEffect(() => {
    loadScript(
      "https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/intlTelInput.min.js"
    ).then(() => {
      let input = document.querySelector("#phone");
      window.intlTelInput(input, {
        utilsScript:
          "https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/utils.js",
        autoInsertDialCode: true,
        nationalMode: false,
      });
    });
  }, []);

  return (
    <div>
      <label className="pb-1.5 text-md font-medium text-[#344054]">
        {label}
      </label>

      <input
        id="phone"
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          const inputValue = e.target.value;

          if (inputValue === "") {
            onChange("+");
            return;
          }

          if (!inputValue.startsWith("+")) {
            onChange("+" + inputValue);
          } else {
            onChange(inputValue);
          }
        }}
        className="border border-[#D0D5DD] w-full block py-2.5 px-2.5 rounded-[8px] shadow-[0px,1px,2px,0px_rgba(16,24,40,0.05)] bg-white focus:outline-0 placeholder:text-[#667085]"
        autocomplete="off"
      />
      {error && <p className="text-red-500 text-sm pt-2 px-2">{error}</p>}
    </div>
  );
};

export default PhoneInput;
