export const pricingData = {
  Business: {
    "Ghost Site": {
      headerRow: [
        {
          category: "Business",
          price: "20",
          plan: "per month",
          saving: "Save 20% annually",
          features: [
            "SSL Security",
            "Unlimited Bandwidth & Storage",
            "SEO Features for Site Visibility",
            "Free Templates",
            "Contributors",
          ],
        },
        {
          category: "Business",
          price: "35",
          plan: "per month",
          saving: "Save 20% annually",
          features: [
            "Free Custom Domain",
            "SSL Security",
            "Unlimited Bandwidth & Storage",
            "SEO Features for Site Visibility",
            "Free Templates",
            "Contributors",
          ],
        },
        {
          category: "Business",
          price: "45",
          plan: "per month",
          saving: "Save 20% annually",
          features: [
            "Free Custom Domain",
            "SSL Security",
            "Unlimited Bandwidth & Storage",
            "SEO Features for Site Visibility",
            "Free Templates",
            "Contributors",
          ],
        },
        {
          category: "Business",
          price: "60",
          plan: "per month",
          saving: "Save 20% annually",
          features: [
            "Free Custom Domain",
            "SSL Security",
            "Unlimited Bandwidth & Storage",
            "SEO Features for Site Visibility",
            "Free Templates",
            "Contributors",
          ],
        },
      ],
      rowData: [
        ["Free Custom Domain", "false", "true", "true", "true"],
        ["SSL Security", "true", "true", "true", "true"],
        ["Unlimited Bandwidth & Storage", "true", "true", "true", "true"],
        ["SEO Features for Site Visibility", "true", "true", "true", "true"],
        ["Free Templates", "true", "true", "true", "true"],
        ["Contributors", "true", "true", "true", "true"],
      ],
    },
    "Static Site": {
      headerRow: [
        {
          category: "Business",
          price: "12",
          plan: "per month",
          saving: "Save 20% annually",
          features: [
            "SSL Security",
            "Unlimited Bandwidth & Storage",
            "SEO Features for Site Visibility",
            "Free Templates",
            "Contributors",
          ],
        },
        {
          category: "Business",
          price: "25",
          plan: "per month",
          saving: "Save 20% annually",
          features: [
            "Free Custom Domain",
            "SSL Security",
            "Unlimited Bandwidth & Storage",
            "SEO Features for Site Visibility",
            "Free Templates",
            "Contributors",
          ],
        },
        {
          category: "Business",
          price: "35",
          plan: "per month",
          saving: "Save 20% annually",
          features: [
            "Free Custom Domain",
            "SSL Security",
            "Unlimited Bandwidth & Storage",
            "SEO Features for Site Visibility",
            "Free Templates",
            "Contributors",
          ],
        },
        {
          category: "Business",
          price: "40",
          plan: "per month",
          saving: "Save 20% annually",
          features: [
            "Free Custom Domain",
            "SSL Security",
            "Unlimited Bandwidth & Storage",
            "SEO Features for Site Visibility",
            "Free Templates",
            "Contributors",
          ],
        },
      ],
      rowData: [
        ["Free Custom Domain", "false", "true", "true", "true"],
        ["SSL Security", "true", "true", "true", "true"],
        ["Unlimited Bandwidth & Storage", "true", "true", "true", "true"],
        ["SEO Features for Site Visibility", "true", "true", "true", "true"],
        ["Free Templates", "true", "true", "true", "true"],
        ["Contributors", "true", "true", "true", "true"],
      ],
    },
    DEFAULT: {
      headerRow: [
        {
          category: "Business",
          price: "12",
          plan: "per month",
          saving: "Save 20% annually",
          features: [
            "SSL Security",
            "Unlimited Bandwidth & Storage",
            "SEO Features for Site Visibility",
            "Free Templates",
            "Contributors",
          ],
        },
        {
          category: "Business",
          price: "25",
          plan: "per month",
          saving: "Save 20% annually",
          features: [
            "Free Custom Domain",
            "SSL Security",
            "Unlimited Bandwidth & Storage",
            "SEO Features for Site Visibility",
            "Free Templates",
            "Contributors",
          ],
        },
        {
          category: "Business",
          price: "35",
          plan: "per month",
          saving: "Save 20% annually",
          features: [
            "Free Custom Domain",
            "SSL Security",
            "Unlimited Bandwidth & Storage",
            "SEO Features for Site Visibility",
            "Free Templates",
            "Contributors",
          ],
        },
        {
          category: "Business",
          price: "40",
          plan: "per month",
          saving: "Save 20% annually",
          features: [
            "Free Custom Domain",
            "SSL Security",
            "Unlimited Bandwidth & Storage",
            "SEO Features for Site Visibility",
            "Free Templates",
            "Contributors",
          ],
        },
      ],
      rowData: [
        ["Free Custom Domain", "false", "true", "true", "true"],
        ["SSL Security", "true", "true", "true", "true"],
        ["Unlimited Bandwidth & Storage", "true", "true", "true", "true"],
        ["SEO Features for Site Visibility", "true", "true", "true", "true"],
        ["Free Templates", "true", "true", "true", "true"],
        ["Contributors", "true", "true", "true", "true"],
      ],
    },
  },
  Personal: {
    "Ghost Site": {
      headerRow: [
        {
          category: "Personal",
          price: "18",
          plan: "per month",
          saving: "Save 20% annually",
          features: [
            "SSL Security",
            "Unlimited Bandwidth & Storage",
            "SEO Features for Site Visibility",
            "Free Templates",
            "Contributors",
          ],
        },
        {
          category: "Personal",
          price: "35",
          plan: "per month",
          saving: "Save 20% annually",
          features: [
            "Free Custom Domain",
            "SSL Security",
            "Unlimited Bandwidth & Storage",
            "SEO Features for Site Visibility",
            "Free Templates",
            "Contributors",
          ],
        },
        {
          category: "Personal",
          price: "45",
          plan: "per month",
          saving: "Save 20% annually",
          features: [
            "Free Custom Domain",
            "SSL Security",
            "Unlimited Bandwidth & Storage",
            "SEO Features for Site Visibility",
            "Free Templates",
            "Contributors",
          ],
        },
        {
          category: "Personal",
          price: "60",
          plan: "per month",
          saving: "Save 20% annually",
          features: [
            "Free Custom Domain",
            "SSL Security",
            "Unlimited Bandwidth & Storage",
            "SEO Features for Site Visibility",
            "Free Templates",
            "Contributors",
          ],
        },
      ],
      rowData: [
        ["Free Custom Domain", "false", "true", "true", "true"],
        ["SSL Security", "true", "true", "true", "true"],
        ["Unlimited Bandwidth & Storage", "true", "true", "true", "true"],
        ["SEO Features for Site Visibility", "true", "true", "true", "true"],
        ["Free Templates", "true", "true", "true", "true"],
        ["Contributors", "true", "true", "true", "true"],
      ],
    },
    "Static Site": {
      headerRow: [
        {
          category: "Personal",
          price: "12",
          plan: "per month",
          saving: "Save 20% annually",
          features: [
            "SSL Security",
            "Unlimited Bandwidth & Storage",
            "SEO Features for Site Visibility",
            "Free Templates",
            "Contributors",
          ],
        },
        {
          category: "Personal",
          price: "25",
          plan: "per month",
          saving: "Save 20% annually",
          features: [
            "Free Custom Domain",
            "SSL Security",
            "Unlimited Bandwidth & Storage",
            "SEO Features for Site Visibility",
            "Free Templates",
            "Contributors",
          ],
        },
        {
          category: "Personal",
          price: "35",
          plan: "per month",
          saving: "Save 20% annually",
          features: [
            "Free Custom Domain",
            "SSL Security",
            "Unlimited Bandwidth & Storage",
            "SEO Features for Site Visibility",
            "Free Templates",
            "Contributors",
          ],
        },
        {
          category: "Personal",
          price: "40",
          plan: "per month",
          saving: "Save 20% annually",
          features: [
            "Free Custom Domain",
            "SSL Security",
            "Unlimited Bandwidth & Storage",
            "SEO Features for Site Visibility",
            "Free Templates",
            "Contributors",
          ],
        },
      ],
      rowData: [
        ["Free Custom Domain", "false", "true", "true", "true"],
        ["SSL Security", "true", "true", "true", "true"],
        ["Unlimited Bandwidth & Storage", "true", "true", "true", "true"],
        ["SEO Features for Site Visibility", "true", "true", "true", "true"],
        ["Free Templates", "true", "true", "true", "true"],
        ["Contributors", "true", "true", "true", "true"],
      ],
    },
    DEFAULT: {
      headerRow: [
        {
          category: "Personal",
          price: "12",
          plan: "per month",
          saving: "Save 20% annually",
          features: [
            "SSL Security",
            "Unlimited Bandwidth & Storage",
            "SEO Features for Site Visibility",
            "Free Templates",
            "Contributors",
          ],
        },
        {
          category: "Personal",
          price: "25",
          plan: "per month",
          saving: "Save 20% annually",
          features: [
            "Free Custom Domain",
            "SSL Security",
            "Unlimited Bandwidth & Storage",
            "SEO Features for Site Visibility",
            "Free Templates",
            "Contributors",
          ],
        },
        {
          category: "Personal",
          price: "35",
          plan: "per month",
          saving: "Save 20% annually",
          features: [
            "Free Custom Domain",
            "SSL Security",
            "Unlimited Bandwidth & Storage",
            "SEO Features for Site Visibility",
            "Free Templates",
            "Contributors",
          ],
        },
        {
          category: "Personal",
          price: "40",
          plan: "per month",
          saving: "Save 20% annually",
          features: [
            "Free Custom Domain",
            "SSL Security",
            "Unlimited Bandwidth & Storage",
            "SEO Features for Site Visibility",
            "Free Templates",
            "Contributors",
          ],
        },
      ],
      rowData: [
        ["Free Custom Domain", "false", "true", "true", "true"],
        ["SSL Security", "true", "true", "true", "true"],
        ["Unlimited Bandwidth & Storage", "true", "true", "true", "true"],
        ["SEO Features for Site Visibility", "true", "true", "true", "true"],
        ["Free Templates", "true", "true", "true", "true"],
        ["Contributors", "true", "true", "true", "true"],
      ],
    },
  },
};
