import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useCart } from "../../store";
import { Prompt } from "react-router-dom/cjs/react-router-dom.min";

const PricingDetailsCard = () => {
  const {
    getSubTotal,
    getFees,
    removeFromCart,
    getTotal,
    clearPlan,
    type,
    clearCart,
    prepareCart,
    items,
    setFlowStarted,
  } = useCart();
  const history = useHistory();

  const handleDelete = (name) => {
    if (name.includes("Plan")) {
      clearPlan();
    }

    removeFromCart(name);
  };

  if (items.length === 0) {
    clearCart();
    history.push("/pricing-plan");
  }

  useEffect(() => {
    prepareCart();
  }, []);

  return (
    <div className="bg-white rounded-lg py-8 md:py-12 px-5 md:px-12 w-full md:w-[540px] shadow-lg">
      <div className="flex flex-row items-center justify-between">
        <p className="text-button uppercase ml-0.5 text-sm font-light tracking-[3px]">
          Plan
        </p>
        <p className="text-button uppercase text-sm font-light tracking-[3px]">
          Total
        </p>
      </div>
      <div className="flex flex-row items-center justify-between">
        <h1 className="text-3xl md:text-4xl text-dark tracking-tight">
          {type || "Domain"}
        </h1>
        <h1 className="text-3xl md:text-4xl text-dark tracking-tight">
          ${getTotal()}
        </h1>
      </div>
      <div className="h-px w-full bg-button opacity-20 mt-10 mb-8" />
      <div className="flex flex-col capitalize min-h-[120px]">
        {items.map((item) => (
          <div
            key={item.name}
            name={item.name}
            className="flex flex-row items-baseline justify-between -mr-1 md:-mr-4"
          >
            <p className="text-sm text-dark">{item.name}</p>
            <p className="text-lg md:text-xl text-dark inline-block">
              {item.price}{" "}
              <img
                src="/delete-icon.svg"
                alt="delete"
                className="inline-block mb-1 ml-3.5 cursor-pointer"
                width="18px"
                onClick={() => handleDelete(item.name)}
              />
            </p>
          </div>
        ))}
      </div>
      <div className="h-px w-full bg-button opacity-20 mt-10 mb-8" />
      <div className="flex flex-col">
        <div className="flex flex-row items-baseline justify-between">
          <p className="text-sm text-dark">Sub Total</p>
          <p className="text-lg md:text-xl text-dark">${getSubTotal()}</p>
        </div>
        <div className="flex flex-row items-baseline justify-between">
          <p className="text-sm text-dark">Service Fee</p>
          <p className="text-lg md:text-xl text-dark">${getFees()}</p>
        </div>
      </div>
      <div className="h-px w-full bg-button opacity-20 mt-10 mb-8" />
      <div className="flex flex-col-reverse md:flex-row gap-6 md:gap-0 items-center justify-between">
        <button
          onClick={() => {
            clearCart();
            history.push("/templates");
            setFlowStarted(false);
          }}
          className="flex flex-row items-center justify-center pt-1.5 gap-1.5 text-button font-bold hover:underline focus:outline-none"
        >
          <img src="/back-arrow.svg" alt="arrow" className="pb-1" />
          <p>Start Over</p>
        </button>
        <button
          onClick={() => {
            type === "Personal"
              ? history.push("/deployment-basic")
              : history.push("/deployment-business");
          }}
          className="h-11 w-full md:w-auto px-6 pt-1 flex flex-row items-center justify-center gap-2 rounded-full bg-button text-white font-bold transition duration-300 hover:bg-blue-600 focus:outline-none"
        >
          <p>Proceed to Pay</p>
          <img src="/forward-arrow.svg" alt="arrow" className="pl-1 pb-1" />
        </button>
      </div>

      {/* <Prompt
        when={true}
        message={(location, action) => {
          if (
            location.pathname === "/deployment-basic" ||
            location.pathname === "/deployment-business"
          ) {
            return true;
          } else {
            const res = window.confirm(
              "Are you sure you want to leave this page? Your cart will be cleared."
            );

            if (res) {
              clearCart();
            } else {
              return false;
            }
          }
        }}
      /> */}
    </div>
  );
};

export default PricingDetailsCard;
