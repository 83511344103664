import React from "react";
import { useHistory } from "react-router";
import { useCart } from "../../store";

const Hero = () => {
  const history = useHistory();
  const [query, setQuery] = React.useState("");
  const handleChange = (event) => {
    event.preventDefault();
    let changeEventQuery = event.target.value;
    changeEventQuery = changeEventQuery.replace("www.", "");
    setQuery(changeEventQuery);
  };
  const { setDomain } = useCart();
  const handleSearch = () => {
    setDomain({
      searchQuery: query,
      outsideSearch: true,
    });
    history.push("/search");
  };

  return (
    <div className="h-[calc(100vh-128px)] md:h-auto flex flex-col md:flex-row justify-evenly gap-8 md:gap-0 m-auto w-full max-w-7xl mt-6 md:mt-20 md:my-20">
      <div className="flex flex-col w-full md:w-1/2 px-5 md:px-0 justify-center items-start gap-6 text-left">
        <p className="text-4xl md:text-6xl font-light tracking-[-1px]">
          Empowering Your Journey from the First Click.
        </p>
        <p className="font-extralight text-base md:text-[18px] mr-0 md:mr-24 mb-1 md:mb-8">
          Launch and manage your online presence effortlessly. Start shaping your website today by finding the perfect domain.
        </p>
        <form className="flex flex-row w-full md:w-auto">
          <input
            placeholder="Search"
            type="text"
            defaultValue={query}
            onChange={(event) => handleChange(event)}
            className="h-14 rounded-full max-w-[100%] w-[500px] font-light box-border pl-7 md:pl-9 pr-9 focus:outline-none"
          />
          <button
            id="searchButton"
            type="submit"
            onClick={() => handleSearch()}
            onKeyPress={(event) => event.key === "Enter" && handleSearch()}
            className="bg-button hover:bg-[#177fc0] transition duration-300 border-4 border-white -ml-20 flex flex-row items-center justify-center h-full rounded-full w-24 focus:outline-none"
          >
            <img src="/search-icon.svg" alt="search-icon" width="26px" />
          </button>
        </form>
      </div>
      <div className="w-full md:w-1/2 flex flex-col justify-center items-end">
        <img src="/hero-illustration.svg" alt="hero-illustration" width="85%" />
      </div>
    </div>
  );
};

export default Hero;
