import React from "react";
import Layout from "../Layout";
import Navbar from "../components/Reusable/Navbar";
import SearchBody from "../components/SearchDomain/SearchBody";
import BottomBar from "../components/SearchDomain/BottomBar";
import SearchResults from "../components/SearchDomain/SearchResults";

import { useCart } from "../store";

const SearchDomain = () => {
  const { domain } = useCart();

  return (
    <div className="flex flex-col">
      <Navbar />
      <div className="h-max overflow-hidden flex flex-col justify-between">
        <Layout bgcolor="secondary">
          <SearchBody />
        </Layout>
        {domain.isAvailable | (domain.isAvailable === false) ? (
          <SearchResults />
        ) : (
          <Layout bgcolor="white">
            <BottomBar />
          </Layout>
        )}
      </div>
    </div>
  );
};

export default SearchDomain;
