import React, { useEffect } from "react";
import Layout from "../Layout";
import Navbar from "../components/Reusable/Navbar";
import { useHistory } from "react-router";
import PricingTable from "../components/Pricing/PricingTable";
import { useCart } from "../store";
import { getPath } from "../utils/getPath";
import cn from "classnames";

const PricingPlan = () => {
  const history = useHistory();
  const { setPlan, addToCart, setType, type, isFlowStarted } = useCart();
  const [selectedPlan, setSelectedPlan] = React.useState(null);

  // this is a handler function that is called when the user click on the next button to set the plan type and price to the selected plan type and price and then redirect the user to the next page
  const handleNext = async () => {
    setPlan({
      type: selectedPlan.type,
      features: selectedPlan.features,
      price:
        selectedPlan.type === "annually"
          ? (Number(selectedPlan.price) * 0.8).toFixed()
          : selectedPlan.price,
    });
    addToCart({
      name: `${type} Plan`,
      price:
        selectedPlan.type === "annually"
          ? (Number(selectedPlan.price) * 0.8).toFixed()
          : selectedPlan.price,
      type,
    });

    history.push(await getPath("pricing-plan"));
  };

  useEffect(() => {
    if (!type) {
      setType("Personal");
    }
  }, []);

  return (
    <div className="flex flex-col">
      <Navbar />
      <Layout bgcolor="light">
        <div className="w-full max-w-screen-xl min-h-screen flex flex-col mx-auto my-20">
          {!isFlowStarted && (
            <div className="flex items-left justify-left mb-5">
              <div
                className={cn(
                  "p-3 shadow-md cursor-pointer transition-colors",
                  {
                    "bg-black text-white": type === "Personal",
                    "bg-white text-black": type !== "Personal",
                  }
                )}
                onClick={() => setType("Personal")}
              >
                Personal
              </div>
              <div
                className={cn(
                  "p-3 shadow-md cursor-pointer transition-colors",
                  {
                    "bg-black text-white": type === "Business",
                    "bg-white text-black": type !== "Business",
                  }
                )}
                onClick={() => setType("Business")}
              >
                Business
              </div>
            </div>
          )}

          <div className="relative mx-auto">
            <h2 className="text-[2rem] text-center font-black px-5 mb-4 tracking-[-0.82px] leading-[32px]">
              Choose a Plan Tailored to Your Needs
            </h2>
            <img
              src="/pricing-illustration.svg"
              alt="illustration"
              className="transform scale-50 md:scale-75 absolute right-0 md:-right-16 top-8 md:top-0 z-50"
            />
          </div>
          <div className="max-w-screen-xl w-[90%] md:w-full mx-[5%] md:mx-0 m-auto overflow-auto">
            <PricingTable setSelectedPlan={setSelectedPlan} />
          </div>
        </div>
        <p
          onClick={handleNext}
          className="text-lg text-button w-max mx-auto mb-20 text-center cursor-pointer hover:underline focus:outline-none"
        >
          Next &#8594;
        </p>
      </Layout>
    </div>
  );
};

export default PricingPlan;
