import React from "react";
import Layout from "../Layout";
import Navbar from "../components/Reusable/Navbar";
import QuestionScreen from "../components/Reusable/QuestionScreen";
import { getPath } from "../utils/getPath";

const question = "I want to create a website for...";
const options = [
  {
    label: "My Personal Use",
    value: "Personal",
  },
  {
    label: "My Business",
    value: "Business",
  },
];

export const SitePurpose = () => {
  const getLink = async () => await getPath("purpose");

  return (
    <div className="flex flex-col">
      <Navbar />
      <div>
        <Layout>
          <QuestionScreen
            navbar
            pageName="purpose"
            question={question}
            options={options}
            link={getLink}
          />
        </Layout>
      </div>
    </div>
  );
};
