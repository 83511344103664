import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Layout from "../Layout";
import Navbar from "../components/Reusable/Navbar";
import BreadCrumb from "../components/Reusable/BreadCrumb";
import Content from "../components/Article/Content";
import TableOfContent, {
  getBusinessStateUrl,
} from "../components/Article/TableOfContent";
import Faq from "../components/Article/Faq";
import { findInTree } from "../utils/arrayHelpers";
import useQueryParams from "../utils/useQueryParams";
import MobileTableOfContent from "../components/Article/MobileTableOfContent";
import MobileFaqDrawer from "../components/Article/MobileFaqDrawer";
import axios from "axios";

// temporary
const dummyTopic = "Account";

// tocs: tocPage[];
const getFirstPage = (tocPages) => {
  const first = tocPages.filter((t) => t.id !== dummyTopic).at(0);
  return first.subPages.at(0);
};

const getPageFromKindOfEntityName = (tocPages, kindOfEntity) => {
  const found = findInTree(
    { name: "_root", subPages: tocPages },
    (t) => t.name.trim() === kindOfEntity,
    (t) => t.subPages
  );
  return found;
};

const StateArticle = () => {
  const [dataState, setDataState] = useState({
    article: undefined,
    isLoading: false,
    error: undefined,
  });
  const [tocMenuOpened, setTocMenuOpened] = useState(false);
  let { state, pageId } = useParams();
  const queryParams = useQueryParams();
  const history = useHistory();

  useEffect(
    () => {
      const articleCall = {
        method: "GET",
        url: "/articles/business/" + state,
        headers: {
          "Content-Type": "application/json",
        },
      };

      setDataState({ article: undefined, isLoading: true, error: undefined });
      axios(articleCall)
        .then((result) => {
          setDataState({
            article: result.data,
            isLoading: false,
            error: undefined,
          });
        })
        .catch((e) => {
          console.error("Error gettings Article", e);
          setDataState({
            article: undefined,
            isLoading: false,
            error: "Error gettings Article",
          });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const navigate = () => {
    setTocMenuOpened(false);
  };

  const { article, isLoading, error } = dataState;
  const isReady = !!article;
  const noData = isReady && article.toc[1].subPages.length === 0;

  let page = isReady && !noData ? article.pagesIndex[pageId] : undefined;

  if (isReady && !noData && !page) {
    pageId = getPageFromKindOfEntityName(
      article.toc,
      queryParams.get("kindOfEntity")
    )?.id;
    if (pageId) {
      history.push(getBusinessStateUrl(state)(pageId));
      return <></>;
    }

    pageId = getFirstPage(article.toc).id;
    page = article.pagesIndex[pageId];
  }

  const title =
    isReady && !noData
      ? state +
        " - " +
        findInTree(
          article.toc[1],
          (e) => e.id === pageId,
          (e) => e.subPages
        ).parentName
      : state;

  return (
    <div className="h-screen overflow-auto justify-center">
      <Navbar />
      <div className="flex flex-col h-min-full overflow-hidden">
        <Layout bgcolor="white">
          <div className="flex flex-col z-10 m-auto w-full max-w-4xl my-4 md:mt-10 md:mb-8">
            <h1 className="font-bold md:font-extrabold text-3xl md:text-5xl text-center text-dark">
              {title}
            </h1>
            <BreadCrumb steps={["Help", "Design Project", "Get Started"]} />
          </div>
        </Layout>
        {noData && (
          <Layout bgcolor="light" fill>
            <div className="text-2xl m-4 md:m-8">Under construction</div>
          </Layout>
        )}
        {isLoading && (
          <Layout bgcolor="light" fill>
            <div className="text-2xl m-4 md:m-8 text-error">
              Data Loading ...
            </div>
          </Layout>
        )}
        {error && (
          <Layout bgcolor="light" fill>
            <div className="text-2xl m-4 md:m-8 text-error">
              Error occured, please try later
            </div>
          </Layout>
        )}
        {isReady && !noData && (
          <Layout bgcolor="light" fill>
            <div
              className="xl:hidden w-screen sm:width-640-fixed lg:width-700-fixed xl:width-700-fixed xxl:width-800-fixed flex justify-end font-bold font-base-icon px-4 md:px-6 py-2"
              onClick={() => setTocMenuOpened(!tocMenuOpened)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                />
              </svg>
            </div>
            <MobileTableOfContent
              key={pageId}
              tocMenuOpened={tocMenuOpened}
              toc={article.toc}
              currentPageId={pageId}
              pathBuilder={getBusinessStateUrl(state)}
              onNavigate={navigate}
            />
            <div className="flex flex-col items-center flex-fill bg-white md:bg-light">
              <div className="w-screen md:max-w-8xl flex flex-row gap-6 justify-center xl:my-8">
                <div className="hidden xl:not-hidden xl:flex-250-fixed xxl:flex-300-fixed">
                  <TableOfContent
                    key={pageId}
                    toc={article.toc}
                    currentPageId={pageId}
                    pathBuilder={getBusinessStateUrl(state)}
                    onNavigate={navigate}
                  />
                </div>
                <div className="w-full sm:flex-640-fixed lg:flex-700-fixed xl:flex-700-fixed xxl:flex-800-fixed">
                  <Content
                    state={state}
                    page={page}
                    pathBuilder={getBusinessStateUrl(state)}
                  />
                </div>
                <div className="hidden xl:not-hidden xl:flex-250-fixed xxl:flex-300-fixed">
                  {page.faq?.length > 0 && <Faq blocks={page.faq}></Faq>}
                </div>
              </div>
            </div>
          </Layout>
        )}
      </div>
      {isReady && !noData && page.faq?.length > 0 && (
        <MobileFaqDrawer faq={page.faq} />
      )}
    </div>
  );
};

export default StateArticle;
