const tags = [".com", ".net", ".xyz", ".live", ".tel", ".site"];

const BottomBar = () => {
  return (
    <div className="h-max md:h-[140px] py-10 md:py-0 flex flex-row flex-wrap items-center justify-center gap-6 md:gap-24">
      {tags.map((tag) => (
        <p className="text-2xl text-dark opacity-40" key={tag}>
          {tag}
        </p>
      ))}
    </div>
  );
};

export default BottomBar;
