/*
{
  "bold": false,
  "italic": false,
  "strikethrough": false,
  "underline": false,
  "code": false,
  "color": "red"
}
*/
const parseStyle = (annotations) => {
  let style = {};
  // color
  if (annotations.color !== "default") {
    style.color = annotations.color;
  }

  if (annotations.bold) {
    style.fontWeight = "bolder";
  }

  return style;
}

const parseClasses = (annotations) => {
  let classes = "";
  // italic
  if (annotations.italic) {
    classes += " italic";
  }
  
  return classes;
}
/*
onComplete: (complete: boolean) => void
*/
const Paragraph = ({ block }) => {
  return (
    <>
      {block.rich_text.map((text, index) => {
        if (text.text.link) return <a className="underline" key={"link"+index} href={text.text.link.url} target={"_newtab" + Date.now()}>{text.text.content}</a>;
        return <span key={"text"+index} style={parseStyle(text.annotations)} classes={parseClasses(text.annotations)}>{text.text.content}</span>
      })}
    </>
  );
};

export default Paragraph;
