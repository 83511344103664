import React from "react";
import DashboardLayout from "../../../components/Dashboard/DashboardLayout";
import KnowledgeBase from "../../KnowledgeBase";

const UserDocumentation = () => {
  return (
    <DashboardLayout>
      <KnowledgeBase hasNavbar={false} />
    </DashboardLayout>
  );
};

export default UserDocumentation;
