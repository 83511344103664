import React from "react";

const Input = ({ placeholder, label, type = "text", error, ...props }) => {
  return (
    <div>
      <label className="pb-1.5 text-md font-medium text-[#344054]">
        {label}
      </label>
      <input
        className="border border-[#D0D5DD] py-2.5 px-3.5 rounded-[8px] w-full shadow-[0px,1px,2px,0px_rgba(16,24,40,0.05)] bg-white focus:outline-0 placeholder:text-[#667085]"
        type={type}
        placeholder={placeholder}
        {...props}
      />

      <p className="text-red-500 text-sm pt-2 px-2">{error}</p>
    </div>
  );
};

export default Input;
