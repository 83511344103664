import React from "react";
import Layout from "../Layout";
import Navbar from "../components/Reusable/Navbar";
import QuestionScreen from "../components/Reusable/QuestionScreen";
import { getPath } from "../utils/getPath";

const question = "Do you want a template?";
const options = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

export const TemplateQuestion = () => {
  const state = "template-selection";
  const getLink = async () => await getPath(state);

  return (
    <div className="flex flex-col">
      <Navbar />
      <div>
        <Layout>
          <QuestionScreen
            navbar
            pageName={state}
            question={question}
            options={options}
            link={getLink}
          />
        </Layout>
      </div>
    </div>
  );
};
