import { useHistory } from "react-router";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const AuthLayout = ({ children, centerText }) => {
  const history = useHistory();

  return (
    <div className="h-screen w-screen flex flex-col md:flex-row pt-10 md:pt-0">
      <div className="relative w-full md:w-2/5 h-max md:h-full bg-white flex items-center justify-center">
        <img
          alt="close-icon"
          src="/close-icon.svg"
          onClick={() => history.push("/")}
          className="absolute left-6 md:left-8 top-0 md:top-6 cursor-pointer"
        />
        <img
          src="/authentication-illustration.svg"
          alt="auth-illustration"
          className="w-[50%] md:w-[420px]"
        />
      </div>
      <div className="inherit md:relative w-full h-screen md:h-auto md:w-3/5 flex flex-col items-center bg-secondary">
        {children}
        <Link
          to="/privacy-policy"
          className={
            centerText
              ? "absolute bottom-8 md:bottom-20 w-[90%] md:w-1/2 text-center"
              : "absolute bottom-8 md:bottom-20 w-[90%] md:w-1/2 text-left"
          }
        >
          By joining, you agree with{" "}
          <p className="inline-block text-button cursor-pointer">
            Privacy Policy
          </p>
        </Link>
      </div>
    </div>
  );
};

export default AuthLayout;
