import React from "react";
import Layout from "../Layout";
import Navbar from "../components/Reusable/Navbar";
import SearchBody from "../components/SearchDomain/SearchBody";
import SearchResults from "../components/SearchDomain/SearchResults";
import BottomBar from "../components/SearchDomain/BottomBar";
import { useCart } from "../store";

const DomainOptions = () => {
  const { domain } = useCart();

  return (
    <div>
      <Navbar />
      <div className="h-max overflow-hidden flex flex-col justify-between">
        <Layout bgcolor="secondary">
          <SearchBody />
          {domain.isAvailable | (domain.isAvailable === false) ? (
            <SearchResults />
          ) : (
            <BottomBar />
          )}
        </Layout>
      </div>
    </div>
  );
};

export default DomainOptions;
