import React, { Fragment } from "react";
import { Tab } from "@headlessui/react";
import SiteCard from "../SitesBlock/SiteCard";
import cn from "classnames";

const tabs = ["All Websites", "Published", "Unpublished"];

const TabsBlock = ({ openSiteSettingModal, sites, setActiveSite }) => {
  return (
    <Tab.Group>
      <Tab.List className="flex items-center gap-2 lg:gap-6 flex-wrap px-3 lg:px-10 mt-8">
        {tabs.map((tab, i) => (
          <Tab as={Fragment} key={i}>
            {({ selected }) => (
              <div
                className={cn(
                  "cursor-pointer text-center font-extrabold text-sm lg:text-base px-4 py-2 text-[#00204F] outline-none",
                  {
                    "text-button border-b-button border-b-[2px] bg-[#DEF2FF]":
                      selected,
                  }
                )}
              >
                <span>{tab}</span>
              </div>
            )}
          </Tab>
        ))}
      </Tab.List>

      <Tab.Panels className="p-3 lg:px-10 lg:py-8 min-h-[calc(100vh-235px)] bg-light">
        <Tab.Panel className="flex items-center gap-6 flex-wrap">
          {sites.map((site) => (
            <SiteCard
              openSiteSettingModal={() => {
                openSiteSettingModal();
                setActiveSite(site);
              }}
              {...site}
            />
          ))}
        </Tab.Panel>
        <Tab.Panel className="flex items-center gap-6 flex-wrap">
          {sites
            .filter((site) => site.status === "Published")
            .map((site) => (
              <SiteCard
                openSiteSettingModal={() => {
                  openSiteSettingModal();
                  setActiveSite(site);
                }}
                {...site}
              />
            ))}
        </Tab.Panel>
        <Tab.Panel className="flex items-center gap-6 flex-wrap">
          {sites
            .filter((site) => site.status === "Unpublished")
            .map((site) => (
              <SiteCard
                openSiteSettingModal={() => {
                  openSiteSettingModal();
                  setActiveSite(site);
                }}
                {...site}
              />
            ))}
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
};

export default TabsBlock;
