const Tooltip = () => {
  return (
    <div class="relative flex flex-col items-center group cursor-pointer">
      <img src="/more-info.svg" alt="more-info" />
      <div class="absolute bottom-0 flex flex-col items-center hidden mb-6 group-hover:flex">
        <span class="relative w-max z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-black shadow-lg">
          More Information
        </span>
        <div class="w-3 h-3 -mt-2 transform rotate-45 bg-black"></div>
      </div>
    </div>
  );
};

export default Tooltip;
