import AuthLayout from "./AuthLayout";
import { useHistory } from "react-router";

const SuccessForm = () => {
  const history = useHistory();

  return (
    <AuthLayout centerText>
      <div className="relative h-full w-full max-w-[90%] md:max-w-xl mt-8 md:-mt-16 flex flex-col text-center items-center justify-start md:justify-center">
        <h2 className="text-3xl md:text-4xl mb-2">
          Verification has been completed!
        </h2>
        <p className="text-dark">
          Please check your email to confirm your account
        </p>
        <img
          src="/success-check.svg"
          alt="success-check"
          width="114px"
          className="mb-8 md:mb-14 mt-10 md:mt-16"
        />
      </div>
    </AuthLayout>
  );
};

export default SuccessForm;
