import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { defaultDomainPrice } from "../utils/domainHelpers";

// this hook initializes a state with various properties related to a shopping cart, including items, type, plan, template, domain, business registration, trademark registration, and template selection. It also includes functions to manipulate the state such as adding items to the cart, setting the type and plan, preparing the cart by filtering items, setting templates and registrations, setting domain information, removing items from the cart, clearing the cart and plan, and getting subtotal, fees, total, and whether a domain is selected.

const initialState = {
  items: [],
  type: "",
  plan: {},
  template: {},
  templateType: "",
  websiteSpeciality: "",
  audienceSize: "",
  domain: {
    suggestionList: [],
    searchQuery: "",
    outsideSearch: null,
    isAvailable: null,
    price: defaultDomainPrice,
  },
  businessRegistration: null,
  trademarkRegistration: null,
  templateSelection: null,
  hasSelectedTemplate: false,
  isFlowStarted: false,
};

const useCart = create(
  devtools((set, get) => ({
    ...initialState,
    addToCart: ({ name, price, fee, ...rest }) =>
      set((state) => ({
        items: state.items.some((item) => item.name === name)
          ? state.items.map((item) =>
              item.name === name ? { name, price, fee, ...rest } : item
            )
          : [...state.items, { name, price, fee, ...rest }],
      })),
    setType: (type) => set({ type }),
    setPlan: (plan) => set({ plan: { ...plan } }),

    prepareCart: () => {
      const { items, type } = get();
      console.log("Items before filtering", items);
      const filteredItems = items.filter(
        (item) => item.type === type || item.name.includes("Domain")
      );

      console.log("Items after filtering", filteredItems);
      set({ items: filteredItems });

      return filteredItems;
    },

    setTemplate: (template) => set({ template: { ...template } }),
    setBusinessRegistration: (businessRegistration) =>
      set({ businessRegistration }),
    setTrademarkRegistration: (trademarkRegistration) =>
      set({ trademarkRegistration }),
    setTemplateSelection: (templateSelection) => set({ templateSelection }),
    setHasSelectedTemplate: (hasSelectedTemplate) =>
      set({ hasSelectedTemplate }),

    setTemplateType: (templateType) => set({ templateType }),
    setWebsiteSpeciality: (websiteSpeciality) => set({ websiteSpeciality }),
    setAudienceSize: (audienceSize) => set({ audienceSize }),

    setDomain: (domain) =>
      set({
        domain: {
          ...get().domain,
          ...domain,
        },
      }),

    setFlowStarted: (isFlowStarted) => set({ isFlowStarted }),

    removeFromCart: (name) =>
      set((state) => ({
        items: state.items.filter((item) => item.name !== name),
      })),
    clearCart: () =>
      set({
        ...initialState,
      }),
    clearPlan: () => set({ plan: {} }),
    clearTemplate: () => set({ template: {} }),

    getSubTotal: () => {
      const { items } = get();
      return items
        ?.reduce((acc, item) => acc + Number(item.price) || 0, 0)
        .toFixed(2);
    },
    getFees: () => {
      const { items } = get();
      return items
        ?.reduce((acc, item) => acc + Number(item.fee) || 0, 0)
        .toFixed(2);
    },
    getTotal: () => {
      return Number(
        Number(get().getSubTotal()) + Number(get().getFees())
      ).toFixed(2);
    },
    getIsDomainSelected: () => {
      const { domain } = get();
      return domain?.name ? true : false;
    },
  }))
);

export default useCart;
