import React from "react";
import Layout from "../Layout";
import Navbar from "../components/Reusable/Navbar";
import QuestionScreen from "../components/Reusable/QuestionScreen";
import { getPath } from "../utils/getPath";
import { HiOutlineDocumentText } from "react-icons/hi";

const question = "What are you looking for today?";
const options = [
  {
    label: "Blank Cut Team",
    value: "Blank Cut Team",
    subLabel: "(one-on-one service)",
    icon: <img src="/icons/blank-cut-team.svg" alt="Blank Cut Team" />,
    path: "/templates",
    info: "We will build your website from scratch. You will have a one-on-one service with our team.",
  },
  {
    label: "Ghost CMS Blog",
    value: "Ghost Site",
    subLabel: "Medium Price",
    icon: <img src="/icons/ghost-logo.svg" alt="Ghost CMS Blog" />,
    info: "We will build your website using Ghost CMS. You will be able to write and publish your own blog posts.",
  },
  {
    label: "Static Site",
    value: "Static Site",
    subLabel: "Cheapest option",
    icon: <HiOutlineDocumentText />,
    info: "We will build your website using a static site generator. You will be able to write and publish your own blog posts.",
  },
];

export const CustomizeWebsiteQuestion = () => {
  const state = "template-type";
  const getLink = async () => await getPath(state);

  return (
    <div className="flex flex-col">
      <Navbar />
      <div>
        <Layout>
          <QuestionScreen
            navbar
            pageName={state}
            question={question}
            options={options}
            link={getLink}
            type="radio"
          />
        </Layout>
      </div>
    </div>
  );
};
