import React from "react";
import { useHistory } from "react-router";
import { useCart } from "../../store";
import { getPath } from "../../utils/getPath";

const TemplateCard = ({ index, name, category, provider }) => {
  const [hovering, toggleHovering] = React.useState(false);
  const history = useHistory();
  const {
    setTemplate,
    setTemplateSelection,
    setTemplateType,
    setHasSelectedTemplate,
    setFlowStarted,
  } = useCart();

  return (
    <div
      className="relative flex flex-col w-[300px] flex-grow text-left"
      onMouseEnter={() => toggleHovering(true)}
      onMouseLeave={() => toggleHovering(false)}
    >
      {hovering ? (
        <>
          <div className="h-[calc(100%-57px)] w-full absolute flex flex-col items-center justify-items-center cursor-pointer bg-white opacity-70 z-40" />
          <button
            onClick={async () => {
              setTemplate({
                name,
                category,
                provider,
                price: 0,
              });
              setTemplateSelection(true);
              setHasSelectedTemplate(true);
              setTemplateType(provider);
              setFlowStarted(true);

              history.push(await getPath("templatePage"));
            }}
            className="absolute top-[-90px] left-0 right-0 bottom-0 m-auto h-[32px] w-[82px] tracking-[-0.5px] pt-[2px] z-50 rounded-full text-white text-sm font-bold bg-button transform duration-300 hover:bg-[#177fc0] focus:outline-none"
          >
            Choose
          </button>
          <button className="absolute top-[-8px] left-0 right-0 bottom-0 m-auto h-[32px] w-[82px] tracking-[-0.5px] pt-[2px] z-50 border border-button rounded-full text-button text-sm font-bold bg-transparent transform duration-300 hover:bg-button hover:text-white focus:outline-none">
            View
          </button>
        </>
      ) : null}
      <img
        alt={name}
        className="z-10"
        src={`template-${(index % 3) + 1}.svg`}
      />
      <p className="text-xl font-bold capitalize mt-3 mb-0.5">{name}</p>
      <p className="text-button font-light text-xs uppercase tracking-[3px]">
        {provider}
      </p>
    </div>
  );
};

export default TemplateCard;
