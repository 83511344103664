import { useState } from "react";

const Dropdown = ({ options, placeholder, onSelection }) => {
  const [dropdownOpen, toggleDropdown] = useState(false);
  const [activeOption, setActiveOption] = useState(-1);

  return (
    <div className="relative">
      <button
        name="states"
        onClick={() => toggleDropdown(!dropdownOpen)}
        className="flex flex-row justify-between items-center w-full h-12 p-4 rounded focus:outline-none border-light border-[#0F2D66] text-black"
      >
        <span className={activeOption === -1 ? "text-gray-400" : "text-black"}>
          {activeOption === -1 ? placeholder : options[activeOption]}
        </span>
        <i
          className={`fa fa-chevron-down ${
            dropdownOpen
              ? "text-black transform duration-200 rotate-180"
              : "text-black transform duration-200 rotate-0"
          }`}
        />
      </button>
      {dropdownOpen && (
        <div
          className="absolute z-[50] w-full max-height-[164px] bg-white border-light shadow-xl overflow-y-auto
          scrollbar-thin scrollbar-thumb-blue-100 scrollbar-track-[#F9F9F9] hover:scrollbar-thumb-blue-200"
        >
          {options.map((option, index) => (
            <p
              key={option}
              onClick={() => {
                setActiveOption(index);
                toggleDropdown(false);
                onSelection(options[index]);
              }}
              className={`text-base text-dark px-4 py-2 cursor-pointer border-b border-blue-100 
                hover:bg-button hover:text-white
                ${index === activeOption ? "bg-blue-400" : ""}`}
            >
              {option}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
