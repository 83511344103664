import React from "react";
import Layout from "../Layout";
import Navbar from "../components/Reusable/Navbar";
import QuestionScreen from "../components/Reusable/QuestionScreen";
import { getPath } from "../utils/getPath";

const question = "What’s the website for?";
const options = [
  {
    label: "An Online Store",
    value: "An Online Store",
  },
  {
    label: "An existing website or blog",
    value: "An existing website or blog",
  },
  {
    label: "Social Media",
    value: "Social Media",
  },
  {
    label: "Services",
    value: "Services",
  },
  {
    label: "Landing Page",
    value: "Landing Page",
  },
];

export const WebsiteSpecialityQuestion = () => {
  const state = "website-speciality";
  const getLink = async () => await getPath(state);

  return (
    <div className="flex flex-col">
      <Navbar />
      <div>
        <Layout>
          <QuestionScreen
            navbar
            pageName={state}
            question={question}
            options={options}
            link={getLink}
            type="select"
          />
        </Layout>
      </div>
    </div>
  );
};
