import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Context } from "../../context/GlobalContext";
import Dropdown from "../Reusable/Dropdown";
import axios from "axios";

const emailFormat =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const kindsOfWork = [
  "Marketing",
  "Technology",
  "Fashion",
  "Finance",
  "Healthcare",
  "Real Estate",
  "and Manufacturing",
];

const kindsOfEntity = [
  "Sole Proprietorships",
  "Partnerships",
  "Corporations",
  "S Corporations",
  "Limited Liability Company (LLC)",
];

const UserInformationForm = ({ onClose }) => {
  const context = useContext(Context);
  const history = useHistory();
  const [name, setName] = useState(undefined);
  const [email, setEmail] = useState(undefined);
  const [kindOfWork, setKindOfWork] = useState(undefined);
  const [kindOfEntity, setKindOfEntity] = useState(undefined);
  const [state, setState] = useState(undefined);
  const [skipRegistration, setSkipRegistration] = useState(false);
  const [agreeMailingList, setAgreeMailingList] = useState(false);

  const handleContinue = () => {
    if (!skipRegistration) {
      const registerUserCall = {
        method: "POST",
        url: "/users/fromKnowledgeBase",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          name,
          email,
          workKind: kindOfWork,
          state,
          entityType: kindOfEntity,
          mailingList: agreeMailingList,
        },
      };
      axios(registerUserCall).catch((e) =>
        console.log("Error when registering user", e)
      );
    }

    onClose();
    history.push(
      "/knowledgebase/business-entity/" +
        state +
        "?kindOfEntity=" +
        kindOfEntity
    );
  };

  const canContinue =
    !!state &&
    !!kindOfEntity &&
    (skipRegistration || (!!name && email?.match(emailFormat) && !!kindOfWork));

  return (
    <div className="flex flex-col">
      <div className="p-5 flex flex-row justify-between">
        <h2 className="text-xl font-extrabold text-dark">
          Tell us about yourself
        </h2>
        <i
          className="fa fa-close text-xl cursor-pointer text-[#00204F]"
          onClick={onClose}
        ></i>
      </div>
      <div className="mx-5 mb-2 bg-[#EEEEEE] h-[1px]"></div>
      <div className="px-5 py-2 flex flex-col gap-1">
        <span className="font-extrabold text-[#00204F]">
          Which state you operating?
        </span>
        <Dropdown
          options={context.entityFormationStates}
          placeholder="Choose your state"
          onSelection={setState}
        />
      </div>
      <div className="px-5 py-2 flex flex-col gap-1">
        <span className="font-extrabold text-[#00204F]">
          What kind of entity you are working with ?
        </span>
        <Dropdown
          options={kindsOfEntity}
          placeholder="Choose your kind of entity"
          onSelection={setKindOfEntity}
        />
      </div>
      <div
        className="px-5 py-2 flex flex-row gap-2 cursor-pointer"
        onClick={() => setSkipRegistration(!skipRegistration)}
      >
        {skipRegistration ? (
          <img src="/checkbox_checked.svg" alt="Skip registration" />
        ) : (
          <img src="/checkbox_unchecked.svg" alt="Don't skip registration" />
        )}
        <span className="text-[#344054]">Skip registration</span>
      </div>
      {!skipRegistration && (
        <>
          <div className="px-5 py-2 flex flex-col gap-1">
            <span className="font-extrabold text-[#00204F]">Your name</span>
            <input
              type="text"
              placeholder="Your name"
              defaultValue=""
              className="w-full h-12 p-4 rounded focus:outline-none border-light border-[#0F2D66]"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="px-5 py-2 flex flex-col gap-1">
            <span className="font-extrabold text-[#00204F]">Your email</span>
            <input
              type="text"
              placeholder="Your email"
              defaultValue=""
              className="w-full h-12 p-4 rounded focus:outline-none border-light border-[#0F2D66]"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="px-5 py-2 flex flex-col gap-1">
            <span className="font-extrabold text-[#00204F]">
              What kind of work do yo do?
            </span>
            <Dropdown
              options={kindsOfWork}
              placeholder="Choose your kind of work"
              onSelection={setKindOfWork}
            />
          </div>
          <div
            className="px-5 py-2 flex flex-row gap-2 cursor-pointer"
            onClick={() => setAgreeMailingList(!agreeMailingList)}
          >
            {agreeMailingList ? (
              <img src="/checkbox_checked.svg" alt="Agree to mailing list" />
            ) : (
              <img
                src="/checkbox_unchecked.svg"
                alt="Don't agree to mailing list"
              />
            )}
            <span className="text-[#344054]">
              I agree to join Blank Cut mailing list
            </span>
          </div>
        </>
      )}
      <button
        onClick={() => canContinue && handleContinue()}
        disabled={!canContinue}
        className={`rounded m-5 h-[52px] text-[16px] transition duration-300 focus:outline-none
          ${
            canContinue
              ? "text-white bg-button hover:bg-[#177fc0]"
              : "bg-[#9EB4C5]"
          }`}
      >
        Continue
      </button>
    </div>
  );
};

export default UserInformationForm;
