import { useCallback, useContext, useState, useMemo, useEffect } from "react";
import { Context } from "../../context/GlobalContext";
import NotionBlocks from "./NotionBlocks";
import axios from "axios";

const Content = ({ state, page, pathBuilder }) => {
  const { articleSteps, setArticleSteps } = useContext(Context);
  const [hasPdf, setHasPdf] = useState(false);
  useEffect(() => {
    if (articleSteps[page.id]) {
      return;
    }

    setArticleSteps({ ...articleSteps, [page.id]: page.steps.reduce(
      (final, step) => ({ ...final, [step.id]: { ...step, completed: false } }),
      {}
    )});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page.id]);
  
  useEffect(() => {
    // check if pdf exists
    setHasPdf(false);
    const controller = new AbortController();
    axios({
      method: "HEAD",
      url: `/articles/business/${state}/${page.id.replaceAll("-", "")}/pdf`,
      validateStatus: (status) => status === 200 || status === 404
    }, {
      signal: controller.signal
    })
      .then(res => setHasPdf(res.status === 200))
      .catch(err => {
        console.error(`Error checking if article pdf for ${state} ${page.id} exists `, err);
      });
    return () => {controller.abort();};
  }, [state, page.id]);
  
  const onComplete = useCallback(
    (id, complete) => {
      setArticleSteps({ ...articleSteps, [page.id]: {...articleSteps[page.id],
            [id]: { ...articleSteps[page.id][id], completed: complete },
        }});
    },
    [articleSteps, page.id, setArticleSteps]
  );

  const downloadHandle = useCallback(async (e)=> {
    e.preventDefault();
    e.stopPropagation();
    const pdfCall = {
      method: "GET",
      url: `/articles/business/${state}/${page.id.replaceAll("-", "")}/pdf`,
      headers: {
        "Content-Type": "application/pdf",
      }
    };

    try {
      const result = await axios(pdfCall);
      const link = document.createElement("a");
      link.href = `data:application/pdf;base64,${result.data}`;
      link.download = `${state}_${page.id}.pdf`;
      link.click();
    } catch(e) {
      console.error(`Error gettings article pdf for ${state} ${page.id}`, e);
    }
  }, [state, page.id]);

  // r, gba(233, 233, 233,1) = #E9E9E9
  // rgba(1, 151, 245,1) = #0197F5
  const bgColor = useMemo(() => {
    if (!(articleSteps[page.id]) || Object.keys(articleSteps[page.id]).length === 0) return "transparent";
    const progression = Math.floor(
      (Object.keys(articleSteps[page.id]).reduce(
        (done, key) => done + (articleSteps[page.id][key].completed ? 1 : 0),
        0
      ) /
        Object.keys(articleSteps[page.id]).length) *
        100
    );
    return `linear-gradient(90deg, rgba(1, 151, 245,1) ${progression}%, rgba(233, 233, 233,1) ${progression}%)`;
  }, [articleSteps, page.id]);

  return (
    <div className="bg-white flex flex-col">
      {page.steps?.length > 0 && (
        <div className="h-1.5" style={{ background: bgColor }}></div>
      )}
      <div className="flex flex-col p-8">
        {page.name && <div className="text-2xl font-semibold pb-2.5">{page.name}</div>}
        <NotionBlocks
          blocks={page.blocks}
          stepsProgression={articleSteps[page.id] ?? {}}
          onComplete={onComplete}
          pathBuilder={pathBuilder}
        />
      </div>
      {hasPdf && 
        <div className="px-8 pb-8">
          <a
            className="inline-flex bg-button text-white text-base font-extrabold px-4 py-2 gap-2 rounded-full"
            href={state + ".pdf"} 
            onClick={downloadHandle}
            alt="Download article"
            download>
              <img src="/download.svg" alt="download"/>
              <span>Download article</span></a>
        </div>
      }
    </div>
  );
};

export default Content;
