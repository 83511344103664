/*
options: {
  header: string;
  selected: boolean;
  content: () => React.ReactNode | undefined 
}
onChange: (name: string) => void;
*/
const TabBar = ({ options, onChange }) => {
  const selectedOption = options.find((o) => o.selected);
  return (
    <>
      <div className="bg-white flex w-full justify-center mb-0 pt-2">
        {options.map((option) => (
          <div
            key={option.header}
            className={`flex relative ${option.selected ? "active" : ""}`}
          >
            <div
              className={`rounded-top-2 text-base font-extrabold text-dark py-4 px-6 ${
                option.selected ? "bg-light" : ""
              } ${option.content ? "cursor-pointer" : ""}`}
              onClick={() =>
                option.content && onChange && onChange(option.header)
              }
            >
              {option.header}
            </div>
          </div>
        ))}
      </div>
      <div className="bg-light m-auto w-full max-w-card sm:max-w-3md xl:max-w-6xl grid-1-cols sm:grid-2-cols xl:grid-4-cols gap-6 my-10 md:my-20 xl:my-28">
        {selectedOption.content()}
      </div>
    </>
  );
};

export default TabBar;
