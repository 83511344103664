import React from "react";
import Layout from "../Layout";
import GoogleMap from "../components/Map/GoogleMap";
import { useHistory } from "react-router";
import RoundedDropdown from "../components/Reusable/RoundedDropdown";

const StateSelection = () => {
  const [dropdownOpen, toggleDropdown] = React.useState(false);
  const [activeOption, setActiveOption] = React.useState(-1);
  const history = useHistory();

  return (
    <div className="relative flex flex-col gap-0 md:gap-0 md:flex-row h-screen w-full m-auto items-center justify-between">
      <div className="relative w-full md:w-1/2 pr-0 md:pr-32 flex flex-col text-left self-start py-16 md:py-20 ml-10">
        <div className="mt-0 md:mt-12">
          <h1 className="font-bold text-[40px] tracking-tight leading-10">
            What state do you want the business registered in?
          </h1>
          <div className="relative mt-9 max-w-[562px]">
            <RoundedDropdown
              options={options}
              variant="bordered"
              selected={
                activeOption === -1 ? "Choose" : options[activeOption].value
              }
              onChange={(value) => {
                setActiveOption(
                  options.findIndex((option) => option.value === value)
                );
              }}
            />
          </div>
        </div>
      </div>
      <button
        onClick={() => history.push("/additional-info")}
        disabled={activeOption === -1}
        className={activeOption === -1 ? classes[1] : classes[0]}
      >
        Next &#8594;
      </button>
      <div className="w-full md:w-1/2 h-[240px] md:h-full mb-12 md:mb-0 flex items-center justify-center">
        <GoogleMap
          key={activeOption === -1 ? "default" : options[activeOption].value}
          useDefault={activeOption === -1}
          center={
            activeOption === -1
              ? { lat: 37.09024, lng: -95.712891 }
              : options[activeOption].center
          }
        />
      </div>
    </div>
  );
};

export default StateSelection;

const options = [
  { value: "alabama", center: { lat: 32.377716, lng: -86.300568 } },
  { value: "alaska", center: { lat: 58.301598, lng: -134.419734 } },
  { value: "arizona", center: { lat: 33.448143, lng: -112.096962 } },
  { value: "arkansas", center: { lat: 34.746613, lng: -92.288986 } },
  { value: "california", center: { lat: 38.575764, lng: -121.478851 } },
  { value: "colorado", center: { lat: 39.739227, lng: -104.984856 } },
  { value: "connecticut", center: { lat: 41.764046, lng: -72.682198 } },
  { value: "delaware", center: { lat: 39.157307, lng: -75.519722 } },
  { value: "florida", center: { lat: 30.438256, lng: -84.280733 } },
  { value: "georgia", center: { lat: 33.749027, lng: -84.388229 } },
  { value: "hawaii", center: { lat: 21.307442, lng: -157.857376 } },
  { value: "idaho", center: { lat: 43.617775, lng: -116.199722 } },
  { value: "illinois", center: { lat: 39.798363, lng: -89.654961 } },
  { value: "indiana", center: { lat: 39.768623, lng: -86.162643 } },
  { value: "iowa", center: { lat: 41.591087, lng: -93.603729 } },
  { value: "kansas", center: { lat: 39.048191, lng: -95.677956 } },
  { value: "kentucky", center: { lat: 38.186722, lng: -84.875374 } },
  { value: "louisiana", center: { lat: 30.457069, lng: -91.187393 } },
  { value: "maine", center: { lat: 44.307167, lng: -69.781693 } },
  { value: "maryland", center: { lat: 38.978764, lng: -76.490936 } },
  { value: "massachusetts", center: { lat: 42.358162, lng: -71.063698 } },
  { value: "michigan", center: { lat: 42.733635, lng: -84.555328 } },
  { value: "minnesota", center: { lat: 44.955097, lng: -93.102211 } },
  { value: "mississippi", center: { lat: 32.303848, lng: -90.182106 } },
  { value: "missouri", center: { lat: 38.579201, lng: -92.172935 } },
  { value: "montana", center: { lat: 46.585709, lng: -112.018417 } },
  { value: "nebraska", center: { lat: 40.808075, lng: -96.699654 } },
  { value: "nevada", center: { lat: 39.163914, lng: -119.766121 } },
  { value: "new hampshire", center: { lat: 43.206898, lng: -71.537994 } },
  { value: "new jersey", center: { lat: 40.220596, lng: -74.769913 } },
  { value: "new mexico", center: { lat: 35.68224, lng: -105.939728 } },
  { value: "new york", center: { lat: 42.652579, lng: -73.756232 } },
  { value: "north carolina", center: { lat: 35.78043, lng: -78.639099 } },
  { value: "north dakota", center: { lat: 46.82085, lng: -100.783318 } },
  { value: "ohio", center: { lat: 39.961346, lng: -82.999069 } },
  { value: "oklahoma", center: { lat: 35.492207, lng: -97.503342 } },
  { value: "oregon", center: { lat: 44.938461, lng: -123.030403 } },
  { value: "pennsylvania", center: { lat: 40.264378, lng: -76.883598 } },
  { value: "rhode island", center: { lat: 41.830914, lng: -71.414963 } },
  { value: "south carolina", center: { lat: 34.000343, lng: -81.033211 } },
  { value: "south dakota", center: { lat: 44.367031, lng: -100.346405 } },
  { value: "tennessee", center: { lat: 36.165810, lng: -86.784241 } },
  { value: "texas", center: { lat: 30.274665, lng: -97.74035 } },
  { value: "utah", center: { lat: 40.777477, lng: -111.888237 } },
  { value: "vermont", center: { lat: 44.262436, lng: -72.580536 } },
  { value: "virginia", center: { lat: 37.540725, lng: -77.436048 } },
  { value: "washington", center: { lat: 47.035805, lng: -122.905014 } },
  { value: "west virginia", center: { lat: 38.336246, lng: -81.612328 } },
  { value: "wisconsin", center: { lat: 43.074684, lng: -89.384445 } },
  { value: "wyoming", center: { lat: 41.140259, lng: -104.820236 } },
];

const classes = [
  "absolute bottom-10 md:bottom-20 text-left text-xl text-button hover:underline focus:outline-none ml-10",
  "absolute bottom-10 md:bottom-20 text-left text-xl text-[#a1a1a1] ml-10",
];
