/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "../../styles/button.css";
import MobileMenu from "./MobileMenu";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import ImageFilter from "react-image-filter";
import { Context } from "../../context/GlobalContext";
import { FacebookLoginClient } from "@greatsumini/react-facebook-login";
import { useCart } from "../../store";
import { FaTimes } from "react-icons/fa";
import cn from "classnames";

const Navbar = () => {
  const history = useHistory();
  const [menuOpen, toggleMenu] = React.useState(false);
  const [offset, setOffset] = React.useState(0);
  const context = React.useContext(Context);
  const { isFlowStarted, setFlowStarted, clearCart } = useCart();

  React.useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
    /* const getLoginInfo = async () => {
      const response = await axios.get("/test");
      console.log(response);
      response.data === 'Welcome to the backend!' ? setLoginDisplay("Log out") : setLoginDisplay("Log In");
    }
    getLoginInfo(); */
  }, []);

  const onSignOutGoogle = () => {
    document.cookie =
      "googleLogin=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;";
    console.log("Hit sign out");
    context.setLoginStatus("Log In");
  };

  const onSignOutFacebook = () => {
    FacebookLoginClient.logout(() => {
      context.setLoginStatus("Log In");
      document.cookie =
        "facebookLogin=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;";
      console.log("logout completed!");
    });
  };

  if (isFlowStarted) {
    return (
      <div
        className={cn(
          "h-[68px] md:h-[88px] flex justify-end items-center px-10",
          {
            "bg-light": !history.location.pathname.includes("contact-us"),
          }
        )}
      >
        <FaTimes
          size={30}
          cursor="pointer"
          onClick={() => {
            const confirm = window.confirm(
              "Are you sure you want to leave the flow? Your progress will be lost."
            );
            if (confirm) {
              setFlowStarted(false);
              clearCart();
              history.push("/");
            }
          }}
        />
      </div>
    );
  }

  return (
    <nav className={offset > 100 ? navbarClasses[0] : navbarClasses[1]}>
      <ul className="relative w-full mx-auto px-5 xl:px-0 md:max-w-7xl flex flex-row justify-between h-full items-center">
        <div className="h-full flex flex-row justify-start items-center font-bold gap-x-6 lg:gap-x-10">
          <li
            className="-ml-2 mr-2 mt-0.5 md:mt-2.5 cursor-pointer"
            onClick={() => history.push("/")}
          >
            <ImageFilter
              image="/blank-cut-logo.svg"
              alt="site-logo"
              filter={"duotone"} // see docs beneath
              colorOne={offset > 100 ? [0, 0, 0] : [255, 255, 255]}
              colorTwo={offset > 100 ? [0, 0, 0] : [255, 255, 255]}
              className="w-[90px] md:w-[120px]"
            />
          </li>
          {options.map((option, index) => (
            <Link
              key={option}
              to={links[index]}
              className={offset > 100 ? navbarItems[0] : navbarItems[1]}
            >
              {option}
            </Link>
          ))}
        </div>
        <div className="relative h-full flex flex-row justify-start items-center font-bold gap-x-6 lg:gap-x-9">
          <i
            className={
              menuOpen
                ? "fa fa-times fa-lg md:hidden"
                : "fa fa-bars fa-lg md:hidden "
            }
            onClick={() => toggleMenu(!menuOpen)}
            aria-hidden="true"
          />
          {!document.cookie.split("; ").includes("googleLogin=true") &&
          !document.cookie.split("; ").includes("facebookLogin=true") ? (
            <Link
              to="/login"
              className={offset > 100 ? LoginClasses[0] : LoginClasses[1]}
            >
              {context.loginStatus}
            </Link>
          ) : document.cookie.split("; ").includes("googleLogin=true") ? (
            <button
              className={offset > 100 ? LoginClasses[0] : LoginClasses[1]}
              onClick={onSignOutGoogle}
            >
              Sign Out
            </button>
          ) : (
            <button
              className={offset > 100 ? LoginClasses[0] : LoginClasses[1]}
              onClick={onSignOutFacebook}
            >
              Sign Out
            </button>
          )}
          <Link to="/signup" className="outer__button button__left-right">
            <div className="text">Sign up</div>
          </Link>
        </div>
      </ul>
      <MobileMenu menuOpen={menuOpen} options={options} links={links} />
    </nav>
  );
};

export default Navbar;

const options = [
  "Home",
  "Templates",
  "Search Domain",
  "Pricing",
  "Knowledge Base",
];
const links = ["/", "/templates", "/search", "/pricing-plan", "/knowledgebase"];

const navbarClasses = [
  "sticky bg-white text-black z-[1000] transform duration-500 top-0 left-0 mx-auto w-full flex-68px md:flex-88px h-[68px] md:h-[88px] self-center shadow",
  "sticky bg-black z-[1000] transform duration-500 top-0 left-0 mx-auto w-full flex-68px md:flex-88px h-[68px] md:h-[88px] self-center text-white",
];

const navbarItems = [
  "hidden text-black md:flex items-center h-full cursor-pointer pt-1 transition duration-300 hover:text-button",
  "hidden text-white md:flex items-center h-full cursor-pointer pt-1 transition duration-300 hover:text-button",
];

const LoginClasses = [
  "hidden text-black md:flex items-center h-full cursor-pointer pt-1 transition duration-300 hover:text-button",
  "hidden text-white md:flex items-center h-full cursor-pointer pt-1 transition duration-300 hover:text-button",
];
