import { useHistory } from "react-router-dom";

const PageLink = ({block, pathBuilder}) => {
  const history = useHistory();
  const clicked = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(pathBuilder(block.id));
  }
  
  return (
    <a
      href={pathBuilder(block.id)}
      className="cursor-pointer flex flex-row gap-2" 
      onClick={clicked} >
      <i className="fa fa-link mt-1"></i>
      {block.child_page.title}
    </a>
  );
}
  
export default PageLink;