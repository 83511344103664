import React from "react";
import Layout from "../Layout";

const BasicDeployment = () => {
  const [loading, setLoading] = React.useState(true);

  return (
    <Layout bgcolor="light">
      <div className="min-h-screen flex flex-col m-auto max-w-7xl items-center justify-center">
        <img
          src="/basic-deployment-illustration.svg"
          alt="deployment-illustration"
          width="420px"
        />
        <h1 className="font-bold text-4xl md:text-[40px] text-center mt-14 mb-2.5 leading-10">
          Sit back <span>&#38;</span> Relax...
        </h1>
        <h2 className="text-button text-center text-lg tracking-tight">
          While we set up everything for you
        </h2>
        {loading ? (
          <img
            width="70px"
            src="/loading-spinner.png"
            alt="spinner small"
            className="animate-spin-slow mt-7"
          ></img>
        ) : (
          <button type="button">Go to Dashboard</button>
        )}
      </div>
    </Layout>
  );
};

export default BasicDeployment;
