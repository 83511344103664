import { useState } from "react";
import { useHistory } from "react-router-dom";
import { findInTree, pathToItem } from "../../utils/arrayHelpers";

/*
tocPage: {
  name: string;
  id: string;
  subPages: tocPage[];
}
*/

const classesByLevel = {
  0: "text-base",
  1: "text-sm pl-7",
  2: "text-sm pl-10",
};

export const getBusinessStateUrl = (state) => (pageId) =>
  "/knowledgebase/business-entity/" + state + "/" + pageId;

export const TableOfContentElement = ({
  toc,
  level,
  currentPageId,
  expandedItems,
  onToggle,
  pathBuilder,
  onNavigate,
}) => {
  const history = useHistory();
  const clicked = (e) => {
    e.preventDefault();
    e.stopPropagation();
    level === 0 && onNavigate(toc.id);
    history.push(pathBuilder(toc.id));
  };

  const isParentOfCurrentPageId = findInTree(
    toc,
    (e) => e.id === currentPageId,
    (e) => e.subPages
  );

  return (
    <>
      <a
        href={pathBuilder(toc.id)}
        className={`p-4 cursor-pointer flex flex-row justify-between gap-3 items-center
        ${classesByLevel[level]} 
        border-b ${
          currentPageId === toc.id
            ? "bg-[#DEF2FF] border-button"
            : "border-gray"
        }
        ${isParentOfCurrentPageId ? "text-button font-medium" : ""}`}
        onClick={clicked}
      >
        {toc.name}
        {toc.subPages?.length > 0 && (
          <i
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onToggle(toc.id);
            }}
            className={`fa ${
              expandedItems.includes(toc.id)
                ? "fa-chevron-down"
                : "fa-chevron-up"
            } text-xs`}
          />
        )}
      </a>
      {expandedItems.includes(toc.id) &&
        toc.subPages.map((entry) => (
          <TableOfContentElement
            key={entry.id}
            toc={entry}
            level={level + 1}
            currentPageId={currentPageId}
            expandedItems={expandedItems}
            onToggle={onToggle}
            pathBuilder={pathBuilder}
            onNavigate={onNavigate}
          />
        ))}
    </>
  );
};

export const getPageIdsToCurrent = (toc, currentPageId) => {
  const items = pathToItem(
    { id: -1, subPages: toc },
    (e) => e.id === currentPageId,
    (e) => e.subPages,
    []
  );
  const ids = items.map((item) => item.id);
  return ids;
};

// tocPage[]
const TableOfContent = ({ toc, currentPageId, onNavigate, pathBuilder }) => {
  const [expandedItems, setExpandedItems] = useState(
    getPageIdsToCurrent(toc, currentPageId)
  );
  const onToggle = (tocId) => {
    const found = expandedItems.findIndex((i) => i === tocId);
    if (found >= 0) {
      const newState = [...expandedItems];
      newState.splice(found, 1);
      setExpandedItems(newState);
    } else {
      setExpandedItems(expandedItems.concat([tocId]));
    }
  };

  return (
    <div className="bg-white min-w-full">
      {toc.map((entry) => (
        <TableOfContentElement
          key={entry.id}
          toc={entry}
          level={0}
          currentPageId={currentPageId}
          expandedItems={expandedItems}
          onToggle={onToggle}
          pathBuilder={pathBuilder}
          onNavigate={onNavigate}
        />
      ))}
    </div>
  );
};

export default TableOfContent;
