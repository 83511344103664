import React from "react";

const RadioButton = ({ active }) => (
  <div
    className={`inlin-table rounded-full w-[20px] h-[20px] border-2 bg-transparent ${
      active
        ? "bg-black border-black ring-4 ring-inset ring-white"
        : "border-[#BFBFBF]"
    }`}
  />
);

export default RadioButton;
