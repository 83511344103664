import React from "react";
import { useHistory } from "react-router";
import { useCart } from "../../store";
import {
  regexExtension,
  regexQuery,
  defaultDomainPrice,
} from "../../utils/domainHelpers";
import { getPath } from "../../utils/getPath";

const SearchResults = () => {
  const { addToCart, setDomain, domain, type, setFlowStarted } = useCart();
  const history = useHistory();

  const handleDomainAdd = (domain, extension) => {
    const price =
      typeof extension === "object"
        ? parseInt(extension["Price"]) + parseInt(extension["Price"]) * 0.75
        : extension;

    addToCart({
      name: "Domain Registration",
      price,
      type,
    });
    setDomain({
      price,
      name: domain,
    });
    history.push(getPath("add-domain"));
    setFlowStarted(true);
  };

  const getExtension = (query) => {
    if (query.length > 0) {
      let extension = query.match(regexExtension)[0];
      return extension;
    } else {
      return;
    }
  };
  const getSitename = (query) => {
    if (query.length > 0) {
      let sitename = query.match(regexQuery)[0];
      sitename = sitename.split(".")[0];
      return sitename;
    } else {
      return;
    }
  };

  return (
    <div className="bg-white w-screen">
      <div className="m-auto px-5 md:px-14 py-20 md:py-20 max-w-7xl flex flex-col items-start justify-between">
        {domain.isAvailable ? (
          <p className="text-xl text-dark">Your Domain is available</p>
        ) : null}
        {domain.isAvailable === false ? (
          <p className="text-xl text-dark">Your Domain is not available</p>
        ) : null}
        <div className="flex flex-col md:flex-row gap-8 md:gap-0 w-full justify-between mt-1">
          <h1 className="text-4xl md:text-6xl inline-block text-dark tracking-tighter font-black">
            www.{getSitename(domain.searchQuery)}
            <h1 className="text-button inline-block">
              {getExtension(domain.searchQuery)}
            </h1>
          </h1>
          {domain.isAvailable && (
            <button
              onClick={() =>
                handleDomainAdd(domain.searchQuery, defaultDomainPrice)
              }
              className="h-[60px] px-8 pt-px rounded-full bg-dark text-white font-bold transform duration-300 hover:bg-[#11344a] focus:outline-none"
            >
              {defaultDomainPrice}/year - Choose this domain
            </button>
          )}
        </div>

        {domain.isAvailable === false && domain.suggestionList.length > 0 ? (
          <ul className="flex flex-col w-full my-16 md:my-28 border rounded-xl border-button">
            {domain.suggestionList[0].map((extension, index) => (
              <li
                key={extension["DomainName"]}
                className={`flex flex-row p-4 md:p-12 gap-4 md:gap-0 justify-between items-center ${
                  index < domain.suggestionList[0].length - 1
                    ? "border-b border-button"
                    : "border-b-0"
                }`}
              >
                <p className="font-bold text-lg md:text-2xl text-dark inline-block">
                  www.{getSitename(extension["DomainName"])}
                  <span className="inline-block text-button">
                    {getExtension(extension["DomainName"])}
                  </span>
                </p>
                <button
                  type="button"
                  className="h-auto md:h-[58px] max-w-[45%] md:max-w-max py-1 md:py-0 px-4 md:px-8 text-button rounded-full border border-button transform duration-300 hover:text-white hover:bg-button focus:outline-none"
                  onClick={() =>
                    handleDomainAdd(extension["DomainName"], extension)
                  }
                >
                  {parseInt(extension["Price"]) +
                    parseInt(extension["Price"]) * 0.75}
                  /year - Add to Cart
                </button>
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    </div>
  );
};

export default SearchResults;
