import { useState } from "react";
import FaqElement from "./FaqElement";

const Faq = ({blocks}) => {
  const [faqCollapsed, setFaqCollapsded] = useState(false);
  const [expandedItem, setExpandedItem] = useState(-1);

  const toggle = (index) => {
    if (expandedItem === index) setExpandedItem(-1);
    else setExpandedItem(index);
  };

  return (
    <div className="bg-white min-w-full">
      <div className="p-4 flex flex-row justify-between" onClick={() => setFaqCollapsded(!faqCollapsed)}>
        {blocks.at(0)?.content?.heading_1?.rich_text?.at(0)?.text?.content}        
        <i className={`fa ${faqCollapsed ? "fa-chevron-up" : "fa-chevron-down"} text-xs mt-1`} />
      </div>
      {!faqCollapsed && blocks.slice(1).map((entry, index) => (
        <FaqElement
          key={entry.content.id}
          entry={entry}
          isExpanded={expandedItem === index}
          onToggle={() => toggle(index)}
        />
      ))}
    </div>);
}

export default Faq;