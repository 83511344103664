import "../node_modules/font-awesome/css/font-awesome.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  
    <App />
  
);
