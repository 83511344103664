import React from "react";
import QuestionScreen from "../components/Reusable/QuestionScreen";
import { getPath } from "../utils/getPath";

const question =
  "Do you want us to take care of your company's registration process?";
const options = [
  {
    label: "Yes, I am in",
    value: true,
  },
  {
    label: "Not for now",
    value: false,
  },
];

export const Registration = () => {
  const getLink = async () => {
    return await getPath("company-registration");
  };

  return (
    <QuestionScreen
      pageName="registration"
      question={question}
      options={options}
      link={getLink}
    />
  );
};
