import React from "react";
import { useHistory } from "react-router";

const Templates = () => {
  const history = useHistory();

  return (
    <div className="overflow  -hidden flex flex-col items-center pt-10 md:pt-28 px-5 md:px-0 pb-8 md:pb-12">
      <p className="relative text-3xl md:text-4xl mb-5 tracking-[-1px]">
        Select From Our Growing Collection of Templates
        <img
          src="/right-drawn-arrow-dark.svg"
          alt="arrow-illustration"
          className="hidden md:absolute top-8 right-[-80px]"
          width="130px"
        />
      </p>
      <p className="relative font-extralight opacity-[0.7] text-left md:text-center w-auto md:w-1/3">
        Explore a world of possibilities with our carefully crafted templates.
      </p>
      <img
        src="/templates-illustration.svg"
        alt="templates-illustration"
        className="min-w-[1000px] md:min-w-auto"
      />
      <button
        onClick={() => history.push("/templates")}
        className="bg-button rounded-full pl-10 pr-10 my-6 md:my-10 h-[52px] text-white text-[16px] font-black transition duration-300 hover:bg-[#177fc0] focus:outline-none"
      >
        Discover More
      </button>
    </div>
  );
};

export default Templates;
