import axios from "axios";
import AuthLayout from "./AuthLayout";
import React from "react";
import { useEffect } from "react";

const DetailsForm = ({ setStepnumber, appendToFormObject }) => {
  const [phone, setPhone] = React.useState(""); // Use state to handle the phone input value

  const sendPhoneForVerification = async () => {
    const phoneVerificationConfig = {
      method: "post",
      url: "/verifyPhone",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        phone: phone,
        channel: "sms",
      },
    };

    try {
      const response = await axios(phoneVerificationConfig);

      if (response.status === 200) {
        // Move to the next step or provide any feedback to the user
        setStepnumber(2);
        appendToFormObject(document.querySelector("form"));
      } else {
        // Handle the error gracefully and inform the user about the failure
        console.error("Error verifying phone:", response.data);
      }
    } catch (error) {
      // Handle the network error gracefully and inform the user about the failure
      console.error("Network error:", error);
    }
  };

  const loadScript = (src) =>
    new Promise((resolve, reject) => {
      if (document.querySelector(`script[src="${src}"]`)) return resolve();
      const link = document.createElement("link");
      const script = document.createElement("script");
      script.src = src;
      link.rel = "stylesheet";
      link.href =
        "https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/css/intlTelInput.css";
      script.onload = () => resolve();
      script.onerror = (err) => reject(err);
      document.head.appendChild(link);
      document.body.appendChild(script);
    });

  useEffect(() => {
    loadScript(
      "https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/intlTelInput.min.js"
    ).then(() => {
      let input = document.querySelector("#phone");
      window.intlTelInput(input, {
        utilsScript:
          "https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/utils.js",
        autoInsertDialCode: true,
        nationalMode: false,
      });
    });
  }, []);

  return (
    <AuthLayout>
      <form className="relative h-full w-full max-w-[90%] md:w-1/2 md:max-w-xl mt-8 md:-mt-16 flex flex-col justify-start md:justify-center">
        <h2 className="text-3xl md:text-4xl mb-4 md:mb-10">
          Please fill in the details
        </h2>
        <input
          name="firstName"
          type="text"
          placeholder="First Name"
          defaultValue=""
          className="w-full h-11 my-1.5 md:my-2 px-6 pt-1 rounded focus:outline-none"
        />
        <input
          name="lastName"
          type="text"
          placeholder="Last Name"
          defaultValue=""
          className="w-full h-11 my-1.5 md:my-2 px-6 pt-1 rounded focus:outline-none"
        />
        <input
          name="password"
          type="password"
          placeholder="Password"
          defaultValue=""
          className="w-full h-11 my-1.5 md:my-2 px-6 pt-1 rounded focus:outline-none"
        />
        <input
          name="confirmPassword"
          type="password"
          placeholder="Confirm Password"
          defaultValue=""
          className="w-full h-11 my-1.5 md:my-2 px-6 pt-1 rounded focus:outline-none"
        />
        <input
          name="username"
          type="text"
          placeholder="Username"
          defaultValue=""
          className="w-full h-11 my-1.5 md:my-2 px-6 pt-1 rounded focus:outline-none"
        />
        <input
          id="phone"
          name="phone"
          type="text"
          placeholder="Phone"
          value={phone}
          onChange={(e) => {
            const inputValue = e.target.value;

            // If the user is trying to delete the + sign, reset it to just the + sign
            if (inputValue === "") {
              setPhone("+");
              return;
            }

            // If the value doesn't start with a +, prepend it
            if (!inputValue.startsWith("+")) {
              setPhone("+" + inputValue);
            } else {
              setPhone(inputValue);
            }
          }}
          className="w-full h-11 my-1.5 md:my-2 px-6 pt-1 rounded focus:outline-none"
          autocomplete="off" // This attribute disables auto-fill
        />

        <button
          type="button"
          onClick={sendPhoneForVerification}
          className="bg-[#0197F5] w-full h-11 mt-2 md:mt-2 text-white font-bold rounded hover:bg-[#177fc0] transition duration-300 focus:outline-none"
        >
          Verify Phone
        </button>
      </form>
    </AuthLayout>
  );
};

export default DetailsForm;
