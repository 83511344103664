import React from "react";
import Navbar from "../components/Reusable/Navbar";
import Footer from "../components/Reusable/Footer";
import { Link, useHistory } from "react-router-dom";
import { useCart } from "../store";
import { FiSearch } from "react-icons/fi";

const team = [
  {
    name: "Black, Marvin",
    avatar: "/images/about/team-1.png",
    position: "Investor in Pinterest, Stripe, Hackpad, Paper/FiftyThree.",
  },
  {
    name: "Nguyen, Shane",
    avatar: "/images/about/team-2.png",
    position: "Investor in Pinterest, Stripe, Hackpad, Paper/FiftyThree.",
  },
  {
    name: "Cooper, Kristin",
    avatar: "/images/about/team-3.png",
    position: "Investor in Pinterest, Stripe, Hackpad, Paper/FiftyThree.",
  },
  {
    name: "Henry, Arthur",
    avatar: "/images/about/team-4.png",
    position: "Investor in Pinterest, Stripe, Hackpad, Paper/FiftyThree.",
  },
  {
    name: "Henry, Arthur",
    avatar: "/images/about/team-5.png",
    position: "Investor in Pinterest, Stripe, Hackpad, Paper/FiftyThree.",
  },
  {
    name: "Black, Marvin",
    avatar: "/images/about/team-6.png",
    position: "Investor in Pinterest, Stripe, Hackpad, Paper/FiftyThree.",
  },
  {
    name: "Henry, Arthur",
    avatar: "/images/about/team-7.png",
    position: "Investor in Pinterest, Stripe, Hackpad, Paper/FiftyThree.",
  },
  {
    name: "Miles, Esther",
    avatar: "/images/about/team-8.png",
    position: "Investor in Pinterest, Stripe, Hackpad, Paper/FiftyThree.",
  },
  {
    name: "Cooper, Kristin",
    avatar: "/images/about/team-9.png",
    position: "Investor in Pinterest, Stripe, Hackpad, Paper/FiftyThree.",
  },
  {
    name: "Nguyen, Shane",
    avatar: "/images/about/team-10.png",
    position: "Investor in Pinterest, Stripe, Hackpad, Paper/FiftyThree.",
  },
  {
    name: "Flores, Juanita",
    avatar: "/images/about/team-11.png",
    position: "Investor in Pinterest, Stripe, Hackpad, Paper/FiftyThree.",
  },
  {
    name: "Miles, Esther",
    avatar: "/images/about/team-12.png",
    position: "Investor in Pinterest, Stripe, Hackpad, Paper/FiftyThree.",
  },
];

const About = () => {
  const history = useHistory();
  const [query, setQuery] = React.useState("");
  const handleChange = (event) => {
    event.preventDefault();
    let changeEventQuery = event.target.value;
    changeEventQuery = changeEventQuery.replace("www.", "");
    setQuery(changeEventQuery);
  };
  const { setDomain } = useCart();
  const handleSearch = () => {
    setDomain({
      searchQuery: query,
      outsideSearch: true,
    });
    history.push("/search");
  };

  return (
    <>
      <Navbar />

      <div class="px-4 md:px-6 mx-auto bg-light">
        <div class="pt-10 lg:pt-20">
          <div class="text-center mb-7 lg:mb-10">
            <p class="mb-2 lg:mb-[17px] text-base lg:text-1xl text-[#00000066]">
              About Blank Cut
            </p>
            <h1 class="font-black text-2xl text-[#121212] md:text-4xl lg:text-4xl">
              A Story of Blank Cut
            </h1>
            <p class="text-sm mt-2 lg:mt-[17px] lg:text-lg max-w-[750px] mx-auto text-[#050505]">
              To provide a comprehensive set of tools, knowledge, and support
              that makes owning a successful business an accessible goal for
              everyone.
            </p>
          </div>
        </div>
        <div>
          <div class="space-y-20">
            <div class="mt-8 md:mt-14 flex justify-center items-center flex-col-reverse md:flex-row md:gap-5 lg:gap-[50px]">
              <div class="bg-white rounded-[14px] flex shadow-lg justify-center md:h-[300px] lg:h-full mx-auto md:mx-0">
                <img
                  class="p-5 lg:px-8 lg:py-[60px] 2xl:px-[46px] 2xl:py-[109px]"
                  src="/images/about/about-1.png"
                  alt=""
                />
              </div>
              <div class="text-sm lg:text-lg w-full max-w-[639px] mx-auto md:mx-0 mb-5 lg:mb-0 ">
                <h5 class="text-2xl 2xl:text-[32px] font-bold">
                  Core Customers
                </h5>
                <p class="mt-4 2xl:text-lg">
                  Blank Cut aims to serve a diverse range of businesses, with a particular focus on creators and new entrepreneurs. This includes social media influencers and bloggers looking to elevate their brand, artists and craftsmen promoting their work or events, writers and researchers seeking a wider audience, service providers such as photographers and educators aiming for promotion and legal benefits, and physical store owners venturing into e-commerce.
                </p>
              </div>
            </div>
            <div class="mt-8 md:mt-14 flex justify-center items-center flex-col-reverse  md:flex-row-reverse md:gap-5 lg:gap-[50px]">
              <div class="bg-white rounded-[14px] flex shadow-lg justify-center md:h-[300px] lg:h-full items-center mx-auto md:mx-0">
                <img
                  class="p-5 lg:px-8 lg:py-[60px] 2xl:px-[46px] 2xl:py-[109px]"
                  src="/images/about/about-2.png"
                  alt=""
                />
              </div>
              <div class="text-sm lg:text-lg w-full max-w-[639px] mx-auto md:mx-0 mb-5 lg:mb-0">
                <h5 class="text-2xl 2xl:text-[32px] font-bold">The Problem</h5>
                <p class="mt-4 2xl:text-lg">
                  Starting and running a business requires a lot of hard work,
                  time, and specialized knowledge. There are many online
                  platforms that can help with services like website creation,
                  customer relation management, bookkeeping, or entity
                  formation, but there is no single platform on the market that
                  offers them all. Business owners try to save time and money by
                  outsourcing these tasks, but with each new vendor comes
                  another monthly fee, another password to remember, and another
                  new interface to learn.
                </p>
              </div>
            </div>
            <div class="mt-8 md:mt-14 flex justify-center items-center flex-col-reverse md:flex-row md:gap-5 lg:gap-[50px]">
              <div class="bg-white rounded-[14px] flex shadow-lg justify-center md:h-[300px] lg:h-full items-center mx-auto md:mx-0">
                <img
                  class="p-5 lg:px-8 lg:py-[60px] 2xl:px-[46px] 2xl:py-[109px]"
                  src="/images/about/about-3.png"
                  alt=""
                />
              </div>
              <div class="text-sm lg:text-lg w-full max-w-[639px] mx-auto md:mx-0 mb-5 lg:mb-0">
                <h5 class="text-2xl 2xl:text-[32px] font-bold">The Solution</h5>
                <p class="mt-4 2xl:text-lg">
                  Blank Cut is a unique online platform providing both website creation and business formation services. In just minutes, you can launch a website and begin business registration with our step-by-step guides. Whether you use our comprehensive free resources to register yourself or let us handle it, we're here to support you. As your business expands, Blank Cut evolves with you, offering features like e-commerce, customer management, bookkeeping, and more on our user-friendly platform.
                </p>
              </div>
            </div>
            <div class="mt-8 md:mt-14 flex justify-center items-center flex-col-reverse md:flex-row-reverse md:gap-5 lg:gap-[50px] lg:mb-[94px]">
              <div class="bg-white rounded-[14px] flex shadow-lg justify-center md:h-[300px] lg:h-full items-center mx-auto md:mx-0">
                <img
                  class="p-5 lg:px-8 lg:py-[60px] 2xl:px-[46px] 2xl:py-[109px]"
                  src="/images/about/about-4.png"
                  alt=""
                />
              </div>
              <div class="text-sm lg:text-lg w-full max-w-[639px] mx-auto md:mx-0 mb-5 lg:mb-0">
                <h5 class="text-2xl 2xl:text-[32px] font-bold">Day Zero Value</h5>
                <p class="mt-4 2xl:text-lg">
                  The best time to start a new business is right now. Blank Cut
                  offers Day Zero value. In just a few minutes, you can:
                </p>
                <ul class="text-md 2xl:text-lg list-disc list-inside m-0 mt-4 ml-3 lg:ml-3 w-full max-w-[639px] mb-1 md:mb-0">
                  <li class="">
                    Build and launch a website using one of our hundreds of
                    professionally designed and customizable templates.
                  </li>
                  <li>File documents to officially register your business.</li>
                  <li>Capture real-time analytics about your site.</li>
                  <li>
                    Add new content or change your site’s appearance with our
                    easy-to-use management system.
                  </li>
                  <li>Give your customers a way to engage with you online.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="mt-20 lg:mb-[30px] lg:mt-[124px]">
            <div class="text-center">
              <h4 class="text-[#111827] font-bold text-md">
                Keeping teams of tens and thousands connected.
              </h4>
            </div>
            <div class="mt-[21px] flex flex-col items-center gap-12 sm:gap-10 md:flex-row md:justify-around mx-auto max-w-[700px] ">
              <div>
                <img src="/images/about/pixar.png" alt="" />
              </div>
              <div>
                <img src="/images/about/curology.png" alt="" />
              </div>
              <div>
                <img src="/images/about/loom.png" alt="" />
              </div>
              <div>
                <img src="/images/about/match-v2.png" alt="" />
              </div>
              <div>
                <img src="/images/about/headspace.png" alt="" />
              </div>
            </div>
          </div>
          <div class="mt-20 lg:mt-[142px]">
            <div class="text-center">
              <h5 class="font-black text-2xl lg:text-[40px] text-[#121212] lg:text-4xl mb-4">
                Meet The Team
              </h5>
              <p class="text-sm md:text-base text-[#050505] max-w-[940px] mx-auto">
                Being remote from day one, we combined a unique experience under
                one roof: engineers who created open source frameworks and won
                numerous data science competitions, serial entrepreneurs and one
                of the best designers in the field - that’s what defines our
                company.
              </p>
            </div>
            <div class="grid grid-cols-1 sm:grid-cols-2 mt-[55px] mb-8 md:grid-cols-3 gap-x-6 gap-y-8 max-w-[940px] mx-auto">
              {team.map((item, i) => (
                <div
                  key={i}
                  class="flex justify-center items-center flex-col space-y-2.5"
                >
                  <div class="">
                    <img
                      class="rounded-3xl shadow-md"
                      src={item.avatar}
                      alt={item.name}
                    />
                  </div>
                  <h6 class="text-lg text-[#050505] font-bold">{item.name}</h6>
                  <p class="text-[#00000066] text-[15px] max-w-[250px] text-center">
                    {item.position}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div class="pt-[142px] pb-[104px]">
            <div class="text-center">
              <h2 class="font-black text-2xl lg:text-[40px] text-[#121212] lg:text-4xl pb-4">
                In The News
              </h2>
              <p class="text-sm md:text-base text-[#050505] max-w-[940px] mx-auto">
                Just a few of the stories about Notion in the tech and business
                press.
              </p>
            </div>
            <div class="mt-10 md:mt-[55px] flex flex-col justify-center items-center sm:grid sm:grid-cols-2 lg:grid-cols-3 gap-5 max-w-[940px] mx-auto">
              <div class="bg-[#F6F5F4] px-[30px] rounded-l-md mx-auto w-full max-w-[300px] p-10 lg:py-0 lg:h-[168px] flex justify-center items-center">
                <img src="/images/about/news-1.png" alt="" />
              </div>
              <div class="bg-[#F6F5F4] px-[30px] rounded-l-md mx-auto w-full max-w-[300px] p-10 lg:py-0 lg:h-[168px] flex justify-center items-center">
                <img src="/images/about/news-2.png" alt="" />
              </div>
              <div class="bg-[#F6F5F4] px-[30px] rounded-l-md mx-auto w-full max-w-[300px] p-10 lg:py-0 lg:h-[168px] flex justify-center items-center">
                <img src="/images/about/news-3.png" alt="" />
              </div>
              <div class="bg-[#F6F5F4] px-[30px] rounded-l-md mx-auto w-full max-w-[300px] p-10 lg:py-0 lg:h-[168px] flex justify-center items-center">
                <img src="/images/about/news-4.png" alt="" />
              </div>
              <div class="bg-[#F6F5F4] px-[30px] rounded-l-md mx-auto w-full max-w-[300px] p-10 lg:py-0 lg:h-[168px] flex justify-center items-center">
                <img src="/images/about/news-5.png" alt="" />
              </div>
              <div class="bg-[#F6F5F4] px-[30px] rounded-l-md mx-auto w-full max-w-[300px] p-10 lg:py-0 lg:h-[168px] flex justify-center items-center">
                <img src="/images/about/news-6.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-dark w-full py-[112px] mx-auto px-3 flex justify-center items-center flex-col h-[384px]">
        <div class="relative max-w-[352px] sm:max-w-full">
          <h2 class="text-2xl lg:text-[40px] font-semibold text-white text-center">
            Start your journey. Search for your domain.
          </h2>
          <div class="">
            <img
              class="absolute top-5 -right-5 sm:top-7  md:-right-11 md:top-3 w-20 lg:w-[114px] lg:top-3"
              src="/icons/right-drawn-arrow.svg"
              alt=""
            />
          </div>
        </div>
        <div class="mt-10 flex-col">
          <form class="relative mx-auto">
            <input
              class="py-3 px-9 lg:py-5 rounded-full w-[260px] sm:w-[350px] md:w-[440px] lg:w-[586px]"
              placeholder="Search"
              type="text"
              onChange={handleChange}
            />
            <button
              class="absolute top-1/2 -translate-y-1/2 right-1 lg:right-2 flex justify-center items-center bg-button py-[5px] lg:py-3 rounded-full w-[70px] lg:w-[96px]"
              onClick={handleSearch}
            >
              <FiSearch color="#fff" size={30} />
            </button>
          </form>
          <div class="flex justify-center mt-[27px]">
            <Link
              class="text-white  cursor-pointer text-sm md:text-base"
              to="/search"
            >
              Have your own domain?
            </Link>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default About;
