import React from "react";
import Layout from "../Layout";
import SignupForm from "../components/Authentication/SignupForm";
import DetailsForm from "../components/Authentication/DetailsForm";
import VerificationForm from "../components/Authentication/VerificationForm";
import SuccessForm from "../components/Authentication/SuccessForm";
import axios from "axios";

const Signup = () => {
  const [stepsNumber, setStepnumber] = React.useState(0);
  const [formObject, setFormObject] = React.useState({});

  /*
    Append user inputs from form components from BlankCut registration into the 'formObject' state variable
   */
  const appendToFormObject = (form) => {
    //create formObject which contains the form from user inputs
    var formData = new FormData(form);
    
    //append inputs to formObject state variable
    for(const [key,value] of formData){
      formObject[`${key}`] = value;
    }
  }

  const appendInputToFormObject = (inputName, inputResponse) => {
    formObject[`${inputName}`] = inputResponse;
  }

  async function sendFormObject() {
    console.log("I'm at sendFormObject")
    const backendSignupConfig = {
        method: 'post',
        url: '/auth/signup',
        headers: {
            'Content-Type': 'application/json'
        },
        data: JSON.stringify({
            email: formObject.email,
            password: formObject.password,
            username: formObject.username,
            phone: formObject.phone
        })
    }

    try {
        const response = await axios(backendSignupConfig);

        // Assuming your server returns a status of 200 for successful signups.
        if (response.status === 200) {
            return true;
        } else {
            // You can handle specific errors based on the response from the server here.
            console.error('Error signing up:', response.data.message);
            return false;
        }
    } catch (error) {
        console.error('Network error:', error);
        return false;
    }
  }

  const selectForm = () => {
    switch (stepsNumber) {
      case 0:
        return <SignupForm appendInputToFormObject = {appendInputToFormObject} setStepnumber={setStepnumber} />;
      case 1:
        return <DetailsForm appendToFormObject = {appendToFormObject} setStepnumber = {setStepnumber} formObject = {formObject}/>;
      case 2:
        return <VerificationForm sendFormObject = {sendFormObject} setStepnumber = {setStepnumber} formObject = {formObject}/>;
      case 3:
        return <SuccessForm appendToFormObject = {appendToFormObject} />;
      default:
        return <SignupForm setStepnumber = {setStepnumber} />;
    }
  };

  return <Layout bgcolor="#ECF4FF">{selectForm()}</Layout>;
};

export default Signup;
