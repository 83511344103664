import Layout from "../Layout";
import Navbar from "../components/Reusable/Navbar";
import QuestionScreen from "../components/Reusable/QuestionScreen";
import { getPath } from "../utils/getPath";

const question = "Do you want a trademark created?";
const options = [
  {
    label: "Yes, I am in",
    value: true,
  },
  {
    label: "Not for now",
    value: false,
  },
];
const getLink = async () => await getPath("trademark");

const Trademark = () => (
  <div className="flex flex-col">
    <Navbar />
    <div>
      <Layout>
        <QuestionScreen
          pageName="trademark"
          question={question}
          options={options}
          link={getLink}
        />
      </Layout>
    </div>
  </div>
);

export default Trademark;
