import { Popover } from "@headlessui/react";
import React from "react";

const Tooltip = ({ children, text }) => {
  return (
    <Popover className="relative">
      <Popover.Button>{children}</Popover.Button>

      <Popover.Panel className="absolute z-10">
        <div className="bg-white shadow-lg rounded-lg p-4 w-96">
          <p className="text-sm text-gray-500">{text}</p>
        </div>
      </Popover.Panel>
    </Popover>
  );
};

export default Tooltip;
