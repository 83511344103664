import React from "react";

const SitesBlock = ({ sites }) => {
  return (
    <div className="flex items-center justify-between my-3 flex-wrap gap-5 lg:gap-0 px-3 lg:px-10">
      <div className="flex items-center space-x-2 flex-wrap">
        <p className="text-[#00204F] font-extrabold">Your Sites</p>
        <p className="text-[#9A9A9A] text-[13px] font-extrabold">
          {sites.filter((site) => site.status === "Published").length}/
          {sites.length} sites Publised
        </p>
        <input
          className="border p-1 border-[#EAEAEA] py-2 pl-4 pr-[120px] w-full max-w-[245px]"
          type="text"
          placeholder="Type  to search"
        />
      </div>
      <div className="md:space-x-6 flex flex-wrap gap-2 items-center lg:block">
        <button className="px-5 py-3 bg-[#FAFAFA] text-dark rounded-[4px] font-extrabold border border-[#EAEAEA]">
          Create Empty Site
        </button>
        <button className="px-5 py-3 bg-button text-white rounded-[4px] font-extrabold">
          Get from template
        </button>
      </div>
    </div>
  );
};

export default SitesBlock;
