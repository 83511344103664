import React from "react";
import GoogleMapReact from "google-map-react";

const LocationMarker = () => (
  <img src="/location-marker.svg" alt="location-marker" />
);

const mapStyles = [
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#e9e9e9" }, { lightness: 17 }],
  },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [{ color: "#f5f5f5" }, { lightness: 20 }],
  },
];

const SimpleMap = ({ useDefault, center }) => {
  // Define map options to disable zoom controls and scroll wheel zooming
  const createMapOptions = (maps) => {
    return {
      zoomControl: false,
      scrollwheel: false,
      disableDoubleClickZoom: true,
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
      styles: mapStyles,
    };
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyAdA5cSZkv3e8m9070ZClX1ihtIKBT4j5o" }}
        defaultCenter={center}
        defaultZoom={useDefault ? 4 : 6} // Adjust the zoom level here
        options={createMapOptions}
      >
        <LocationMarker lat={center.lat} lng={center.lng} />
      </GoogleMapReact>
    </div>
  );
};

export default SimpleMap;
