import React, { useState } from "react";
import FaqElement from "./FaqElement";

const MobileFaq = ({ blocks }) => {
    const [expandedItem, setExpandedItem] = useState(-1);
  
    const toggle = (index) => {
      if (expandedItem === index) setExpandedItem(-1);
      else setExpandedItem(index);
    };
  
  return (
    <div
      className="overflow-auto flex flex-col justify-center w-full px-4"
    >
      {blocks.slice(1).map((entry, index) => (
      <FaqElement
        key={entry.content.id}
        entry={entry}
        isExpanded={expandedItem === index}
        onToggle={() => toggle(index)}
      />
    ))}
    </div>
  );
};

export default MobileFaq;
