import React, { useState } from "react";
import Layout from "../Layout";
import Navbar from "../components/Reusable/Navbar";
import PricingDetailsCard from "../components/Pricing/PricingDetailsCard";
import ActivePlanCard from "../components/Pricing/ActivePlanCard";
import { useCart } from "../store";

const PricingDetails = () => {
  const { plan } = useCart();
  const [loading, setLoading] = useState(false);

  const handleCheckout = async () => {
    setLoading(true);
    const stripe = window.Stripe("pk_test_51MDYOlAgvXD9aUOwIGzjsphGNAuYVDuVNEJs4F1B0qQ5de67EoOtpxnu22pU7gScljNfCwd1n46tK8QrsCQ15NJB005IPLFafJ");

    // Adjust as needed if we have multiple items.
    const items = [
      { type: "subscription", id: "price_1MO4jgAgvXD9aUOw0tNUwBrF" },
      { type: "one-time", id: "price_1MO4f1AgvXD9aUOwlu06cu3y" }
    ];
    
    // Make a call to the backend to create the checkout session
    const response = await fetch("/payments/create-mixed-checkout-session", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ items: items }),
    });

    // Check for potential errors from the backend
    if (!response.ok) {
        const errorData = await response.json();
        console.error("Backend error:", errorData);
        setLoading(false);
        return; // Stop further execution
    }

    const { sessionId } = await response.json();

    // Redirect the user to Stripe Checkout
    const { error } = await stripe.redirectToCheckout({ sessionId });

    if (error) {
      console.error("Failed to redirect to Stripe:", error.message);
    }
    setLoading(false);
  };


  return (
    <div className="flex flex-col">
      <Navbar />
      <Layout bgcolor="light">
        <div className="min-h-screen max-w-screen box-border md:mx-0 py-16 flex flex-col justify-center">
          <h1 className="text-3xl md:text-4xl text-center font-black mb-10 tracking-tighter">
            Your new pricing details
          </h1>
          <div className="flex flex-col md:flex-row mx-auto gap-7">
            <PricingDetailsCard />
            {Object.keys(plan).length > 0 && (
              <ActivePlanCard features={plan.features} price={plan.price} />
            )}
            <button
              onClick={handleCheckout}
              className="bg-blue-500 text-white px-4 py-2 rounded mt-4"
              disabled={loading}
            >
              {loading ? "Processing..." : "Proceed to Pay"}
            </button>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default PricingDetails;
