import React from "react";
import Layout from "../Layout";
import { useHistory } from "react-router";
import { getPath } from "../utils/getPath";

const InfoCard = ({
  progress,
  totalQuestions,
  question,
  answers,
  setProgress,
}) => {
  const [active, setActive] = React.useState(0);
  const history = useHistory();

  return (
    <div className="flex flex-col bg-white p-10 pt-12 pb-10 shadow-xl">
      <div className="w-full bg-[#EEF6FC] rounded-full h-2">
        <div
          className="bg-button rounded-full h-2"
          style={{
            width: `${((progress + 1) / totalQuestions) * 100}%`,
            transition: "0.5s",
          }}
        />
      </div>
      <p className="text-left text-sm text-button font-light mt-14 mb-3.5">
        Question {progress + 1} of {totalQuestions}
      </p>
      <h2 className="text-dark text-left text-[24px] font-extrabold tracking-tight">
        {question}
      </h2>
      <div className="mt-8 mb-12">
        {answers.map((answer, index) => (
          <button
            key={answer}
            type="button"
            onClick={() => setActive(index)}
            className={`w-full h-[54px] mt-2 pl-6 pr-2 flex flex-row items-center justify-between text-xl rounded-full focus:outline-none ${
              active === index
                ? "text-white bg-button"
                : "text-button bg-[#EEF6FC] shadow"
            }`}
          >
            <p className="text-base pt-px">{answer}</p>
            {active === index ? (
              <img src="green-check.svg" alt="check-icon" width="40px" />
            ) : null}
          </button>
        ))}
      </div>
      <button
        className="w-max mx-auto text-dark hover:underline focus:outline-none"
        onClick={() => {
          setProgress(progress + 1);
          if (progress === cardsData.length - 1)
            history.push(getPath("afterBusinessRegistration"));
        }}
      >
        Next &#8594;
      </button>
    </div>
  );
};

const TrademarkInfo = () => {
  const [progress, setProgress] = React.useState(0);
  const history = useHistory();

  return (
    <Layout bgcolor="light">
      <div className="m-auto my-20 w-full max-w-[420px] flex flex-col items-center justify-center">
        <h1 className="font-bold text-3xl md:text-4xl text-center mb-8 w-full mx-auto">
          Please provide the following information
        </h1>
        {progress < cardsData.length ? (
          <InfoCard
            progress={progress}
            totalQuestions={cardsData.length}
            question={cardsData[progress].question}
            answers={cardsData[progress].answers}
            setProgress={setProgress}
          />
        ) : null}
      </div>
    </Layout>
  );
};

export default TrademarkInfo;

const cardsData = [
  {
    question: "Lorem ipsum dolor sit amet consetetur sadipscing ipsum elitr?",
    answers: ["Yes, I am in", "Not for now"],
  },
  {
    question: "Lorem ipsum dolor sit amet consetetur sadipscing ipsum elitr?",
    answers: ["Yes, I am in", "Not for now"],
  },
  {
    question: "Lorem ipsum dolor sit amet consetetur sadipscing ipsum elitr?",
    answers: ["Yes, I am in", "Not for now"],
  },
  {
    question: "Lorem ipsum dolor sit amet consetetur sadipscing ipsum elitr?",
    answers: ["Yes, I am in", "Not for now"],
  },
  {
    question: "Lorem ipsum dolor sit amet consetetur sadipscing ipsum elitr?",
    answers: ["Yes, I am in", "Not for now"],
  },
];
