import React from "react";
import Layout from "../Layout";
import LoginForm from "../components/Authentication/LoginForm";

const Login = () => (
  <Layout bgcolor="#ECF4FF">
    <LoginForm />
  </Layout>
);

export default Login;
