const Layout = ({ children, bgcolor, fill, fixed }) => {
  return (
    <div
      className={`flex justify-center w-screen min-w-full max-w-full ${fill ? "flex-fill": ""} bg-${bgcolor} ${fixed ? "fixed z-[1000] top-68 md:not-fixed md:not-z md:not-top" : ""}`}
    >
      <div className="w-full mx-5 flex flex-col items-center">{children}</div>
    </div>
  );
};

export default Layout;
