import React from "react";
import Navbar from "../components/Reusable/Navbar";
import { SOCIAL } from "../utils/data/social";
import ContactForm from "../components/ContactForm/ContactForm";
import { GrLocation } from "react-icons/gr";
import { BiPhone } from "react-icons/bi";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Contact = () => {
  const location = useLocation();

  const variant = location?.search?.split("=")[1];
  const isDreamTeam = variant === "dream-team";

  return (
    <>
      <Navbar />

      <div className="mx-auto lg:pt-0 lg:grid grid-cols-3 items-center justify-between lg:h-[calc(100vh-88px)] text-[#373737]">
        <div className="col-span-1 p-12 h-full bg-primary w-full relative">
          <div className="">
            <p className="text-xl sm:text-2xl font-medium">Get in touch</p>
            <p className="mt-4 text-base sm:text-lg">
              We’ love to hear from you. Our friendly team is always here to
              chat.
            </p>
            <div className="flex items-start space-x-4 pt-8">
              <img src="/icons/message-chat-circle.svg" alt="" />
              <div className="space-y-2">
                <p className="font-medium text-lg sm:text-xl">Chat to us</p>
                <p className="text-sm sm:text-base pb-5">
                  Our friendly team is here to help.
                </p>
                <a className="text-base" href="mailto:office@blankcut.com">
                office@blankcut.com
                </a>
              </div>
            </div>
            <div className="flex items-start space-x-4 pt-8">
              <GrLocation size={25} />
              <div className="space-y-2">
                <p className="font-medium text-lg sm:text-xl">Office</p>
                <p className="text-sm sm:text-base">
                  Come say hello at our office HQ.{" "}
                </p>
                <p className="pt-3 text-sm sm:text-base">
                  8 THE GREEN STE 300 DOVER, DE 19901
                </p>
              </div>
            </div>
            <div className="flex items-start space-x-4 pt-8">
              <BiPhone size={25} />
              <div className="space-y-2">
                <p className="font-medium text-lg sm:text-xl">Phone</p>
                <p className="text-sm sm:text-base pb-5">
                  Mon-Fri from 8am to 5pm.
                </p>
                <a
                  className="pt-3 text-sm sm:text-base"
                  href="tel:+1(352)286-9493"
                >
                  +1 (352) 286-9493
                </a>
              </div>
            </div>
            <div className="flex space-x-8 justify-center items-center pt-20 lg:pt-0 lg:absolute lg:bottom-0 pb-10">
              <a href={SOCIAL.FACEBOOK}>
                <img
                  className="w-5 sm:w-6 cursor-pointer"
                  src="/icons/fb-cricle.svg"
                  alt=""
                />
              </a>
              <a href={SOCIAL.TWITTER}>
                <img
                  className="w-5 sm:w-6 cursor-pointer"
                  src="/icons/twitter.svg"
                  alt=""
                />
              </a>
              <a href="">
                <img
                  className="w-5 sm:w-6 cursor-pointer"
                  src="/icons/linkedin.svg"
                  alt=""
                />
              </a>
              <a href="">
                <img
                  className="w-5 sm:w-6 cursor-pointer"
                  src="/icons/youtube.svg"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
        <div className="bg-white mt-20 my-20 lg:mt-0 lg:mb-5 h-full w-full lg:col-span-2 lg:flex flex flex-col justify-center  lg:justify-center lg:flex-col lg:items-center max-w-[512px] mx-auto px-6 lg:px-0 lg:py-10">
          <h2 className="text-3xl lg:text-4xl text-dark self-start">
            {isDreamTeam ? "Welcome To The Dream Team!" : "Level up your brand"}
          </h2>
          {isDreamTeam ? (
            <p className="text-[#475467] text-base mt-3 md:mt-5 max-w-2xl mx-auto self-start">
              The Dream Team offering is a bespoke and cutting-edge solution
              provided by Blank Cut, tailored to meet the unique needs of our
              esteemed clients. We take pride in crafting fully customized
              solutions that empower businesses to reach new heights and achieve
              their dreams.
            </p>
          ) : (
            <p className="text-[#475467] text-base lg:text-md mt-3 md:mt-5 mr-auto">
              You can reach us anytime via{" "}
              <a className="text-[#6941C6]" href="mailto: office@blankcut.com">
              office@blankcut.com
              </a>
            </p>
          )}
          <ContactForm isDreamTeam={isDreamTeam} />
        </div>
      </div>
    </>
  );
};

export default Contact;
