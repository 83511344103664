import React, { useState, useCallback, useContext, useEffect } from "react";
import Navbar from "../components/Reusable/Navbar";
import Modal from "../components/Reusable/Modal";
import TabBar from "../components/Knowledgebase/TabBar";
import KnowledgeTopic from "../components/Knowledgebase/KnowledgeTopic";
import KnowledgeBaseBanner from "../components/Knowledgebase/KnowledgeBaseBanner";
import UserInformationForm from "../components/Knowledgebase/UserInformationForm";
import { Context } from "../context/GlobalContext";
import axios from "axios";

const categoriesBase = [
  { header: "General", selected: false, content: undefined },
  { header: "Design Project", selected: false, content: undefined },
  {
    header: "Business",
    selected: true,
    content: () => (
      <>
        <KnowledgeTopic />
      </>
    ),
  },
];

const KnowledgeBase = ({ hasNavbar }) => {
  const context = useContext(Context);
  const [categories, setCategories] = useState(categoriesBase);

  const handleCategoryChange = useCallback(
    (category) => {
      setCategories(
        categories.map((cat) => ({ ...cat, selected: cat.header === category }))
      );
    },
    [categories, setCategories]
  );

  const handleUserInformationClose = () => {
    context.setUserInformationShown(false);
  };

  useEffect(
    () => {
      if (context.entityFormationStates.length > 0) {
        return;
      }

      const entityFormationStatesCall = {
        method: "GET",
        url: "/articles/states",
        headers: {
          "Content-Type": "application/json",
        },
      };

      axios(entityFormationStatesCall)
        .then((result) => {
          context.setEntityFormationStates(result.data);
        })
        .catch((e) =>
          console.error("Error gettings Entity Formation States", e)
        );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className="flex flex-col h-screen">
      {hasNavbar ?? <Navbar />}
      <KnowledgeBaseBanner />
      <div className="flex-fill flex flex-col items-center bg-light">
        <TabBar options={categories} onChange={handleCategoryChange}></TabBar>
      </div>
      <Modal show={context.userInformationShown}>
        <UserInformationForm onClose={handleUserInformationClose} />
      </Modal>
    </div>
  );
};

export default KnowledgeBase;
